import axios from "axios";

//export const apiUrl = "http://10.179.2.80:8080/mrss/v-1/";
//export const apiUrl = "http://localhost:8080/mrss/v-1/";

export const apiUrl =
  window.location.protocol +
  "//" +
  window.location.hostname +
  "/mrssapi/mrss/v-1/";

// export const apiUrl = "http://megrssa.nic.in:8080/mrssapi/mrss/v-1/";
// export const apiUrl = "http://megrssa.nic.in/mrssapi/mrss/v-1/";
// export const apiUrl = process.env.REACT_APP_ENDPOINT;


export default axios.create({
  withCredentials: false,
  timeout: 10000,
  baseURL: apiUrl,
  // headers: {
  //   "Content-type": "application/json",
  //   common: {
  //     Authorization: getToken(0),
  //   },
  // },
});
