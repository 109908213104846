import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import JSEncrypt from "jsencrypt";
import React, { useContext } from "react";
import httpApi from "../../api/http-common-apikey";
import httpApiApp from "../../api/http-common-apikey-application";
import { GlobalContext } from "../../context/GlobalState";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const ForgotPasswordDialog = (props) => {
  const {
    setUsername,
    username,
    setOtpPswdChnge,
    otpPswdChnge,
    setNewPswd,
    newPswd,
    publicKey,
    setForgotPswd,
    setOpenLoginDialog
  } = props;
  const { openSnackBar } = useContext(GlobalContext);

  return (
    <div>
      <DialogTitle style={{ backgroundColor: "#29335C", color: "#fff", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <IconButton
          onClick={() => {
            setForgotPswd(false);
            setOpenLoginDialog(true);
          }}
        ><ArrowBackIcon style={{ color: "white" }} /></IconButton>
        <Typography align="center" variant="h5">
          Password Change
        </Typography>
        <IconButton
          style={{
            // backgroundColor: "#eb5809",
            color: "#fff",
            justifySelf: "flex-end",
            height: "20px",
            width: "20px",
            borderRadius: 1,
          }}
          onClick={() => {
            setForgotPswd(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            },
            justifyContent: "space-between",
          }}
        >
          <FormControl
            sx={{
              display: {
                lg: "inline-flex",
                md: "inline-flex",
                sm: "inline-flex",
                xs: "block",
              },
              width: { lg: "70%", md: "70%", sm: "70%", xs: "100%" },
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              id="username"
              label="Enter Username"
              variant="standard"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </FormControl>
          <DialogActions>
            <Button
              sx={{ marginTop: "17px" }}
              size="small"
              variant="outlined"
              onClick={() =>
                httpApi
                  .get(`apikey/getOTP/user/${username}`)
                  .then(() =>
                    openSnackBar({
                      open: true,
                      message: "OTP has been sent to your registered email!",
                      severity: "success",
                    })
                  )
                  .catch((err) => {
                    if (err && err.response && err.response.data.message) {
                      openSnackBar({
                        open: true,
                        message: err.response.data.message,
                        severity: "error",
                      });
                    } else {
                      openSnackBar({
                        open: true,
                        message: err.response.data,
                        severity: "error",
                      });
                    }
                  })
              }
            >
              Get OTP
            </Button>
          </DialogActions>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            },
            justifyContent: "space-between",
          }}
        >
          <FormControl
            sx={{
              display: {
                lg: "inline-flex",
                md: "inline-flex",
                sm: "inline-flex",
                xs: "block",
              },
              width: { lg: "70%", md: "70%", sm: "70%", xs: "100%" },
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              id="otp"
              label="Enter OTP"
              fullWidth
              variant="standard"
              onChange={async (e) => {
                let otp = e.target.value;
                if (otp.length >= 6) {
                  try {
                    let crypt = new JSEncrypt();
                    crypt.setPublicKey(publicKey);
                    let enc = crypt.encrypt(otp);
                    httpApi.defaults.headers["Content-type"] =
                      "application/json";
                    setOtpPswdChnge(enc);
                  } catch (error) {
                    console.log(error.response);
                  }
                }
              }}
            />
          </FormControl>

          <DialogActions>
            <Button
              sx={{ marginTop: "17px" }}
              size="small"
              variant="outlined"
              onClick={() => {
                httpApi
                  .post(`apikey/validateOTP/${username}`, otpPswdChnge)
                  .then((response) => {
                    httpApiApp.defaults.headers["API-key"] = response.data;
                    openSnackBar({
                      open: true,
                      message: "OTP has been successfully verified!",
                      severity: "success",
                    });
                  })
                  .catch((err) =>
                    openSnackBar({
                      open: true,
                      message: err.response.data,
                      severity: "error",
                    })
                  );
              }}
            >
              Verify
            </Button>
          </DialogActions>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            },
            justifyContent: "space-between",
          }}
        >
          <FormControl
            sx={{
              display: {
                lg: "inline-flex",
                md: "inline-flex",
                sm: "inline-flex",
                xs: "block",
              },
              width: { lg: "70%", md: "70%", sm: "70%", xs: "100%" },
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="Enter New Password"
              variant="standard"
              onChange={(e) => {
                setNewPswd(e.target.value);
              }}
            />
          </FormControl>

          <DialogActions>
            <Button
              sx={{ marginTop: "17px" }}
              size="small"
              variant="outlined"
              onClick={async () => {
                let crypt = await new JSEncrypt();
                await crypt.setPublicKey(publicKey);
                let enc = await crypt.encrypt(newPswd);

                let data = {
                  username: username,
                  password: enc,
                };
                httpApiApp
                  .post(`process/changepassword`, data)
                  .then((response) => {
                    openSnackBar({
                      open: true,
                      message: "Your Password has been changed successfully!",
                      severity: "success",
                    });
                  })
                  .catch((err) =>
                    openSnackBar({
                      open: true,
                      message: err.response.data,
                      severity: "error",
                    })
                  );
              }}
            >
              Apply
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </div>
  );
};

export default ForgotPasswordDialog;
