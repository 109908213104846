import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LandlordDetails from "./LandlordDetails";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import TenantDetails from "./TenantDetails";
import RentalUnitDetails from "./RentalUnitDetails";
import ApplicationService from "../../api/ApplicationService";
import { stringWithinBrackets } from "../../api/GlobalService";
// import OldClearance from "./OldClearance";

const useStyles = makeStyles((theme) => ({
  centered: { width: "50%", margin: "auto" },
  content: {
    justifyContent: "center",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    color: "#29335C",
  },
}));

export default function Application() {
  const { processApplication } = useContext(GlobalContext);

  const classes = useStyles();
  const [expanded, setExpanded] = useState("panel");
  const [appFlow, setAppFlow] = useState([]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded && panel);
  };

  useEffect(() => {
    ApplicationService.getApplicationFlow(
      processApplication.appl.applid
    ).then((response) => setAppFlow(response.data));
  }, []);

  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded === "panel" || expanded === "panel1"}
        onChange={handleChange("panel1")}
        // className={classes.centered}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Landlord Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LandlordDetails landlord={processApplication.landlord} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel" || expanded === "panel2"}
        onChange={handleChange("panel2")}
        // className={classes.centered}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Tenant Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TenantDetails tenant={processApplication.appl.tenant} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel" || expanded === "panel3"}
        onChange={handleChange("panel3")}
        // className={classes.centered}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Rental Unit</Typography>
        </AccordionSummary>
        {/* <AccordionDetails> */}
        <RentalUnitDetails rentalunit={processApplication.appl.rentalunit} />
        {/* </AccordionDetails> */}
      </Accordion>
      <Accordion
        expanded={expanded === "panel" || expanded === "panel4"}
        onChange={handleChange("panel4")}
        // className={classes.centered}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
          // classes={{ content: classes.content }}
        >
          <Typography className={classes.heading}>Remarks</Typography>
        </AccordionSummary>
        {/* AppFlow */}
        <AccordionDetails
          style={{ justifyContent: "center" }}
          // classes={{ content: classes.content }}
        >
          {appFlow.map((appState, index) => {
            index++;
            return (
              <>
                <Typography
                  style={{
                    color:
                      stringWithinBrackets(appState.remarks) ==
                      "May be Accepted"
                        ? "green"
                        : "red",
                  }}
                >
                  {stringWithinBrackets(appState.remarks)}
                </Typography>
                {index > 1 && ","}
              </>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
