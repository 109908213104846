import React, { useEffect, useState } from "react";
import PageHeader from "../../components/Header/PageHeader";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { GroupingState, IntegratedGrouping } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
} from "@devexpress/dx-react-grid-material-ui";
import { Backdrop, CircularProgress, Paper } from "@mui/material";
import UserService from "../../api/UserService";
import RentalUnitService from "../../api/RentalUnitService";
import CustomDataTable from "../../components/data-table/CustomDataTable";
import { useStyles } from "./useStyles";

const columns = [
  {
    name: "localityName",
    label: "Locality/Cantonment",
    options: {
      filter: true,
    },
  },
  {
    name: "headManName",
    label: "Headman Name",
    options: {
      filter: true,
    },
  },
  {
    name: "users", // needs to use .map() here
    label: "Users",
    options: {
      filter: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return tableMeta.rowData[2].map((user, index) => (
          <div key={index}>
            {user}
            {tableMeta.rowData[2].length - 1 === index ? "" : ","}
          </div>
        ));
      },
    },
  },
];

function AddLocalityFromAdmin() {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [roles, setRoles] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [retrieving, setRetrieving] = useState(false);

  const getRoles = () => {
    UserService.getRoles()
      .then((response) => {
        setRoles(response.data);
        console.log(response.data);
      })
      .catch((e, response) => {
        console.error(e);
        // alert(JSON.stringify(e.response.data));
      });
  };

  useEffect(() => {
    getRoles();
  }, []);

  const getLocalitiesForAdmin = async () => {
    setRetrieving(true);
    const response = await RentalUnitService.getAllLocalities();
    let data = [];
    data = response.data;
    for (var i = 0; i < data.length; i++) {
      data[i].id = i + 1;
      data[i].locality = response.data[i].localityName;

      const userresponse = await UserService.getUserbycode(
        data[i].localityCode
      );
      userresponse.data !== null
        ? (data[i].users = userresponse.data)
        : (data[i].users = []);
    }
    setRows(data);
    setRetrieving(false);
  };

  useEffect(() => {
    // getDistricts();
    getLocalitiesForAdmin();
  }, [refresh]);

  return (
    <>
      <Backdrop className={classes.backdrop} open={retrieving}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageHeader
        title="Registered Localities"
        subTitle="Registered localities of different police stations."
        icon={<AccountBalanceIcon fontSize="large" />}
      />
      <div style={{ marginTop: "2%", position: "relative" }}>
        <Paper>
          {rows.length !== 0 && (
            <CustomDataTable
              rows={rows}
              columns={columns}
              title="Registered Localities"
            />
          )}
        </Paper>
      </div>
    </>
  );
}

export default AddLocalityFromAdmin;
