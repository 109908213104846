import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TenantService from "../../../api/TenantService";
import { getDecryptedData } from "../../../api/GlobalService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

export default function CheckBoxGroup(props) {
  const classes = useStyles();

  const { selectedTenant, selectFamily, selectHelper, apply } = props;


  const [familyMembers, setFamilyMembers] = useState([]);

  const [helpers, setHelpers] = useState([]);

  const getFamilyMembers = async () => {
    try {
      const response =
        // getDecryptedData("role") === "MODERATOR" ||
        //   getDecryptedData("role") === "SUPERMODERATOR" ||
        //   getDecryptedData("role") === "VERIFIER"
        sessionStorage.getItem("role") === "MODERATOR" ||
          sessionStorage.getItem("role") === "SUPERMODERATOR" ||
          sessionStorage.getItem("role") === "VERIFIER"
          ? await TenantService.getTenantFamilyMembersByEpicNumber(
            selectedTenant.tenant.id
          )
          : await TenantService.getTenantFamilyMembersByEpicNumberAnonymous(
            selectedTenant.tenant.id
          );

      let data = await response.data.filter(
        (member) => member.leftOn === null
      );

      for (var i = 0; i < data.length; i++) {
        data[i].checked = false;
      }


      setFamilyMembers(data);
    } catch (err) {
      // Error Message Here
    }
  };

  const getDomesticHelpers = async () => {
    try {
      const response =
        // getDecryptedData("role") === "MODERATOR" ||
        //   getDecryptedData("role") === "SUPERMODERATOR" ||
        //   getDecryptedData("role") === "VERIFIER"
        sessionStorage.getItem("role") === "MODERATOR" ||
          sessionStorage.getItem("role") === "SUPERMODERATOR" ||
          sessionStorage.getItem("role") === "VERIFIER"
          ? await TenantService.getTenantDomesticHelpersByEpicNumber(
            selectedTenant.tenant.id
          )
          : await TenantService.getTenantDomesticHelpersByEpicNumberAnonymous(
            selectedTenant.tenant.id
          );

      let data = await response.data.filter(
        (helper) => helper.leftOn === null
      );

      for (var i = 0; i < data.length; i++) {
        data[i].checked = false;
      }


      setHelpers(data);
    } catch (err) {
      // Error Message Here
    }
  };

  const getData = () => {
    if (props.type === "family") getFamilyMembers();
    else getDomesticHelpers();
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (event) => {

    if (props.type === "family") {
      for (var i = 0; i < familyMembers.length; i++) {
        if (familyMembers[i].memberCode === event.target.value) {
          familyMembers[i].checked = !familyMembers[i].checked;
          // if checked, we need to add
          // if it is checked=false remove
          selectFamily(
            familyMembers[i].memberCode,
            familyMembers[i].checked ? "ADD" : "REMOVE"
          );
          setFamilyMembers([...familyMembers]);
          break;
        }
      }
    } else {
      for (var i = 0; i < helpers.length; i++) {
        if (helpers[i].memberCode === event.target.value) {
          helpers[i].checked = !helpers[i].checked;
          selectHelper(
            helpers[i].memberCode,
            helpers[i].checked ? "ADD" : "REMOVE"
          );
          setHelpers([...helpers]);
          break;
        }
      }
    }

    // Change state here
  };

  if (props.type === "family")
    return (
      <div className={classes.root}>
        <FormControl
          component="fieldset"
          className={classes.formControl}
          disabled={apply}
        >
          <FormLabel component="legend">Select Family Members</FormLabel>
          <FormGroup>
            {familyMembers.length != 0 ? (
              familyMembers.map((familyMember) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={familyMember.checked}
                      onChange={handleChange}
                      name={familyMember.memberName}
                      value={familyMember.memberCode}
                    />
                  }
                  label={familyMember.memberName}
                />
              ))
            ) : (
              <>No family members</>
            )}
          </FormGroup>
        </FormControl>
      </div>
    );
  else
    return (
      <div className={classes.root}>
        <FormControl
          component="fieldset"
          className={classes.formControl}
          disabled={apply}
        >
          <FormLabel component="legend">Select Helpers</FormLabel>
          <FormGroup>
            {helpers.length != 0 ? (
              helpers.map((helper) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={helper.checked}
                      onChange={handleChange}
                      name={helper.memberName}
                      value={helper.memberCode}
                    />
                  }
                  label={helper.memberName}
                />
              ))
            ) : (
              <>No helpers</>
            )}
          </FormGroup>
        </FormControl>
      </div>
    );
}
