import React from "react";
import { Grid } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import DataTable from "./DataTable";
import NewApplication from "./NewApplication";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import { useEffect } from "react";
import UserService from "../../api/UserService";
import * as Constants from "../../Constants";
import { getDecryptedData } from "../../api/GlobalService";
export default function LandlordPage() {
  var classes = useStyles();
  const { applications, getApplications } = useContext(GlobalContext);

  // const role = getDecryptedData("role");
  const role = sessionStorage.getItem("role");

  console.log(applications);

  return (
    <>
      <PageTitle title="Applications" />
      {role === Constants.role.USER ? <NewApplication /> : <></>}

      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <DataTable />
        </Grid>
      </Grid>
    </>
  );
}
