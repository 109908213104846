import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  logotypeContainer: {
    // backgroundColor: theme.palette.primary.main,
    width: "60%",
    // height: "100%",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "40%",
    },
    // [theme.breakpoints.down("md")]: {
    //   display: "none",
    // },

    // backgroundImage: `url(${process.env.PUBLIC_URL + "/static/login.svg"})`,
  },
  logotypeImage: {
    width: 200,
    // marginBottom: theme.spacing(4),
  },
  logo: {
    width: 300,
    height: 240,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  formContainer: {
    width: "40%",
    // height: "100%",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    // backgroundImage: `url(${process.env.PUBLIC_URL + "/static/login.svg"})`,
  },
  form: {
    width: 520,
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
    color: "#B12B39",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
    // "&:-webkit-autofill, &:-webkit-autofill:hover,&:-webkit-autofill:focus,&:-webkit-autofill: active": {
    //   WebkitBoxShadow: "0 0 0 30px red inset",
    // },
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 700,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },
  textColor: {
    color: "black",
  },
  responsive: {
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
  },
  hover: {
    "&:hover": {
      color: "#06bcee !important",
      // borderBottom: "3px solid #06bcee",
    },
  },
  marquee: {
    width: "70%",
    margin: "auto",
  },
  navbarButton: {
    "&:hover": {
      color: "#F3A712",
      backgroundColor: "transparent",
    },
    cursor: 'pointer',
    letterSpacing: 0.8,
    fontFamily: "darker grotesque",
    fontSize: "20px",
    fontWeight: 500
  },

  hero: {
    backgroundImage: `url(${process.env.PUBLIC_URL + "/static/image7.png"})`,
    backgroundSize: '70%',
    backgroundRepeat: 'no-repeat', // Ensure the image is not repeated
    backgroundPosition: 'center',
    height: '80vh', // Adjust the height as needed
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff', // Set text color to ensure contrast with the background
    textAlign: 'center',

  },
  heroHeading: {
    fontFamily: "raleway",
    fontWeight: 700,
    fontSize: 40,
    padding: "50px",
    color: "#29335c"
  }

}));
