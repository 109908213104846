import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { makeStyles } from "@material-ui/core/styles";
import fs from "fs";

import { Backdrop, CircularProgress } from "@material-ui/core";

import Webcam from "react-webcam";
import { uploadCapturedPhoto } from "../../api/GlobalService";
import { createCanvas, loadImage } from "canvas";

const useStyle = makeStyles((theme) => ({
  dialogElements: {
    display: "flex",
  },
  dialogActions: {
    justifyContent: "center",
  },
  dialogTitle: {
    margin: "auto",
  },
  closeDialog: {
    width: "10%",
    margin: "auto",
    marginTop: "40px",
    marginBottom: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const CameraComponent = ({
  setSnackBar,
  document,
  setUploading,
  addTenantDocument,
  setDocument,
  snackBar,
  label,
  setOpenCamera,
  memberCode,
  setOpen,
}) => {
  const webcamRef = React.useRef(null);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const classes = useStyle();

  const [imageSrc, setImageSrc] = React.useState(null); // stores the image in base64
  const [imageBack, setImageBack] = React.useState(null);
  const [captureBack, setCaptureBack] = React.useState(false);
  const [toggleAction, setToggleAction] = useState(false);
  const [croppedImage, setCroppedImage] = React.useState(null);
  const [croppedImageBack, setCroppedImageBack] = React.useState(null);
  const [cropper, setCropper] = React.useState(null);
  const [cropFlag, setCropFlag] = useState(false);
  const [webpString, setWebpString] = useState("");
  const [jpegDataUrl, setJpegDataUrl] = useState("");
  const [isFrontCamera, setIsFrontCamera] = useState(true);

  // Capture The Image
  // & set it to imageSrc
  const captureImage = async () => {
    if (imageSrc === null) {
      setToggleAction(true);
      const imageSrc = await webcamRef.current.getScreenshot();
      await setImageSrc(imageSrc);
    } else {
      setToggleAction(true);
      const imageBack = await webcamRef.current.getScreenshot();
      await setImageBack(imageBack);
    }
  };

  const captureImageBack = async () => {
    // If front image has been capture
    // set capture back to true
    if (imageSrc) {
      setCaptureBack(true);
    }
  };

  const handleCrop = () => {
    // Crop the frontImage else backImage
    if (cropper && croppedImage === null) {
      const croppedImage = cropper.getCroppedCanvas().toDataURL();
      setCroppedImage(croppedImage);
      setCropFlag(false);
    } else {
      const croppedImage = cropper.getCroppedCanvas().toDataURL();
      setCroppedImageBack(croppedImage);
      setCropFlag(false);
    }
  };

  // Upload Function
  const uploadImage = async () => {
    // If there is a back image
    // merge them
    if (imageBack !== null) {
      loadImage(croppedImage ? croppedImage : imageSrc).then((image1) => {
        loadImage(croppedImageBack ? croppedImageBack : imageBack).then(
          async (image2) => {
            const canvasHeight = image1.height + image2.height;
            const canvasWidth = Math.max(image1.width, image2.width);

            const canvas = createCanvas(canvasWidth, canvasHeight);
            const ctx = canvas.getContext("2d");

            // Draw the first image at (0, 0)
            ctx.drawImage(image1, 0, 0);

            // Draw the second image at (0, image1.height)
            ctx.drawImage(image2, 0, image1.height);

            const base64Image = canvas.toDataURL("image/png");
            await uploadCapturedPhoto(
              setSnackBar,
              base64Image,
              setUploading,
              addTenantDocument,
              setDocument,
              snackBar,
              label,
              setBackDropOpen,
              setWebpString,
              setJpegDataUrl,
              jpegDataUrl,
              setOpenCamera,
              memberCode,
              setOpen
            );
          }
        );
      });
    } else {
      await uploadCapturedPhoto(
        setSnackBar,
        croppedImage ? croppedImage : imageSrc,
        setUploading,
        addTenantDocument,
        setDocument,
        snackBar,
        label,
        setBackDropOpen,
        setWebpString,
        setJpegDataUrl,
        jpegDataUrl,
        setOpenCamera,
        memberCode,
        setOpen
      );
    }

    // if (imageSrc) {
    //   const link = document.createElement("a");
    //   link.href = imageSrc;
    //   link.download = "captured_photo.png";
    //   link.click();
    // }
  };

  const constraints = {
    video: {
      facingMode: isFrontCamera ? "user" : "environment", // 'user' for front camera, 'environment' for rear camera
    },
  };

  const toggleCamera = () => {
    setIsFrontCamera(!isFrontCamera);
    if (webcamRef.current) {
      const tracks = webcamRef.current.video.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        webcamRef.current.video.srcObject = stream;
      });
    }
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {cropFlag ? (
        <Cropper
          style={{ height: 400, width: "100%" }}
          src={imageBack ? imageBack : imageSrc} // crop the back image else crop the front image
          initialAspectRatio={1}
          guides={true} // Set to true to show expandable grid lines
          //  cropBoxResizable={true}
          //  zoomable={true}
          viewMode={1}
          //  dragMode="move"
          responsive={true}
          autoCropArea={1}
          zoomTo={0.5}
          preview=".img-preview"
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
      ) : (
        <Box sx={{ textAlign: "center", margin: "2rem 2rem" }}>
          {imageSrc ? (
            <img
              src={croppedImage ? croppedImage : imageSrc}
              alt="CapturedOrCropped"
            />
          ) : (
            <Webcam audio={false} ref={webcamRef} style={{ width: "100%" }} />
          )}

          {imageBack ? (
            <img
              src={croppedImageBack ? croppedImageBack : imageBack}
              alt="CapturedOrCropped"
            />
          ) : (
            captureBack && (
              <Webcam audio={false} ref={webcamRef} style={{ width: "100%" }} />
            )
          )}
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "center",
          padding: "4rem 2rem",
        }}
      >
        {imageSrc && (
          <>
            <Button
              onClick={async () => {
                setCropFlag(false);
                setImageSrc(null);
                setImageBack(null);
                setCroppedImage(null);
                setCroppedImageBack(null);
                setToggleAction(false);
                setCaptureBack(false);
              }}
              variant="contained"
            >
              Reset
            </Button>
            {cropFlag ? (
              <Button onClick={handleCrop} variant="contained">
                Crop Image
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setCropFlag(true);
                  // setImageSrc(null);
                  // setToggleAction(false);
                }}
                variant="contained"
              >
                Crop
              </Button>
            )}
          </>
        )}

        {/* Capture or Upload front of the ID */}
        <Button
          variant="contained"
          // disabled={imageSrc===null}
          onClick={toggleAction ? uploadImage : captureImage}
        >
          {toggleAction
            ? "Upload Photo"
            : label === "IDENTITYPROOF"
            ? "Capture Front"
            : "Capture Photo"}
        </Button>

        {captureBack && imageBack === null ? (
          <Button variant="contained" onClick={captureImage}>
            Capture
          </Button>
        ) : null}

        {/* Capture Back of the ID */}
        {label === "IDENTITYPROOF" &&
        imageSrc !== null &&
        captureBack === false ? (
          <Button variant="contained" onClick={captureImageBack}>
            Capture Back
          </Button>
        ) : null}
        <button onClick={toggleCamera}>Toggle Camera</button>
      </Box>
    </div>
  );
};

export default CameraComponent;
