import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import http from "../../api/http-common";
import UserService from "../../api/UserService";
import JSEncrypt from "jsencrypt";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const AddLocalAuthorityUserDialog2 = ({
  landlordUser,
  setLandlordUser,
  roles,
  localAuthorityCode,
  toggleRefresh,
  setOpen
}) => {
  const [publicKey, setPublicKey] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleRoleChange = async (event) => {
    console.log(event.target.value);
    let value = event.target.value;
    if (value === "select") {
      alert("Please select a role.");
    } else {
      let data = roles.find((role) => role.name === value);
      console.log(data);

      setLandlordUser((prevState) => ({
        ...prevState,
        role: [data],
      }));
      // policeUser.role[0] = data;
      //  console.log(policeUser);

      // const api: GridApi = params.api;               // these are not required anymore.
      // const fields = api
      //   .getAllColumns()

      //   .map((c) => c.field)
      //   .filter((c) => c !== "__check__" && !!c);
      // const thisRow: Record<string, GridCellValue> = {};
      // fields.forEach((f) => {
      //   thisRow[f] = params.getValue(f);
      // });

      // let psCode = JSON.stringify(thisRow, null, 4)

      // let localAuthority = localAuthorityList.filter(                                    //might not be needed anymore
      //   (la) => la.localAuthorityName === row.localAuthorityName
      // );

      // console.log("Required Ps: ", localAuthority);

      // policeUser.usercode = thisRow.id

      setLandlordUser((prevState) => ({
        ...prevState,
        usercode: localAuthorityCode,
      }));
    }
  };

  const handleCreateLAUser = async () => {
    // const errs = validateUserData();

    // if (errs.error === true) {
    //   setErrors(errs);
    //   // setSnackBar({
    //   //   message: "Validation Errors!",
    //   //   severity: "error",
    //   //   open: true,
    //   // });
    //   return;
    // }

    let crypt = new JSEncrypt();
    crypt.setPublicKey(publicKey);
    let enc = crypt.encrypt(landlordUser.password);
    console.log("enc is : ", enc);

    let userData = { ...landlordUser };
    userData.password = enc;
    // if (
    //   landlordUser.role[0].name === "USER" ||
    //   landlordUser.role[0].name === "MODERATOR"
    // ) {
    //   userData.level = {
    //     id: 2,
    //     level: "LOCAL AUTHORITY",
    //   };
    // }

    console.log("userdata: ", userData);

    //encrypt the password here.

    // e.preventDefault();
    UserService.signUp(userData)
      .then((response) => {
        console.log("Response for Landlord Entry is : ", response);
        alert("Successfully Created User");
        // setSnackBar({open:true,message:'Successfully Created User',severity:'success'}),
        // history.push("/app/dashboard");
        //   setError(null)
        //   setIsLoading(false);

        // history.push("/app/dashboard");
        //   dispatch({ type: "LOGIN_SUCCESS" });
      })
      .then(() => {
        setLandlordUser(prevState => ({
          ...prevState,
          username: "",
          password: "",
        }));
        toggleRefresh()
        setOpen(false);
      })
      .catch((e, response) => {
        // alert(response.)
        console.error(e);
        alert(JSON.stringify(e.response.data));
        //   setError(true);
        //   setIsLoading(false);
        //dispatch({ type: "LOGIN_FAILURE" }); // No need this, if enable it will reset the tabIndex State
      });
  };

  useEffect(() => {
    UserService.getPublicKey()
      .then((response) => setPublicKey(response.data))
      .catch((err) => console.log(err));
  }, []);
  return (
    <form
      noValidate
      autoComplete="off"
      style={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
      }}
    >
      <FormControl>
        <TextField
          variant="outlined"
          autoFocus
          margin="dense"
          label="Username"
          type="text"
          fullWidth
          value={landlordUser.username}
          onChange={(e) => {
            setLandlordUser({
              ...landlordUser,
              username: e.target.value,
            });
            console.log(e.target.value);
          }}
        // error={errors.username.exist === true ? true : false}
        // helperText={
        //   errors.username.exist
        //     ? errors.username.helperText
        //     : ""
        // }
        />
      </FormControl>
      <FormControl>
        <TextField
          variant="outlined"
          autoFocus
          margin="dense"
          label="Password"
          type={showPassword ? "text" : "password"}
          fullWidth
          value={landlordUser.password}
          InputProps={{
            // classes: {
            //   underline: classes.textFieldUnderline,
            //   input: classes.textField,
            // },
            // className: classes.textColor,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => setShowPassword(!showPassword)}
                  color="primary"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setLandlordUser({
              ...landlordUser,
              password: e.target.value,
            });
            console.log("entered!!");
          }}
        // error={errors.password.exist === true ? true : false}
        // helperText={
        //   errors.password.exist
        //     ? errors.password.helperText
        //     : ""
        // }
        />
      </FormControl>

      <label for="role" style={{ fontSize: "16px" }}>
        Role:{" "}
      </label>
      <select
        style={{ width: "95%", height: "25px" }}
        id="role"
        onChange={(event) => {
          handleRoleChange(event);
        }}
      >
        <option value="select" selected>
          {" "}
          ----{" "}
        </option>
        {roles.map((role) => (
          <option value={role.name} key={role.id}>
            {role.rolename}
          </option>
        ))}
      </select>
      <Button
        style={{ marginTop: "8%", backgroundColor: "#F3A712", color: "#fff" }}
        size={"small"}
        variant="contained"
        // color="#F3A712"
        onClick={handleCreateLAUser}
      >
        Create User
      </Button>
    </form>
  );
};

export default AddLocalAuthorityUserDialog2;
