import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import RentalUnitFormDialog from "./RentalUnitFormDialog";

export default function NewRentalUnit() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <div>
      <Button
        style={{ float: "right" }}
        variant="contained"
        color="secondary"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        New Rental Unit
      </Button>
      <RentalUnitFormDialog open={open} setOpen={setOpen} />
    </div>
  );
}
