import http from "./http-common";
import httpApi from "./http-common-apikey";
import httpApiApp from "./http-common-apikey-application";

const getAll = () => {
  return http.get("/todos/1");
};

const signIn = (username, password) => {
  return http.post("users/signin", {
    username: username,
    password: password,
  });
};

const getByEpicNumber = async (id) => {
  return http.get(`/register/landlord/${id}`);
};

const getLandlordById = async (regNo) => {
  return httpApi.get(`/register/landlordByReg/${regNo}`);
};

const getDomesticHelperByLandlordEpicNumber = async (id) => {
  return http.get(`/register/landlord/domesticHelper/${id}`);
};

const create = async (data) => {
  return httpApi.post("/register/landlord", data);
};

const update = async (data) => {
  return http.post("/register/updateLandlord", data);
};

const remove = async (id) => {
  return http.delete(`/user/${id}`);
};

const removeAll = async () => {
  return http.delete(`/user`);
};

const checkLandlord = async (data) => {
  return httpApi.get(`/register/Existlandlord/${data}`);
};

const generateRC = async (applno) => {
  return http.get(`/process/GenerateRC/${applno}`);
};

// api's for Anonymous User
const getByEpicNumberAnonymous = async (id) => {
  return httpApiApp.get(`/process/landlord/${id}`);
};

const getLandlordByIdAnonymous = async (regNo) => {
  return httpApiApp.get(`/process/landlordByReg/${regNo}`);
};

const getDomesticHelperByLandlordEpicNumberAnonymous = async (id) => {
  return httpApiApp.get(`/process/landlord/domesticHelper/${id}`);
};

const updateAnonymous = async (data) => {
  return httpApiApp.post("/process/updateLandlord", data);
};

const getAllLandlordsByLocalityCodeForPagination = async (username, paramcode, pageno) => {
  return await http.get(`/process/GetListLandlordsByLACode/user/${username}/${paramcode}/${pageno}`);
}

const getAllLandlordsByLocalityCode = async (username, paramcode) => {
  return await http.get(`/process/GetTotalLandlordsByLACode/user/${username}/${paramcode}`);
}

const applyClearance = async (data) => {
  return httpApiApp.post(`process/SaveApplication_Police`, data);
};

export default {
  getByEpicNumber,
  getAll,
  getDomesticHelperByLandlordEpicNumber,
  create,
  update,
  remove,
  removeAll,
  signIn,
  getLandlordById,
  checkLandlord,
  generateRC,

  // api's for Anonymous User
  getByEpicNumberAnonymous,
  getLandlordByIdAnonymous,
  getDomesticHelperByLandlordEpicNumberAnonymous,
  updateAnonymous,
  getAllLandlordsByLocalityCode,
  getAllLandlordsByLocalityCodeForPagination,
  applyClearance
};
