import React from "react";
import { Grid } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import ApplicationDataTable from "../offlineapplication/DataTable";
import * as Constants from "../../Constants";
import NewApplication from "../offlineapplication/NewApplication";
import PageHeader from "../../components/Header/PageHeader";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import { getDecryptedData } from "../../api/GlobalService";

export default function Pending() {
  // const role = getDecryptedData("role");
  const role = sessionStorage.getItem("role");

  // let user = role === "MODERATOR" ? "sub-locality" : "police officer";

  return (
    <>
      {/* <PageTitle title="Pending Applications" /> */}
      <PageHeader
        title="Pending Applications"
        subTitle={`Applications that are yet to be approved by the headman goes here.`}
        icon={<AvTimerIcon fontSize="large" />}
      />
      {/* {role === Constants.role.USER || role === Constants.role.MODERATOR ? (
        <NewApplication />
      ) : (
        <></>
      )} */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <ApplicationDataTable
            status={role === "MODERATOR" ? "LARP" : "LAVP"}
          />
        </Grid>
      </Grid>
    </>
  );
}
