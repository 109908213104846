import { Box, FormControl, Input, InputLabel } from "@mui/material";
import React from "react";

const SubLocalityBasicDetails = ({ profile, setProfile, disabled }) => {
  const handleFirstNameChange = (event) => {
    setProfile({ ...profile, firstName: event.target.value });
  };
  return (
    <Box
      sx={{
        display: "flex",
        gap: 3,
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <FormControl disabled={disabled}>
        <InputLabel htmlFor="name">Name</InputLabel>
        {profile !== null && (
          <Input
            id="name"
            onChange={handleFirstNameChange}
            defaultValue={profile.headManName}
            disabled
          />
        )}
      </FormControl>
      <FormControl disabled={disabled}>
        <InputLabel htmlFor="name">Sub-Locality/Wards Name</InputLabel>
        {profile !== null && (
          <Input
            id="name"
            // onChange={handleSubLocalityNameChange}
            defaultValue={profile.localAuthorityName}
            disabled
          />
        )}
      </FormControl>
    </Box>
  );
};

export default SubLocalityBasicDetails;
