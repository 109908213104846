import React from "react";
import { Grid } from "@material-ui/core";
import * as Constants from "../../Constants";
// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import ApplicationDataTable from "../offlineapplication/DataTable";
import NewApplication from "../offlineapplication/NewApplication";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import PageHeader from "../../components/Header/PageHeader";
import { getDecryptedData } from "../../api/GlobalService";

export default function Approved() {
  // const role = getDecryptedData("role");
  const role = sessionStorage.getItem("role");

  return (
    <>
      {/* <PageTitle title="Approved Applications" /> */}
      <PageHeader
        title="Approved Applications"
        subTitle="Applications that are approved by the headman goes here."
        icon={<CheckCircleOutlineIcon fontSize="large" />}
      />
      {/* {role === Constants.role.USER || role === Constants.role.MODERATOR ? (
        <NewApplication />
      ) : (
        <></>
      )} */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <ApplicationDataTable status="PLVP" />
        </Grid>
      </Grid>
    </>
  );
}
