import React from "react";
import { Box, DialogContent, IconButton } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@mui/icons-material/Close";

const ImageDialog = (props) => {
  const { openImage, setOpenImage, image } = props;
  return (
    <Dialog open={openImage} onClose={() => setOpenImage(false)}>
      <DialogContent >
        <IconButton
          style={{
            backgroundColor: "#eb5809",
            color: "#626060",
            position: "absolute",
            right: 0,
            top: 0,
            height: "20px",
            width: "20px",
            borderRadius: 5,
          }}
          onClick={() => setOpenImage(false)}
        >
          {/* <CancelOutlinedIcon /> */}
          <CloseIcon />
        </IconButton>

        <Box
          component="img"
          sx={{
            width: "100%",
          }}
          alt="image document"
          src={image}
          />
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialog;
