import * as React from "react";
import "react-data-grid/dist/react-data-grid.css";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";

import { Button, Chip } from "@material-ui/core";

import RentalUnitService from "../../api/RentalUnitService";
import CustomDataTable from "../../components/data-table/CustomDataTable";
import EditIcon from "@mui/icons-material/Edit";
import RentalUnitFormDialog from "./RentalUnitFormDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { Backdrop, CircularProgress } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmDialog from "../../components/confirm-dialog/ConfirmDialog";
import LandlordService from "../../api/LandlordService";
import { getDecryptedData } from "../../api/GlobalService";

const useStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function TenantsDataTable(props) {
  const classes = useStyle();

  const {
    currentApplication,
    editRentalUnit,
    deleteRentalUnit,
    openSnackBar,
    existingApplication,
    addRentalUnit
  } = useContext(GlobalContext);
  const [rentCode, setRentCode] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [row, setRow] = React.useState([]);
  const [rowDetails, setRowDetails] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [openConfirmDialogFreeTenant, setOpenConfirmDialogFreeTenant] = React.useState(false)

  const [rentalUnitProp, setRentalUnitProp] = React.useState({
    houseNo: "",
    floorNo: "",
    address: {
      address1: "",
      address2: "",
      pinCode: "",
      district: "",
    },
    laCode: "",
    landlordId: "",
  });

  const handleEdit = async (rowDetails) => {
    let landlordRentalUnit = await currentApplication.rentalUnit.filter(
      (rentalUnit) => rentalUnit.rentcode === rowDetails.rowData[8]
    );

    let s = { ...rentalUnitProp };
    s.houseNo = await landlordRentalUnit[0].houseNo;
    s.floorNo = await landlordRentalUnit[0].floorNo;
    s.address.address1 = await landlordRentalUnit[0].address1;
    s.address.address2 = await landlordRentalUnit[0].address2;
    s.address.pinCode = await landlordRentalUnit[0].pinCode;
    s.address.district = await landlordRentalUnit[0].district.districtCode;
    s.laCode = await landlordRentalUnit[0].la.localAuthorityCode;
    s.landlordId = await currentApplication.id;
    s.rentcode = await rowDetails.rowData[8];
    await setRentalUnitProp(s);
    setOpen(true);
  };

  const handleDelete = async () => {
    setBackdropOpen(true);
    // getDecryptedData("role") === null
    sessionStorage.getItem("role") === null
      ? RentalUnitService.editOrDeleteRentalUnitAnonymous(
        {
          rentcode: rowDetails.rowData[8],
        },
        1
      )
        .then(async (response) => {
          await deleteRentalUnit(rowDetails.rowData[8]);
          setBackdropOpen(false);
          setOpenConfirmDialog(false);
          openSnackBar({
            open: true,
            message: "Successfully Deleted Rental Unit",
            severity: "success",
          });
        })
        .catch(async (err) => {
          setBackdropOpen(false);
          setOpenConfirmDialog(false);

          openSnackBar({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        })
      : RentalUnitService.editOrDeleteRentalUnit(
        {
          rentcode: rowDetails.rowData[8],
        },
        1
      )
        .then(async (response) => {
          await deleteRentalUnit(rowDetails.rowData[8]);
          setBackdropOpen(false);
          setOpenConfirmDialog(false);

          openSnackBar({
            open: true,
            message:
              response && response.data && response.data.message
                ? response.data.message
                : "Successfully Deleted Rental Unit",
            severity: "success",
          });
        })
        .catch(async (err) => {
          setBackdropOpen(false);
          setOpenConfirmDialog(false);

          openSnackBar({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        });
  };

  const handleFreeTenant = () => {
    // setBackdropOpen(true);

    setRentCode(rowDetails.rowData[8]);
    // getDecryptedData("role") === "MODERATOR" ||
    //   getDecryptedData("role") === "SUPERMODERATOR" ?
    sessionStorage.getItem("role") === "MODERATOR" ||
      sessionStorage.getItem("role") === "SUPERMODERATOR" ?
      RentalUnitService.changeRentalUnitStatusByLA(      //use changeRentalUnitStatusByLA for Local Authy
        currentApplication.id,
        rowDetails.rowData[8]
      ).then(async (res) => {
        await editRentalUnit(rowDetails.rowData[8]);
        setBackdropOpen(false);
        alert("Successfully Updated!");
        const response = await LandlordService.getByEpicNumber(currentApplication.landlord_id);
        console.log("response::: ", response)
        response.data.exist = true;
        response.data.id = response.data.landlord_id;
        // addRentalUnit({ ...response.data, policestation: response.data.la.ps.policeStationName, localAuthority: response.data.la.localAuthorityName });
        //setLandLordDetails(response.data);
        existingApplication(response.data);
        for (var i = 0; i < response.data.rentalUnit.length; i++) {
          response.data.rentalUnit[i]["slno"] = i + 1;
          response.data.rentalUnit[i]["rentcode"] = response.data.rentalUnit[i].rentcode;
          response.data.rentalUnit[i]["policestation"] = response.data.rentalUnit[i].la.ps.policeStationName;
          response.data.rentalUnit[i]["localAuthority"] = response.data.rentalUnit[i].la.localAuthorityName;
          if (response.data.rentalUnit[i].la != null) {
            response.data.rentalUnit[i]["policestation"] = response.data.rentalUnit[i].la.ps.policeStationName;
            response.data.rentalUnit[i]["localAuthority"] = response.data.rentalUnit[i].la.localAuthorityName;
          }
        }
        setRow(response.data.rentalUnit)
      })
      :
      RentalUnitService.changeRentalUnitStatus(      //use changeRentalUnitStatus for Landlord
        currentApplication.id,
        rowDetails.rowData[8]
      ).then(async (res) => {
        await editRentalUnit(rowDetails.rowData[8]);
        setBackdropOpen(false);

        alert("Successfully Updated!");
        const response = await LandlordService.getByEpicNumberAnonymous(currentApplication.landlord_id);
        console.log("response::: ", response)
        response.data.exist = true;
        response.data.id = response.data.landlord_id;
        // addRentalUnit({ ...response.data, policestation: response.data.la.ps.policeStationName, localAuthority: response.data.la.localAuthorityName });
        //setLandLordDetails(response.data);
        existingApplication(response.data);
        for (var i = 0; i < response.data.rentalUnit.length; i++) {
          response.data.rentalUnit[i]["slno"] = i + 1;
          response.data.rentalUnit[i]["rentcode"] = response.data.rentalUnit[i].rentcode;
          response.data.rentalUnit[i]["policestation"] = response.data.rentalUnit[i].la.ps.policeStationName;
          response.data.rentalUnit[i]["localAuthority"] = response.data.rentalUnit[i].la.localAuthorityName;
          if (response.data.rentalUnit[i].la != null) {
            response.data.rentalUnit[i]["policestation"] = response.data.rentalUnit[i].la.ps.policeStationName;
            response.data.rentalUnit[i]["localAuthority"] = response.data.rentalUnit[i].la.localAuthorityName;
          }
        }
        setRow(response.data.rentalUnit)

      });

  }

  const columns = [
    {
      name: "slno",
      label: "Sl.No",
      options: {
        display: false,
        filter: true,
      },
    },
    {
      name: "houseNo",
      label: "House Number",
      options: {
        filter: true,
      },
    },
    {
      name: "floorNo",
      label: "Floor Number/Flat Number",
      options: {
        filter: true,
      },
    },
    // { name: "pinCode", label: "Pincode",  },
    {
      name: "address1",
      label: "Address Line 1",
      options: {
        filter: true,
      },
    },
    {
      name: "address2",
      label: "Address Line 2",
      options: {
        filter: true,
      },
    },
    {
      name: "policestation",
      label: "Police Station",
      options: {
        filter: true,
      },
    },
    {
      name: "localAuthority",
      label: "Sub-Locality/Wards",
      options: {
        filter: true,
      },
    },
    {
      name: "vacant",
      label: "Vacancy",
      options: {
        filter: true,
        sort: false,

        customBodyRender: (value, tableMeta, updateValue) => {
          console.log("table:", tableMeta)
          return tableMeta.rowData[7] ? (
            <Chip
              label="Yes"
              style={{ padding: 0, color: "#fff", backgroundColor: "#92d459" }}
            />
          ) : (
            <Chip
              label="No"
              style={{ padding: 0, color: "#fff", backgroundColor: "red" }}
            />
          );
        },
      },
    },
    {
      name: "rentcode",
      label: "Rent Code",
      options: {
        display: false,
        filter: true,
      },
    },
    {
      name: "update",
      label: "Update Vacancy",
      options: {
        // display: sessionStorage.getItem("role") === null ? true : false,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              disabled={
                tableMeta.rowData[7]
                // ||
                // sessionStorage.getItem("role") === "MODERATOR" ||
                // sessionStorage.getItem("role") === "SUPERMODERATOR"
              }
              size={"small"}
              variant="contained"
              color="primary"
              onClick={() => {
                setRowDetails(tableMeta);
                setOpenConfirmDialogFreeTenant(true);
              }}
            >
              Update Status
            </Button>
          );
        },
      },
    },
    {
      name: "edit",
      label: "Edit Rental Unit",
      options: {
        filter: false,
        sort: false,

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              size={"small"}
              variant="contained"
              color="primary"
              onClick={() => {
                handleEdit(tableMeta);
              }}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          );
        },
      },
    },
    {
      name: "delete",
      label: "Disown Rental Unit",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              size={"small"}
              variant="contained"
              color="primary"
              onClick={() => {
                setRowDetails(tableMeta);
                setOpenConfirmDialog(true);
              }}
              startIcon={<DeleteIcon />}
            >
              Disown
            </Button>
          );
        },
      },
    },
  ];

  React.useEffect(() => {
    let data = [];
    if (currentApplication != undefined) {
      if (
        currentApplication.rentalUnit != null ||
        currentApplication.rentalUnit != undefined
      ) {
        data = currentApplication.rentalUnit;
        for (var i = 0; i < data.length; i++) {
          data[i]["slno"] = i + 1;
          data[i]["rentcode"] = data[i].rentcode;
          data[i]["policestation"] = data[i].la.ps.policeStationName;
          data[i]["localAuthority"] = data[i].la.localAuthorityName;
          if (data[i].la != null) {
            data[i]["policestation"] = data[i].la.ps.policeStationName;
            data[i]["localAuthority"] = data[i].la.localAuthorityName;
          }
        }
      }
    }

    setRow(data);
  }, [open, backdropOpen]);

  return (
    <div style={{ height: 700, width: "100%" }}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomDataTable rows={row} columns={columns} title="Rental Units" />
      <RentalUnitFormDialog
        open={open}
        setOpen={setOpen}
        rentalUnitProp={rentalUnitProp}
        setRentalUnitProp={setRentalUnitProp}
      />
      <ConfirmDialog
        text="Are you sure you want to delete this rental unit?"
        openConfirmDialog={openConfirmDialog}
        setOpenConfirmDialog={setOpenConfirmDialog}
        handleDelete={handleDelete}
      />
      <ConfirmDialog
        text="An application might be under process. This action might cancel your application or indicate that the tenant has left the locality. Are you sure the tenant has vacated the rental-unit? If yes, you can proceed."
        openConfirmDialog={openConfirmDialogFreeTenant}
        setOpenConfirmDialog={setOpenConfirmDialogFreeTenant}
        handleDelete={handleFreeTenant}
      />
    </div>
  );
}
