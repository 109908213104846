import React from "react";
import { Grid } from "@material-ui/core";

// styles

// components
import PageTitle from "../../components/PageTitle/PageTitle";

export default function Application() {
  return (
    <>
      <PageTitle title="All Applications" />
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}></Grid>
      </Grid>
    </>
  );
}
