import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import { Snackbar, Typography } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Application from "../../pages/application";
import OfflineApplication from "../../pages/offlineapplication";
import RentalUnit from "../../pages/rentalunits";
import Tenants from "../../pages/tenants";
import Inbox from "../../pages/application/Pending";
import Outbox from "../../pages/application/Rejected";
import Approved from "../../pages/application/Approved";

import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";

// context
import { useLayoutState } from "../../context/LayoutContext";
import Rectification from "../../pages/application/Rectification";
import LandlordTenants from "../landlord/LandlordTenants";
import AddPoliceStation from "../../pages/policestations/AddPoliceStation";
import AddLocalAuthority from "../../pages/localauthorities/AddLocalAuthority";
import AddLocalAuthorityFromAdmin from "../../pages/localauthorities/AddLocalAuthorityFromAdmin";
import AddLocalAuthority2 from "../../pages/localauthorities2/AddLocalAuthority2";

import Forwarded from "../../pages/application/Forwarded";
import Alert from "@mui/material/Alert";
import TenantRevisit from "../../pages/tenants/TenantRevisit";
import Report from "../../pages/policestations/Report";
import Error from "../../pages/error";
import LogRecords from "../../pages/admin/LogRecords";
import { signOut, useUserDispatch } from "../../context/UserContext";
import IdleTimer from "react-idle-timer";
import { IdleTimeOutModal } from "./IdleTimeoutModal";
import { Box } from "@mui/system";
import Users from "../../pages/admin/Users";
import FamilyMemberFormUsingFormik from "../../pages/tenants/FamilyMemberFormUsingFormik";
import SearchDialog from "../SearchDialog/SearchDialog";
import AddLocalityFromAdmin from "../../pages/localauthorities2/AddLocalityFromAdmin";
import UserProfile from "../../pages/user/UserProfile";
import NIC from "./NIC.png";
import digitalIndia from "./digitalIndia.png";
import UpdateMedia from "../../pages/update-media/UpdateMedia";
import SearchTenantAndLandlord from "../../pages/tenants/SearchTenantAndLandlord";
import ApprovedTenants from "../../pages/tenants/ApprovedTenants";
import { getDecryptedData } from "../../api/GlobalService";
import ShiftedTenants from "../../pages/tenants/ShiftedTenants";
import LandlordTable from "../../pages/landlord/LandlordTable";


function Layout(props) {
  var classes = useStyles();
  var userDispatch = useUserDispatch();

  // SessionTimeout to be implimented later
  const { snackbar, openSnackBar } = useContext(GlobalContext);
  const [openSerchDialog, setOpenSearchDialog] = useState(false);

  let idleTimer = null;
  const [state, setState] = useState({
    // timeout: 1000 * 5 * 1,
    timeout: 1000 * 450 * 1, //auto-logout after 15 minutes
    showModal: false,
    userLoggedIn: false,
    isTimedOut: false,
  });

  // global
  var layoutState = useLayoutState();

  const onAction = (e) => {
    // console.log("user did something", e);
    setState({ ...state, isTimedOut: false });
  };

  const onActive = (e) => {
    console.log("user is active", e);
    setState({ ...state, isTimedOut: false });
  };

  const onIdle = (e) => {
    console.log("user is idle", e);
    const isTimedOut = state.isTimedOut;
    if (isTimedOut) {
      signOut(userDispatch, props.history);
      alert("Your session has expired. Please login again!");
      props.history.push("/");
    } else {
      setState({ ...state, showModal: true });
      idleTimer.reset();
      setState({ ...state, isTimedOut: true });
    }
  };

  useEffect(() => {
    //   // trying to call signOut() on close of tab

    window.addEventListener("beforeunload", () => {
      signOut(userDispatch, props.history);
    });
  });

  const handleClose = () => {
    setState({ ...state, showModal: false });
  };

  const handleLogout = () => {
    setState({ ...state, showModal: false });
    props.history.push("/");
  };
  return (
    <>
      {
        sessionStorage.getItem("accessToken")
          // getDecryptedData("accessToken")
          !== null ? (
          <Box>
            <div className={classes.root}>
              <IdleTimer
                ref={(ref) => {
                  idleTimer = ref;
                }}
                element={document}
                onActive={onActive}
                onIdle={onIdle}
                onAction={onAction}
                debounce={250}
                timeout={state.timeout}
              />
              <IdleTimeOutModal
                showModal={state.showModal}
                handleClose={handleClose}
                handleLogout={handleLogout}
              />
              <Header
                history={props.history}
                setOpenSearchDialog={setOpenSearchDialog}
              />
              <Sidebar />
              <SearchDialog
                openSerchDialog={openSerchDialog}
                setOpenSearchDialog={setOpenSearchDialog}
              />
              <div
                className={classnames(classes.content, {
                  [classes.contentShift]: layoutState.isSidebarOpened,
                })}
              >
                <div className={classes.fakeToolbar} />
                <Snackbar
                  open={snackbar.open}
                  autoHideDuration={3000}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  onClose={() => openSnackBar({ open: false })}
                >
                  <Alert
                    onClose={() => {
                      openSnackBar({ open: false });
                    }}
                    severity={snackbar.severity}
                  >
                    {snackbar.message}
                  </Alert>
                </Snackbar>

                <Switch>
                  <Route path="/app/dashboard" component={Dashboard} />
                  {/* <Route path="/app/profile" component={Profile} /> */}
                  <Route path="/app/profile" component={UserProfile} />

                  <Route path="/app/applications" component={Application} />
                  <Route
                    path="/app/offlineapplication"
                    component={OfflineApplication}
                  />
                  <Route path="/app/application/pending" component={Inbox} />
                  <Route path="/app/application/rejected" component={Outbox} />
                  <Route path="/app/application/approved" component={Approved} />
                  <Route
                    path="/app/application/forwarded"
                    component={Forwarded}
                  />
                  <Route
                    path="/app/application/rectification"
                    component={Rectification}
                  />
                  <Route
                    path="/app/landlord/tenants"
                    component={LandlordTenants}
                  />

                  <Route path="/app/rentalunits" component={RentalUnit} />
                  <Route path="/app/tenants" component={Tenants} />

                  <Route
                    path="/app/policestations"
                    component={AddPoliceStation}
                  />
                  {/* // <Route path="/app/user" component={AddUser} /> */}
                  <Route path="/app/localities" component={AddLocalAuthority} />
                  <Route
                    path="/app/local-authorities"
                    component={AddLocalAuthority2}
                  />
                  <Route
                    exact
                    path="/app/ui"
                    render={() => <Redirect to="/app/ui/icons" />}
                  />
                  <Route path="/app/new-application" component={TenantRevisit} />
                  <Route path="/app/report" component={Report} />
                  <Route path="/app/log-records" component={LogRecords} />
                  <Route
                    path="/app/registered-local-authorities"
                    component={AddLocalAuthorityFromAdmin}
                  />
                  <Route
                    path="/app/registered-localities"
                    component={AddLocalityFromAdmin}
                  />
                  <Route path="/app/users" component={Users} />
                  <Route
                    path="/app/formik"
                    component={FamilyMemberFormUsingFormik}
                  />
                  <Route path="/app/media-update" component={UpdateMedia} />
                  <Route
                    path="/app/update-tenant"
                    component={SearchTenantAndLandlord}
                  />
                  <Route
                    path="/app/approved-tenants"
                    component={ApprovedTenants}
                  />
                  <Route
                    path="/app/shifted-tenants"
                    component={ShiftedTenants}
                  />
                  <Route
                    path="/app/landlords"
                    component={LandlordTable}
                  />
                </Switch>
              </div>
            </div>
            <Box
              sx={{
                // backgroundImage: "linear-gradient(to right, #29335C, #B12B39)",
                backgroundColor: "#232338",
                color: "#fff",
                padding: "1%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    lg: "row",
                    md: "row",
                    sm: "column",
                    xs: "column",
                  },
                  gap: "5px",
                  justifyContent: "center",
                }}
              >
                <Box alignSelf="center" sx={{ backgroundColor: "#fff" }}>
                  <img src={digitalIndia} alt="logo" height={42} />
                </Box>
                <Box alignSelf="center" sx={{ backgroundColor: "#fff" }}>
                  <img src={NIC} alt="logo" />
                </Box>
              </Box>
              <Typography className={classes.heading} align="center">
                <br />
                This webapp is developed by NIC Meghalaya <br /> Copyright ©{" "}
                {new Date().getFullYear()} - All Rights Reserved
              </Typography>
            </Box>
          </Box>
        ) : (
          <Error />
        )}
    </>
  );
}

export default withRouter(Layout);
