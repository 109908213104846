import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import { Alert, Autocomplete } from "@mui/lab";
import { FormControl, Snackbar } from "@material-ui/core";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import TenantTab from "../offlineapplication/TenantTab";
import { Box, Typography } from "@mui/material";

export default function NewTenant(props) {
  const [open, setOpen] = React.useState(false);
  const [existingTenant, setExistingTenant] = useState(false);
  const { currentApplication } = useContext(GlobalContext);
  const [selectedRentalUnit, setSelectedRentalUnit] = useState(null);
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const handleClickOpen = () => {
    setSelectedRentalUnit(null);
    setOpen(true);
  };
  const handleClose = () => {
    setSelectedRentalUnit(null);
    setOpen(false);
  };
  const handleRentalUnitChange = (value) => {
    setSelectedRentalUnit(value);
  };

  return (
    <div>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
      >
        <Alert
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          severity={snackBar.severity}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button
          size={"small"}
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => {
            setExistingTenant(true);
            handleClickOpen();
          }}
        >
          Existing Tenant
        </Button>
        <Button
          size={"small"}
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => {
            setExistingTenant(false);
            handleClickOpen();
          }}
        >
          New Tenant
        </Button>
      </Box>
      <Box minHeight={10} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="1000px"
        minHeight="60%"
        maxHeight="60%"
      >
        <DialogTitle id="form-dialog-title">
          <Typography align="center" variant="h6">
            {existingTenant
              ? "Application for an existing tenant"
              : "Application for new tenant"}
          </Typography>
        </DialogTitle>
        {currentApplication.rentalUnit.length === 0 ? (
          <Alert severity="error">
            No Rental Unit Found for this Landlord, Click <b>Back</b> and then
            Click <b>RENTAL UNITS</b> to add rental unit
          </Alert>
        ) : (
          <></>
        )}
        <DialogContent>
          {selectedRentalUnit === null ? (
            <FormControl>
              <Autocomplete
                onChange={(event, value) => {
                  handleRentalUnitChange(value);
                }}
                id="standard-basic"
                options={currentApplication.rentalUnit.filter(
                  (unit) => unit.vacant === true
                )}
                getOptionLabel={(option) =>
                  "Floor Number:" +
                  option.floorNo +
                  "/ House Number:" +
                  option.houseNo +
                  "/ Address1:" +
                  option.address1 +
                  "/ Address2:" +
                  option.address2
                }
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        Rental Unit <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          ) : (
            <TenantTab
              selectedRentalUnit={selectedRentalUnit}
              closeTenantModal={handleClose}
              activeStep={props.activeStep}
              setActiveStep={props.setActiveStep}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
