import { Autocomplete, Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { getDecryptedData } from "../../api/GlobalService";

const reasons = [
  { reason: "Lack of Documents" },
  { reason: "Invalid Documents" },
  { reason: "Criminal Issues" },
  { reason: "Duplicate Application" },
  { reason: "Others" },
];

const ComboBox = ({ remarks, setRemarks }) => {

  const [customRemark, setCustomRemark] = useState(false);
  const [customRemarkText, setCustomRemarkText] = useState("");

  return (
    // <FormControl>
    <>
      <Autocomplete
        size={"small"}
        onChange={(event, value) => {
          if (value !== null) {
            if (value.reason === 'Others') {
              setCustomRemark(true);
            }
            else {
              sessionStorage.getItem("role") === "MODERATOR"
                ? setRemarks(`(Recommended for rejection: ${value.reason})`)
                : setRemarks(`(Rejected by Locality/Cantonment: ${value.reason})`);
            }
            // else {
            //   getDecryptedData("role") === "MODERATOR"
            //     ? setRemarks(`(Recommended for rejection: ${value.reason})`)
            //     : setRemarks(`(Rejected by Locality: ${value.reason})`);
            // }
          } else {
            setRemarks("");
          }
        }}
        id="standard-basic"
        options={reasons}
        getOptionLabel={(option) => option.reason}
        sx={{ width: 200 }}
        renderInput={(params) => (
          <TextField {...params} label="Remarks *" variant="outlined" />
        )}
      />
      <Box sx={{ minHeight: "0.5rem" }} />
      {customRemark && <TextField label="Enter reason.."
        onChange={(e) =>
          sessionStorage.getItem("role") === "MODERATOR"
            ? setRemarks(`(Recommended for rejection: ${e.target.value})`)
            : setRemarks(`(Rejected by Locality/Cantonment: ${e.target.value})`)
          // getDecryptedData("role") === "MODERATOR"
          //   ? setRemarks(`(Recommended for rejection: ${e.target.value})`)
          //   : setRemarks(`(Rejected by Locality: ${e.target.value})`)
        } />
      }
      <Box sx={{ minHeight: "0.5rem" }} />

    </>

    // </FormControl>
  );
};

export default ComboBox;
