import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Alert, Tabs, Tab, Paper, Stack } from '@mui/material';
import { Backdrop, CircularProgress, FormControl, Snackbar, Step, StepLabel, Stepper, TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import ApplicationService from '../../api/ApplicationService';
import { GlobalContext } from '../../context/GlobalState';
import InfoIcon from "@mui/icons-material/Info";
import httpApi from "../../api/http-common-apikey";
import JSEncrypt from "jsencrypt";
import httpApiApp from "../../api/http-common-apikey-application";
import LandlordTab from '../offlineapplication/LandlordTab';
import LandlordService from '../../api/LandlordService';
import PropTypes from "prop-types";
import { useUserDispatch, redirectLogout } from "../../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  downloadApp: {
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  stepper: {
    "& .MuiStepIcon-active": { color: "#F3A712" },
    "& .MuiStepIcon-completed": { color: "#29335C" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "none" },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}





const LandlordStepperForm = () => {
    const classes = useStyles();
      const errorInitialState = {
    error: false,
    loginId: {
      exist: false,
      helperText: "Landlord ID does not exist.",
    },
    otpValue: {
      exist: false,
      helperText: "Invalid OTP, Please retry!",
    },
  };

      const {
    newApplication,
    existingApplication,
    currentApplication,
    openSnackBar,
    initialState,
    runningApplication,
    } = useContext(GlobalContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [backDropOpen, setBackDropOpen] = useState(false);
    const steps = ["Landlord Election ID", "Landlord Details"];
    const [registerFlag, setRegisterFlag] = useState(false);
    const [download, setDownload] = useState(true);
    const [tabValue, setTabValue] = React.useState(0);
     const [value, setValue] = React.useState("id");
  const [lndLogin, setLndLogin] = useState(false);
  const [landlordExists, setLandlordExists] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [publicKey, setPublicKey] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpValue, setOtpValue] = useState("");
    const [loginValue, setLoginValue] = useState("");
    
    const [errors, setErrors] = useState(errorInitialState);
      const [invalidEpic, setInvalidEpic] = useState(false);
  var userDispatch = useUserDispatch();

    
      const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
      });
      const checkValidEpic = (epic) => {
    let alp = epic.slice(0, 3);
    let num = epic.slice(3, 10);
    return /^\d+$/.test(num) && /^[a-zA-Z]+$/.test(alp);
  };

      function isEmptyString(str) {
    return !str || 0 === str.length;
  }
    
     const isValidEpicCard = (val) => {
    return !isEmptyString(val);
  };
    
     const handleDownload = async () => {
    setDownload((download) => !download);
    console.log(runningApplication);
    const base64Pdf = await ApplicationService.getApplicationPdf(
      runningApplication
    );
    setDownload((download) => !download);
    var blob = base64toBlob(base64Pdf.data.base64img);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
    };
    
      function base64toBlob(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
    }

    const validateLoginValue = (value) => {
    const regex = /^[A-Za-z]{3}\d{7}$/;
    return regex.test(value);
    };
    
      const handleReset = () => {
    setActiveStep(0);
    setOtpSent(false);
    setNotFound(false);
    setLoginValue("");
    setOtp("");
    setOtpValue("")
    setLandlordExists(false);
    setInvalidEpic(false);
  };
    
    
  const handleTabChange = (event, newValue) => {
    setLoginValue("");

    setTabValue(newValue);
    setNotFound(false);

    setRegisterFlag(!registerFlag);
  };

    
     function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Box sx={{ width: "100%" }}>
            <Snackbar
              open={snackBar.open}
              sx={{ height: "100%" }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={(event, reason) => {
                if (reason === "clickaway") {
                  return; 
                }
                setSnackBar({ ...snackBar, open: false });
              }}
            >
              <Alert
                onClose={() => {
                  setSnackBar({ ...snackBar, open: false }); // Correctly close the Snackbar when 'x' is clicked
                }}
                severity={snackBar.severity}
              >
                {snackBar.message}
              </Alert>
            </Snackbar>
            <Box>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                centered
              >
                <Tab label="Login" {...a11yProps(0)} />
                <Tab label="Register" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <Paper
                // variant="outlined"
                sx={{
                  padding: "6%",
                  width: { md: "41%", lg: "41%", sm: "100%", xs: "100%" },
                  margin: "auto",
                }}
                elevation={3}
              >
                <FormControl
                  style={{
                    width: "100%",
                    marginBottom: 0,
                    paddingBottom: 0,
                    // paddingLeft: "20px",
                  }}
                >
                  <Stack spacing={2}>
                    <Box sx={{ display: "flex", alignSelf: "center" }}>
                      <InfoIcon color="primary" />
                      <Typography
                        variant="subtitle1"
                        align="center"
                        color="primary"
                      >
                        Login with Landlord Epic number
                      </Typography>
                    </Box>

                    <Stack spacing={1}>
                      <Stack direction="row" spacing={1}>
                        <TextField
                          autoFocus
                          disabled={lndLogin}
                          fullWidth
                          style={{
                            marginBottom: "20px",
                          }}
                          id="id"
                          value={value === "id" ? loginValue : ""}
                          onChange={(e) => {
                            e.target.value.length < 11 &&
                              setLoginValue(e.target.value.toUpperCase());
                          }}
                          error={errors.loginId.exist === true ? true : false}

                          helperText={
                            errors.loginId.exist ? errors.loginId.helperText : ""
                          }
                          // style={{ width: "100%" }}
                          label="Epic Number"
                        />
                      </Stack>
                    </Stack>

                    <Button
                      disabled={!validateLoginValue(loginValue) || otpSent}
                      color="primary"
                      variant="contained"
                      style={{ width: "50%", margin: "auto" }}
                      onClick={() =>
                        httpApi
                          .get(`apikey/getOTP/${loginValue}`)
                          .then((res) => {

                            setSnackBar({
                              message:
                                // "An OTP has been sent to your registered mobile number or email",
                              "Please enter OTP received in your registered mobile number or email.",  
                              severity: "success",
                              open: true,
                            });
                          })
                          .then(() => setOtpSent(true))
                          .catch(async (err) => {
                            err.response.data.status === 404 && setSnackBar({
                              message:
                                "Landlord does not exist!",
                              severity: "error",
                              open: true,
                            });
                            // alert(err.response.data.message)
                            // const e = await errorInitialState;  
                            // e.error =await true;
                            // e.loginId.exist =await true;
                            // e.loginId.helperText =await err.response.data.message;
                            // setNotFound(true)
                          })
                      }
                    >
                      Get OTP
                    </Button>
                    <TextField
                      // disabled={!otpSent}
                      autoFocus
                      fullWidth
                      id="id"
                      value={otpValue}
                      onChange={async (e) => {
                        let otp = e.target.value;
                        setOtpValue(otp);

                        if (otp.length >= 6) {
                          try {
                            let crypt = new JSEncrypt();
                            crypt.setPublicKey(publicKey);
                            let enc = crypt.encrypt(otp);
                            httpApi.defaults.headers["Content-type"] =
                              "application/json";
                            setOtp(enc);
                          } catch (error) {
                            console.log(error.response);
                          }
                        }
                      }}
                      label="Enter OTP"
                    />
                  </Stack>
                </FormControl>
              </Paper>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Paper
                // variant="outlined"
                sx={{
                  padding: "3%",
                  width: { md: "30%", lg: "30%", sm: "100%", xs: "100%" },
                  margin: "auto",
                }}
                elevation={3}
              >
                <FormControl
                  style={{
                    width: "100%",
                    marginBottom: 0,
                    paddingBottom: 0,
                    // paddingLeft: "20px",
                  }}
                >
                  <Stack spacing={2}>
                    <Box sx={{ display: "flex", alignSelf: "center" }}>
                      <InfoIcon color="primary" />
                      <Typography
                        variant="subtitle1"
                        align="center"
                        color="primary"
                      >
                        Register with Landlord Epic no.
                      </Typography>
                    </Box>
                    <TextField
                      autoFocus
                      style={{
                        marginBottom: "20px",
                        margin: "auto",
                      }}
                      id="id"
                      fullWidth
                      value={loginValue}
                      onChange={(e) => {
                        e.target.value.length < 11 &&
                          setLoginValue(e.target.value.toUpperCase());
                      }}
                      // style={{ width: "100%" }}
                      label="Epic Number"
                      error={landlordExists || invalidEpic}
                      helperText={
                        (landlordExists &&
                          "Landlord with entered EPIC number already exists. Please try logging in!") ||
                        (invalidEpic && "Invalid Epic!")
                      }
                    />
                  </Stack>
                </FormControl>
              </Paper>
            </TabPanel>
          </Box>
        );
      case 1:
        return (
          <Paper elevation={0}>
            <LandlordTab registerFlag={registerFlag} setActiveStep={setActiveStep} />
          </Paper>
        );
    }
    }
    
    const handleNext = async () => {
    switch (activeStep) {
      case 0:
        {
          if (isValidEpicCard(loginValue) === false) {
            openSnackBar({
              open: true,
              severity: "error",
              message: "Landlord Epic Number required!",
            });
            newApplication({ epicNumber: loginValue, exist: false });
            setLoginValue("");
            return;
          }

          registerFlag
            ? await LandlordService.checkLandlord(loginValue).then().catch(error => {
              if (error.response != undefined && error.response.status === 404) {
                // EPIC NUMBER Not exist, promt to add a new one
                openSnackBar({
                  open: true,
                  message:
                    `Welcome! This will be a fresh registration for landlord with EPIC Number ${loginValue}`,
                  severity: "success",
                });
                //console.log(currentApplication);
                let newApp = initialState;
                newApp.id = loginValue;
                // Reinitialized the currentApplication
                newApplication(newApp);
                // setLandLordDetails({...landlordDetails,exist:false,id:loginValue});
                setActiveStep((prevActiveStep) => prevActiveStep + 1);

              } else {
                if (error.response.status === 401) {
                  openSnackBar({
                    open: true,
                    message: "Authentication Failed",
                    severity: "error",
                  });
                  redirectLogout(userDispatch);
                } else {
                  openSnackBar({
                    open: true,
                    message:
                      "Network Connection Error!, Cannot connect to backend API",
                    severity: "error",
                  });
                }
                // Connection Problem

                // Do no progress
                return;
              }
            }
            )
            : await httpApi
              .post(`apikey/validateOTP/${loginValue}`, otp)
              .then(async (res) => {
                httpApiApp.defaults.headers["API-key"] = res.data;
                const response =
                  value === "id"
                    ? await LandlordService.getByEpicNumberAnonymous(
                      loginValue
                    )
                    : await LandlordService.getLandlordByIdAnonymous(
                      loginValue
                    );
                response.data.exist = true;
                response.data.id = response.data.landlord_id;
                existingApplication(response.data);
                setLoginValue("");
                setNotFound(false);
                setSnackBar({ ...snackBar, open: false });
                if (activeStep === 0 && checkValidEpic(loginValue)) {
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else if (activeStep !== 0) {
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else {
                  return;
                }
              }).catch(error => {
                setSnackBar({
                  message:
                    error.response.data,
                  severity: "error",
                  open: true,
                })
              });
          if (registerFlag) {
            setLandlordExists(true);
            return;
          }

        }

        break;

      case 1: {
        // Validate Entry
        //
        if (currentApplication.exist === false) {
          // initializeApplication();
          openSnackBar({
            open: true,
            message:
              "Landlord details not saved, please save and then continue...",
            severity: "error",
          });

          return;
        }

        if (activeStep === 0 && checkValidEpic(loginValue)) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep !== 0) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          return;
        }

        break;
      }
    }


  };

  return (
    <>
      {/* Navbar */}
      <AppBar position="static" style={{ backgroundColor: "#232338", color: "#fff" }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Landlord Login
          </Typography>
          <Button color="inherit">Home</Button>
          <Button color="inherit">About</Button>
          <Button color="inherit">Contact</Button>
        </Toolbar>
      </AppBar>

      {/* Content for the form */}
      <Box p={2}>
        <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.stepper}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length ? (
        <div>
          <Typography className={classes.instructions}>
            All steps completed
          </Typography>
          <Button
            onClick={handleDownload}
            color="primary"
            disabled={!download}
            className={classes.downloadApp}
          >
            Download the Complete Application
          </Button>
        </div>
      ) : (
        <>
          <Typography className={classes.instructions}>
            {getStepContent(activeStep)}
          </Typography>
          <Box
            alignItems="center"
            justifyContent="center"
            sx={{ display: activeStep === 1 ? "none" : "flex" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                setBackDropOpen(true);
                await handleNext();
                setBackDropOpen(false);
              }}
              disabled={
                // (activeStep === steps.length - 1 && finish === false) ||
                // (activeStep === 0 && loginValue === "")
                otpValue.length < 6 && (!registerFlag || !validateLoginValue(loginValue))
              }
            >
              {registerFlag ? "Next" : "Login"}
            </Button>
            <Button onClick={handleReset} className={classes.backButton}>
              Reset
            </Button>
          </Box>
        </>
      )}
    </div>
      </Box>
    </>
  );
};

export default LandlordStepperForm;