import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import { Alert, Autocomplete } from "@mui/lab";
import MasterData from "../../api/MasterData";
import { FormControl, Snackbar } from "@material-ui/core";
import RentalUnitService from "../../api/RentalUnitService";
import TenantForm from "./TenantForm";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import HelperForm from "./HelperForm";

export default function NewHelper(props) {
  const [open, setOpen] = React.useState(false);

  const { currentApplication, addRentalUnit } = useContext(GlobalContext);

  const [selectedRentalUnit, setSelectedRentalUnit] = useState(null);

  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const handleClickOpen = () => {
    setSelectedRentalUnit(null);
    setOpen(true);
  };

  const closeHelperModal = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setSelectedRentalUnit(null);
    props.setRefresh(!props.refresh)
    setOpen(false);
  };

  const handleRentalUnitChange = (value) => {
    setSelectedRentalUnit(value);
  };

  return (
    <div>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
      >
        <Alert
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          severity={snackBar.severity}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Button
        style={{ float: "right" }}
        variant="contained"
        color="secondary"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        {" "}
        New Helper
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="1000px"
      >
        <DialogTitle id="form-dialog-title">New Helper</DialogTitle>
        <DialogContent>
          <HelperForm
            closeHelperModal={closeHelperModal}
            handleClose={handleClose}
            profile={props.profile}
            type={props.type}
            refresh={props.refresh}
            setRefresh={props.setRefresh}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
