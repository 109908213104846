import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#B12B39",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
    // fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  viewAppButton: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.4rem",
    },
  },
  actionButton: {
    justifyContent: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ConfirmDialog = ({
  text,
  openConfirmDialog,
  setOpenConfirmDialog,
  handleDelete,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={openConfirmDialog}
      onClose={() => setOpenConfirmDialog(false)}
      fullWidth
    >
      <DialogContent>
        <DialogContentText>
          <Typography align="center">{text}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actionButton}>
        <IconButton
          style={{ backgroundColor: "#92d459", color: "#fff" }}
          onClick={async () => {
           await handleDelete();
           setOpenConfirmDialog(false);
          }}
        >
          <CheckCircleOutlineIcon />
        </IconButton>
        <IconButton
          onClick={() => setOpenConfirmDialog(false)}
          style={{ backgroundColor: "#eb5809", color: "#fff" }}
        >
          <CancelOutlinedIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
