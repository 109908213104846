import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TenantService from "../../api/TenantService";
import { Button, Typography, Tooltip } from "@material-ui/core";
import HelperService from "../../api/HelperService";
import ImageDialog from "../../components/ImageDialog";
import CustomDataTable from "../../components/data-table/CustomDataTable"
import { GlobalContext } from "../../context/GlobalState"
import { useContext } from "react";
import { getDecryptedData } from "../../api/GlobalService";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  rowColor: {
    backgroundColor: "#E8EAFC",
    color: "black",
  },
});

const getAddress = (address) => {
  return (
    address.address1 +
    ", " +
    address.address2 +
    ", pincode: " +
    address.pinCode +
    ", District: " +
    address.district.districtName +
    ", State: " +
    address.district.statename.stateName
  );
};

export default function TenantDetails(props) {
  const classes = useStyles();
  const [documents, setDocuments] = useState([]);
  const [openImage, setOpenImage] = useState(false);
  const [image, setImage] = useState();
  const { processApplication } = useContext(GlobalContext);


  const columnsFMemebers = [
    {
      name: "member",
      label: "Member Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log("fmm: ",tableMeta.rowData[0].documents[0].docid)     
          return tableMeta.rowData[0].memberName
        },
      },
    },
    {
      name: "member",
      label: "Date of Birth",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[0].age
        },
      },
    },
    {
      name: "member",
      label: "Relationship",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[0].relationship
        },
      },
    },
    {
      name: "member",
      label: "Occupation",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[0].occupation
        },
      },
    },
    {
      name: "documents",
      label: "Actions",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log("tableMeta", tableMeta)
          return (
            <Tooltip
              title={`View photo of ${tableMeta.rowData[0].memberName}`}
              placement="top-start"
              arrow
            >
              <Button
                variant="outlined"
                // startIcon={<AddIcon />}
                style={{
                  backgroundColor: "#F3A712",
                  color: "#fff",
                  borderRadius: "4px",
                }}
                onClick={async () => {

                  const fileBase64 =
                    // getDecryptedData("role") === "MODERATOR" ||
                    //   getDecryptedData("role") === "SUPERMODERATOR" ||
                    //   getDecryptedData("role") === "VERIFIER"
                    sessionStorage.getItem("role") === "MODERATOR" ||
                      sessionStorage.getItem("role") === "SUPERMODERATOR" ||
                      sessionStorage.getItem("role") === "VERIFIER"
                      ? tableMeta.rowData[0].documents.length >= 1 ? await TenantService.downloadFamilyMemberDocument(tableMeta.rowData[0].documents[0].docid).catch(err => {
                        alert("No Photo Uploaded For This Member!")
                        return;
                      }) : alert("No Photo Uploaded For This Member!")
                      : await TenantService.getFamilyMemberDocumentAnonymous(
                        tableMeta.rowData[4]
                      );
                  if (!fileBase64) return;
                  const contenttype = fileBase64.data.contenttype;
                  var type = null;
                  if (
                    contenttype == "JPEG" ||
                    contenttype == "JPG" ||
                    contenttype == "jpeg" ||
                    contenttype == "jpg"
                  ) {
                    type = "image/jpg";
                  } else {
                    type = "application/pdf";
                  }
                  handleDownload(fileBase64.data.base64img, type);
                }}
              >
                View
              </Button>

            </Tooltip>

          );
        },
      },
    },
  ];

  const columnsHelpers = [
    {
      name: "helper",
      label: "Helper Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[0].memberName
        },
      },
    },
    {
      name: "member",
      label: "Date of Birth",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[0].age
        },
      },
    },
    {
      name: "member",
      label: "Permanent Address",
      options: {
        customBodyRender: (value, tableMeta) => {
          return <>
            <span>
              {tableMeta.rowData[0].permanentAddress.address1},&nbsp;
              {tableMeta.rowData[0].permanentAddress.address2}
            </span>
            <br />
            <span>
              {tableMeta.rowData[0].permanentAddress.district.districtName},&nbsp;{tableMeta.rowData[0].permanentAddress.pinCode}
            </span>
            <br />
            <span>
              {tableMeta.rowData[0].permanentAddress.district.statename.stateName}
            </span>
          </>
        }
      }
    },
    {
      name: "documents",
      label: "Actions",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log("tableMeta", tableMeta)
          return (
            <Tooltip
              title={`View photo of ${tableMeta.rowData[0].memberName}`}
              placement="top-start"
              arrow
            >
              <Button
                variant="outlined"
                // startIcon={<AddIcon />}
                style={{
                  backgroundColor: "#F3A712",
                  color: "#fff",
                  borderRadius: "4px",
                }}
                onClick={async () => {

                  const fileBase64 =
                    // getDecryptedData("role") === "MODERATOR" ||
                    //   getDecryptedData("role") === "SUPERMODERATOR" ||
                    //   getDecryptedData("role") === "VERIFIER"
                    sessionStorage.getItem("role") === "MODERATOR" ||
                      sessionStorage.getItem("role") === "SUPERMODERATOR" ||
                      sessionStorage.getItem("role") === "VERIFIER"
                      ? tableMeta.rowData[0].documents.length >= 1 ? await TenantService.downloadHelperDocument(tableMeta.rowData[0].documents[0].docid).catch(err => {
                        alert("No Photo Uploaded For This Member!")
                        return;
                      }) : alert("No Photo Uploaded For This Member!")
                      : await TenantService.downloadHelperDocumentAnonymous(
                        tableMeta.rowData[4]
                      );
                  if (!fileBase64) return;
                  const contenttype = fileBase64.data.contenttype;
                  var type = null;
                  if (
                    contenttype == "JPEG" ||
                    contenttype == "JPG" ||
                    contenttype == "jpeg" ||
                    contenttype == "jpg"
                  ) {
                    type = "image/jpg";
                  } else {
                    type = "application/pdf";
                  }
                  handleDownload(fileBase64.data.base64img, type);
                }}
              >
                View
              </Button>

            </Tooltip>

          );
        },
      },
    },
  ];

  const { tenant } = props;
  const [domesticHelpers, setDomesticHelpers] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);

  const getDocuments = async () => {
    const response = await TenantService.getTenantDocumentsByTenantId(
      tenant.id
    );
    setDocuments(response.data);
  };

  const getDomesticHelpers = async () => {
    let response;
    // if (props.type === "TENANT") {
    //   response = await HelperService.getHelpersByTenantId(
    //     sessionStorage.getItem("tenantId")
    //   );
    // } else {
    //   response = await LandlordService.getDomesticHelperByLandlordEpicNumber(
    //     currentApplication.id
    //   );
    // }

    response = await HelperService.getHelpersByTenantId(tenant.id);

    // setDomesticHelpers(currentApplication.helper)
    setDomesticHelpers(response.data);
  };

  const getFamilyMembers = async () => {
    const response = await TenantService.getTenantFamilyMembersByEpicNumber(
      tenant.id
    );

    setFamilyMembers(response.data);
  };

  useEffect(() => {
    getDocuments();
    getDomesticHelpers();
    getFamilyMembers();
  }, []);

  const handleDownload = async (base64img, type) => {

    //   const base64Pdf = await TenantService.getDocumentsByDocumentId(props.row.docid);
    var blob = base64toBlob(base64img, type);
    let blobUrl = URL.createObjectURL(blob);
    setImage(blobUrl);
    setOpenImage(true);
    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveOrOpenBlob(blob);
    // } else {
    //   const blobUrl = URL.createObjectURL(blob);
    //   window.open(blobUrl);
    // }
  };

  function base64toBlob(base64Data, type) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: type });
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            <TableRow key={1}>
              <TableCell component="th" scope="row" style={{ color: "blue" }}>
                Full Name:
              </TableCell>
              <TableCell align="left" style={{ color: "blue" }}>
                {tenant.firstName}{" "}
                {tenant.middleName != null || tenant.middleName != ""
                  ? tenant.middleName
                  : ""}{" "}
                {tenant.lastName}{" "}
              </TableCell>
            </TableRow>

            <TableRow key={2}>
              <TableCell component="th" scope="row" style={{ color: "blue" }}>
                tenant ID:
              </TableCell>
              <TableCell align="left" style={{ color: "blue" }}>
                {tenant.id}
              </TableCell>
            </TableRow>
            <TableRow key={3}>
              <TableCell component="th" scope="row" style={{ color: "blue" }}>
                Occupation:
              </TableCell>
              <TableCell align="left" style={{ color: "blue" }}>
                {" "}
                {tenant.occupation}{" "}
              </TableCell>
            </TableRow>
            <TableRow key={4}>
              <TableCell component="th" scope="row" style={{ color: "blue" }}>
                Mobile Number:
              </TableCell>
              <TableCell align="left" style={{ color: "blue" }}>
                {" "}
                {tenant.mobile}{" "}
              </TableCell>
            </TableRow>

            <TableRow key={5}>
              <TableCell component="th" scope="row" style={{ color: "blue" }}>
                Permanent Address:
              </TableCell>
              <TableCell align="left" style={{ color: "blue" }}>
                {getAddress(tenant.permanentAddress)}{" "}
              </TableCell>
            </TableRow>

            <TableRow key={6}>
              <TableCell component="th" scope="row" style={{ color: "blue" }}>
                Present Address:
              </TableCell>
              <TableCell align="left" style={{ color: "blue" }}>
                {getAddress(tenant.presentAddress)}{" "}
              </TableCell>
            </TableRow>

            <TableRow key={6}>
              <TableCell component="th" scope="row" style={{ color: "blue" }}>
                Documents:
              </TableCell>
              {documents.length > 0 ? (
                <TableCell align="left">
                  {documents.map((document) => {
                    let docType = document.doctype;
                    return (
                      <Button
                        key={document.docid}
                        size={"small"}
                        variant="contained"
                        color="primary"
                        style={{
                          marginRight: "2%",
                          backgroundColor: "#29335C",
                        }}
                        onClick={async () => {

                          const fileBase64 = await TenantService.getDocumentsByDocumentId(
                            document.docid
                          );

                          const contenttype = fileBase64.data.contenttype;
                          var type = null;
                          if (
                            contenttype == "JPEG" ||
                            contenttype == "JPG" ||
                            contenttype == "jpeg" ||
                            contenttype == "jpg"
                          ) {
                            type = "image/jpg";
                          } else {
                            type = "application/pdf";
                          }
                          handleDownload(fileBase64.data.base64img, type);
                        }}
                      >
                        VIEW {docType}
                      </Button>
                    );
                  })}
                </TableCell>
              ) : (
                <TableCell align="left">
                  No documents found for this tenant.
                </TableCell>
              )}
            </TableRow>
            <TableRow key={6}>
              <TableCell component="th" scope="row" style={{ color: "blue" }}>
                Helpers:
              </TableCell>
              <TableCell align="left">

                {
                  processApplication.appl.helperInApplication.length === 0
                    // domesticHelpers.length === 0
                    ? (
                      <>No helpers found for this tenant.</>
                    ) : (
                      <CustomDataTable
                        // rows={domesticHelpers}
                        rows={processApplication.appl.helperInApplication}
                        columns={columnsHelpers}
                        title="Domestic Helpers selected for the Rental Unit"
                      />
                    )}
              </TableCell>
            </TableRow>
            <TableRow key={6}>
              <TableCell component="th" scope="row" style={{ color: "blue" }}>
                Family Members:
              </TableCell>
              <TableCell align="left">
                {
                  processApplication.appl.familyInApplication.length === 0
                    // familyMembers.length === 0
                    ? (
                      <Typography>
                        No family members found for this tenant.
                      </Typography>
                    ) : (
                      <CustomDataTable
                        // rows={familyMembers}
                        rows={processApplication.appl.familyInApplication}
                        columns={columnsFMemebers}
                        title="Family Members selected for the Rental Unit"
                      />
                    )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ImageDialog
        openImage={openImage}
        setOpenImage={setOpenImage}
        image={image}
      />
    </>
  );
}
