import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const CustomCircularProgress = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 30,
        left: "40%",
      }}
    >
      <CircularProgress
        size={100}
        thickness={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          color: "#29335C",
          margin: "auto",
        }}
      />
      <Box minHeight={20} />
      <Typography align="center" variant="h4" sx={{ color: "#29335C" }}>
        Please wait!
      </Typography>
    </Box>
  );
};

export default CustomCircularProgress;
