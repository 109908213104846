import DocumentService from "./DocumentService";
import CryptoJS from 'crypto-js';

export const getMonthNumber = (month) => {
  switch (month) {
    case "Jan": {
      return "01";
    }
    case "Feb": {
      return "02";
    }
    case "Mar": {
      return "03";
    }
    case "Apr": {
      return "04";
    }
    case "May": {
      return "05";
    }
    case "Jun": {
      return "06";
    }
    case "Jul": {
      return "07";
    }
    case "Aug": {
      return "08";
    }
    case "Sep": {
      return "09";
    }
    case "Oct": {
      return "10";
    }
    case "Nov": {
      return "11";
    }
    case "Dec": {
      return "12";
    }
    default: {
      return;
    }
  }
};
export const validateTenantId = (id) => {
  if (id !== "") {
    if (id.match(/^[0-9A-Za-z]+$/) === null) {
      return false;
      //is not alphanumeric
    } else {
      return true;
      //it is alphanumeric
    }
  } else {
    return;
  }
};

export const stringWithinBrackets = (string) => {
  var newString = string.split("(");
  let arr = [];
  for (var i = 1; i < newString.length; i++) {
    arr.push(newString[i].split(")")[0]);
  }
  return arr;
};

export const stringBeforeBrackets = (string) => {
  let split = string.split("(Adress");
  let substring = split[0];
  return substring;
};

export const uploadFile = async (
  setSnackBar,
  file,
  setUploading,
  addTenantDocument,
  setId,
  snackBar,
  label,
  setBackDropOpen
) => {
  setBackDropOpen(true);
  if (file === null || file === undefined) {
    setSnackBar({
      message: "Please choose a file to upload",
      open: true,
      severity: "error",
    });

    setBackDropOpen(false);
    return;
  }

  console.log("File format:: ", file);

  function getBase64(file, callback) {
    const reader = new FileReader();

    reader.addEventListener("load", () => callback(reader.result));

    reader.readAsDataURL(file);
  }

  function getExt(filename) {
    var ext = filename.split(".").pop();
    if (ext == filename) return "";
    return ext;
  }

  getBase64(file, async function (base64Data) {
    const fileExtension = getExt(file.name);

    // if (
    //   fileExtension !== "jpeg" ||
    //   fileExtension !== "jpg" ||
    //   fileExtension !== "pdf" ||
    //   fileExtension !== "JPEG" ||
    //   fileExtension !== "JPEG" ||
    //   fileExtension !== "JPEG"
    // ) {
    //   setSnackBar({
    //     ...snackBar,
    //     message: "File-type not accepted!",
    //     open: true,
    //     severity: "error",
    //   });
    //   return;
    // }

    let base64String = /,(.+)/.exec(base64Data)[1];
    console.log(base64String);

    const payload = {
      ownerid: sessionStorage.getItem("tenantId"),
      // ownerid: getDecryptedData("tenantId"),
      ownertype: "tenant",
      doctype: label, // must be title from Document.js
      base64img: base64String,
      contenttype: fileExtension,
    };

    try {
      setUploading(true);
      const response =
        sessionStorage.getItem("role") === "MODERATOR" ||
          sessionStorage.getItem("role") === "SUPERMODERATOR" ||
          sessionStorage.getItem("role") === "VERIFIER"
          ? await DocumentService.create(payload)
          : await DocumentService.createAnonymous(payload);
      // const response =
      //   getDecryptedData("role") === "MODERATOR" ||
      //     getDecryptedData("role") === "SUPERMODERATOR" ||
      //     getDecryptedData("role") === "VERIFIER"
      //     ? await DocumentService.create(payload)
      //     : await DocumentService.createAnonymous(payload);
      setId(null);
      // setSelectedDocumentType(null);
      addTenantDocument(response.data);
      setBackDropOpen(false);

      setSnackBar({
        message: `${label} Uploaded Successfully!`,
        open: true,
        severity: "success",
      });
      setUploading(false);
      //  openSnackBar({message:'Successfully Uploaded Document', severity:'success',open:true})
    } catch (err) {
      setBackDropOpen(false);

      setUploading(false);
      setId(null);
      // setSelectedDocumentType(null);
      setSnackBar({
        ...snackBar,
        message: err.response
          ? err.response.data.message
          : "Uploading error. Try again later!",
        open: true,
        severity: "error",
      });
      ///openSnackBar({message:'Error Uploading Document', severity:'error',open:true})
    }
    setUploading(false);
  });
};

export const uploadCapturedPhoto = async (
  setSnackBar,
  file,
  setUploading,
  addTenantDocument,
  setId,
  snackBar,
  label,
  setBackDropOpen,
  setWebpString,
  setJpegDataUrl,
  jpegDataUrl,
  setOpenCamera,
  memberCode,
  setOpen
) => {
  console.log("file0: ", file)
  // // function to get the file extension
  // const getFileExtension = async (file) => {
  //   const indexOfFirstSlash = await (file.indexOf("/") + 1);
  //   const indexOfFirstSemiColon = await file.indexOf(";");

  //   const extension = await file.slice(
  //     indexOfFirstSlash,
  //     indexOfFirstSemiColon
  //   );
  //   return extension;
  // };

  // function to get base64 data from image src
  const getBase64FromRawData = async (file) => {
    const commaIndex = await (file.indexOf(",") + 1);
    const base64Data = await file.slice(commaIndex);
    return base64Data;
  };

  const convertWebPToJPEG = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = `${file}`;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0);

        canvas.toBlob((blob) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result); // Resolve the promise with the result
          };
          reader.readAsDataURL(blob);
        }, 'image/jpeg');
      };

      img.onerror = (error) => {
        reject(error); // Reject the promise in case of an error
      };
    });
  };


  // await convertWebPToJPEG()
  console.log("memberCode: ", memberCode)

  const payload = {

    ownerid: memberCode !== undefined ? memberCode : sessionStorage.getItem("tenantId"),
    // ownerid: memberCode !== undefined ? memberCode : getDecryptedData("tenantId"),

    ownertype: memberCode !== undefined ? "tenantfamily" : "tenant",
    doctype: label, // must be title from Document.js
    base64img: await convertWebPToJPEG(file)
      .then((jpegDataUrl) => {
        // Handle the converted JPEG data URL
        console.log("newDataUrl: ", jpegDataUrl);
        return getBase64FromRawData(jpegDataUrl);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      }),
    contenttype: "jpeg",
  };

  try {
    setUploading(true);
    const response =
      sessionStorage.getItem("role") === "MODERATOR" ||
        sessionStorage.getItem("role") === "SUPERMODERATOR" ||
        sessionStorage.getItem("role") === "VERIFIER"
        ? await DocumentService.create(payload)
        : await DocumentService.createAnonymous(payload);
    // const response =
    //   getDecryptedData("role") === "MODERATOR" ||
    //     getDecryptedData("role") === "SUPERMODERATOR" ||
    //     getDecryptedData("role") === "VERIFIER"
    //     ? await DocumentService.create(payload)
    //     : await DocumentService.createAnonymous(payload);
    setId(null);
    // setSelectedDocumentType(null);
    memberCode === undefined && addTenantDocument(response.data);
    setBackDropOpen(false);

    setSnackBar({
      message: `${label} Uploaded Successfully!`,
      open: true,
      severity: "success",
    });
    setUploading(false);
    setOpenCamera(false);
    memberCode !== undefined && setOpen(false);
    //  openSnackBar({message:'Successfully Uploaded Document', severity:'success',open:true})
  } catch (err) {
    setBackDropOpen(false);

    setUploading(false);

    setId(null);
    setOpenCamera(false);

    // setSelectedDocumentType(null);
    setSnackBar({
      ...snackBar,
      message: err.response
        ? err.response.data.message
        : "Uploading error. Try again later!",
      open: true,
      severity: "error",
    });
    ///openSnackBar({message:'Error Uploading Document', severity:'error',open:true})
  }
  setUploading(false);
};

export const replaceUnderScoreWithSpace = (string) => {
  return string.replaceAll("_", " ");
};

export const formatDateToIndianFormat = (dateString) => {
  let p = dateString.split(/\D/g);
  return [p[2], p[1], p[0]].join("-");
};


export const storeEncryptedData = (key, data) => {
  // Your secret key for encryption
  const secretKey = 'yourSecretKey';

  // Encrypt the token
  const encryptedToken = CryptoJS.AES.encrypt(data, secretKey).toString();

  // Store the encrypted token in sessionStorage
  sessionStorage.setItem(key, encryptedToken);
};
// Example usage:
// const jwtToken = 'yourJWTToken';
// storeEncryptedToken(jwtToken);


export const getDecryptedData = async (key) => {
  console.log(key, sessionStorage.getItem(key))
  // // Your secret key for encryption
  // const secretKey = 'yourSecretKey';

  // // Retrieve the encrypted token from sessionStorage
  // const encryptedToken = await sessionStorage.getItem(key);

  // // Decrypt the token
  // const bytes = await CryptoJS.AES.decrypt(encryptedToken, secretKey);
  // const decryptedToken = await bytes.toString(CryptoJS.enc.Utf8);

  // return decryptedToken;
};

// Example usage:
// const decryptedToken = getDecryptedToken();
// console.log('Decrypted Token:', decryptedToken);


