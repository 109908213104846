import React, { useEffect, useState } from "react";
import Widget from "../../components/Widget/Widget";
// styles
import { useStyles } from "./useStyles";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import RentalUnitService from "../../api/RentalUnitService";
import MasterData from "../../api/MasterData";
import SubLocalityBasicDetails from "./SubLocalityBasicDetails";
import LocalityBasicDetails from "./LocalityBasicDetails";
import { getDecryptedData } from "../../api/GlobalService";

// const AddressType = { PERMANENT: 0, PRESENT: 1 };

export default function UserDetails() {
  const classes = useStyles();

  const [profile, setProfile] = useState(null);
  const [disabled, setDisabled] = useState(true);

  // const getUserDetails = async () => {
  //   if (getDecryptedData("role") === "MODERATOR") {
  //     RentalUnitService.getLocalAutoritiesByLACode(
  //       getDecryptedData("usercode")
  //     )
  //       .then((response) => {
  //         setProfile(response.data);
  //       })
  //       .catch((err) => console.log(err));
  //   } else if (getDecryptedData("role") === "SUPERMODERATOR") {
  //     RentalUnitService.getLocalityByCode(getDecryptedData("usercode"))
  //       .then((response) => {
  //         setProfile(response.data);
  //       })
  //       .catch((err) => console.log(err));
  //   } else if (getDecryptedData("role") === "VERIFIER") {
  //     MasterData.getPoliceStationByCode(getDecryptedData("usercode"))
  //       .then((response) => {
  //         setProfile(response.data);
  //       })
  //       .catch((err) => console.log(err));
  //   } else {
  //     setProfile(null);
  //   }
  // };
  const getUserDetails = async () => {
    if (sessionStorage.getItem("role") === "MODERATOR") {
      RentalUnitService.getLocalAutoritiesByLACode(
        sessionStorage.getItem("usercode")
      )
        .then((response) => {
          setProfile(response.data);
        })
        .catch((err) => console.log(err));
    } else if (sessionStorage.getItem("role") === "SUPERMODERATOR") {
      RentalUnitService.getLocalityByCode(sessionStorage.getItem("usercode"))
        .then((response) => {
          setProfile(response.data);
        })
        .catch((err) => console.log(err));
    } else if (sessionStorage.getItem("role") === "VERIFIER") {
      MasterData.getPoliceStationByCode(sessionStorage.getItem("usercode"))
        .then((response) => {
          setProfile(response.data);
        })
        .catch((err) => console.log(err));
    } else {
      setProfile(null);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  // const handleLocalityChange = (event, addressType) => {
  //   console.log(addressType);

  //   const s = { ...profile };
  //   switch (addressType) {
  //     case AddressType.PERMANENT:
  //       s.address.permanent.locality = event.target.value;
  //       break;
  //     case AddressType.PRESENT:
  //       s.address.present.locality = event.target.value;
  //   }
  //   setProfile(s);
  // };

  return (
    <>
      <Widget disableWidgetMenu>
        {/* <Button
          style={{ float: "right" }}
          variant="contained"
          color="secondary"
          startIcon={<EditIcon />}
          onClick={() => setDisabled(false)}
        >
          Edit
        </Button> */}

        <div className={classes.dashedBorder} style={{ marginTop: 40 }}>
          <form className={classes.root} noValidate autoComplete="off">
            <Typography variant="h6" component="h6">
              Basic Details
            </Typography>
            {/* {getDecryptedData("role") === "MODERATOR" ? ( */}
            {sessionStorage.getItem("role") === "MODERATOR" ? (
              <SubLocalityBasicDetails
                profile={profile}
                setProfile={setProfile}
                disabled={disabled}
              />
            ) : (
              <LocalityBasicDetails
                profile={profile}
                setProfile={setProfile}
                disabled={disabled}
              />
            )}
          </form>
        </div>

        {/* <Button
          style={{ float: "right", marginTop: 5 }}
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          disabled={disabled}
        >
          Save
        </Button> */}
      </Widget>
    </>
  );
}
