import React, { useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Logout from "@mui/icons-material/Logout";
import SearchIcon from "@mui/icons-material/Search";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
  togglePrompt,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";

import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
  AccountCircle,
} from "@material-ui/icons";
import { AppBar, Toolbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  alpha,
  Button,
  ButtonBase,
  InputBase,
  styled,
  Tooltip,
} from "@mui/material";
import Prompt from "../Prompt";
import { getDecryptedData } from "../../api/GlobalService";

const Search = styled("div")(({ theme }) => ({
  // position: "relative",
  textTransform: "none !important",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  paddingRight: 10,
  // marginLeft: 10,
  // width: "100%",
  // [theme.breakpoints.up("sm")]: {
  //   marginLeft: theme.spacing(1),
  //   width: "auto",
  // },
}));

export default function Header(props) {
  var classes = useStyles();
  // const [prompt, setPrompt] = useState(false);

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar
      // className={classes.toolbar}
      >
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          )}
        </IconButton>
        <Box sx={{ display: { xs: "none", sm: "none", md: "none", large: "flex" } }}>

          <a style={{ textDecoration: "none", fontWeight: 600, color: "#fff" }} href="https://megrssa.nic.in" rel="noreferrer" target="_blank">
            Meghalaya Resident Safety and Security Act Online Application
          </a>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "flex", large: "none" } }}>
          <a style={{ textDecoration: "none", fontWeight: 600, color: "#fff" }} href="https://megrssa.nic.in" rel="noreferrer" target="_blank">MRSSA</a>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        {
          (sessionStorage.getItem("role") === "SUPERVISOR" ||
            sessionStorage.getItem("role") === "MODERATOR" ||
            sessionStorage.getItem("role") === "SUPERMODERATOR")
          // (getDecryptedData("role") === "SUPERVISOR" ||
          //   getDecryptedData("role") === "MODERATOR" ||
          //   getDecryptedData("role") === "SUPERMODERATOR")
          && (
            // <Tooltip title="Search Tenants, Landlords, etc.">
            //   <Button
            //     startIcon={<SearchIcon />}
            //     sx={{ color: "#fff", bgcolor: "red" }}
            //     onClick={() => props.setOpenSearchDialog(true)}
            //   >
            //     Search Tenant/Landlord
            //   </Button>
            //   {/* <IconButton aria-label="search" size="large">
            //     <SearchIcon sx={{ color: "#fff" }} />
            //   </IconButton> */}
            // </Tooltip>

            <Tooltip title="Search Tenants and Landlords">
              <Search>
                <Button
                  size={"small"}
                  onClick={() => props.setOpenSearchDialog(true)}
                  sx={{ color: "#F3F5FF", gap: 1, textTransform: "none" }}
                >
                  <SearchIcon sx={{ color: "#fff" }} />
                  Search
                </Button>
              </Search>
            </Tooltip>
          )}

        {/* <div className={classes.grow} />
        <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen,
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen,
            })}
            onClick={() => setSearchOpen(!isSearchOpen)}
          >
            <SearchIcon classes={{ root: classes.headerIcon }} />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        </div> */}
        <Typography
          variant="subtitle1"
          style={{ fontSize: "15px", color: "#fff", color: "#f5e6cb", fontFamily: "raleway", marginLeft: "2rem" }}
          className={classes.logotype}
        >
          Logged in as &nbsp;
          <span style={{ fontWeight: "bold" }}>
            <u style={{ fontFamily: "raleway" }}>{
              sessionStorage.getItem("username")
              // getDecryptedData("username")
            }</u>
          </span>
        </Typography>
        <div>
          <Tooltip title="Logout">
            <Button
              size={"small"}
              onClick={() => {
                togglePrompt(layoutDispatch);
              }}
              sx={{
                ml: 2,
                color: "#fff",
                backgroundColor: "#F3A712",
                "&:hover": {
                  backgroundColor: "#F3A712",
                },
              }}
              variant="contained"
              startIcon={<Logout size="large" sx={{ color: "#fff" }} />}
            >
              LOGOUT
            </Button>
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Link
              to="/app/profile"
              style={{ textDecoration: "none", color: "rgb(25, 118, 210)" }}
            >
              <MenuItem>
                <ListItemIcon>
                  <Avatar />
                </ListItemIcon>
                Profile
              </MenuItem>
            </Link>
            <MenuItem onClick={() => signOut(userDispatch, props.history)}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
      <Prompt
        prompt={layoutState.prompt}
        text="Are you sure you want to logout?"
      />
    </AppBar>
  );

  //  return (
  //    <AppBar position="fixed" className={classes.appBar}>
  //      <Toolbar
  //      // className={classes.toolbar}
  //      >
  //        <IconButton
  //          color="inherit"
  //          onClick={() => toggleSidebar(layoutDispatch)}
  //          className={classNames(
  //            classes.headerMenuButtonSandwich,
  //            classes.headerMenuButtonCollapse
  //          )}
  //        >
  //          {layoutState.isSidebarOpened ? (
  //            <ArrowBackIcon
  //              classes={{
  //                root: classNames(
  //                  classes.headerIcon,
  //                  classes.headerIconCollapse
  //                ),
  //              }}
  //            />
  //          ) : (
  //            <MenuIcon
  //              classes={{
  //                root: classNames(
  //                  classes.headerIcon,
  //                  classes.headerIconCollapse
  //                ),
  //              }}
  //            />
  //          )}
  //        </IconButton>
  //        <Box sx={{ display: { xs: "none", md: "flex" } }}>
  //          <Typography
  //            variant="h6"
  //            weight="medium"
  //            // className={classes.logotype}
  //          >
  //            Meghalaya Resident Safety and Security Act Online Application
  //          </Typography>
  //        </Box>
  //        <Box sx={{ display: { xs: "flex", md: "none" } }}>
  //          <Typography variant="h6" weight="medium">
  //            MRSSA
  //          </Typography>
  //        </Box>
  //        <Box sx={{ flexGrow: 1 }} />

  //        {/* <div className={classes.grow} />
  //       <div
  //         className={classNames(classes.search, {
  //           [classes.searchFocused]: isSearchOpen,
  //         })}
  //       >
  //         <div
  //           className={classNames(classes.searchIcon, {
  //             [classes.searchIconOpened]: isSearchOpen,
  //           })}
  //           onClick={() => setSearchOpen(!isSearchOpen)}
  //         >
  //           <SearchIcon classes={{ root: classes.headerIcon }} />
  //         </div>
  //         <InputBase
  //           placeholder="Search…"
  //           classes={{
  //             root: classes.inputRoot,
  //             input: classes.inputInput,
  //           }}
  //         />
  //       </div> */}
  //        <Typography
  //          variant="h6"
  //          weight="medium"
  //          // className={classes.logotype}
  //        >
  //          Welcome{" "}
  //          <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
  //            {sessionStorage.getItem("username")}
  //          </span>
  //        </Typography>
  //        <div>
  //          <IconButton
  //            size="large"
  //            aria-label="account of current user"
  //            aria-controls="menu-appbar"
  //            aria-haspopup="true"
  //            color="inherit"
  //            // className={classes.headerMenuButton}
  //            // aria-haspopup="true"
  //            // color="inherit"
  //            // aria-controls="profile-menu"
  //            onClick={(e) => setProfileMenu(e.currentTarget)}
  //          >
  //            <AccountIcon classes={{ root: classes.headerIcon }} />
  //          </IconButton>
  //          <Menu
  //            id="profile-menu"
  //            open={Boolean(profileMenu)}
  //            anchorEl={profileMenu}
  //            onClose={() => setProfileMenu(null)}
  //            className={classes.headerMenu}
  //            classes={{ paper: classes.profileMenu }}
  //            disableAutoFocusItem
  //          >
  //            <div className={classes.profileMenuUser}>
  //              <Typography variant="h4" weight="medium">
  //                {sessionStorage.getItem("username")}
  //              </Typography>
  //              <Typography
  //                className={classes.profileMenuLink}
  //                component="a"
  //                color="primary"
  //                href="#"
  //              >
  //                {sessionStorage.getItem("role")}
  //              </Typography>
  //            </div>
  //            <MenuItem
  //              className={classNames(
  //                classes.profileMenuItem,
  //                classes.headerMenuItem
  //              )}
  //            >
  //              <AccountIcon className={classes.profileMenuIcon} /> Profile
  //            </MenuItem>
  //            <div className={classes.profileMenuUser}>
  //              <Typography
  //                className={classes.profileMenuLink}
  //                color="primary"
  //                onClick={() => signOut(userDispatch, props.history)}
  //              >
  //                Sign Out
  //              </Typography>
  //            </div>
  //          </Menu>
  //        </div>
  //      </Toolbar>
  //    </AppBar>
  //  );
}
