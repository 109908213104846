import { getDecryptedData } from "./GlobalService";
import http from "./http-common";
import httpApiApp from "./http-common-apikey-application";

const getApplication = (id) => {
  return http.get(`process/GetApplicationDetail/${id}`);
};
const getApplicationListByKey = (id) => {
  return httpApiApp.get(`process/ApplicationList/${id}`);
};

const getApplicationAnonymouseUser = (id, lndId) => {
  return httpApiApp.get(`process/GetApplicationStatus/${lndId}/${id}`);
};

const getApplicationByStatus = (status, username) => {
  return http.get(`process/GetTotalApplications/user/${username}/${status}`);
};

const getApplicationForPagination = (
  status,
  username,
  pageNumber,
  paramcode
) => {
  // return http.get(
  //   `process/GetApplicationDetails/user/${username}/${status}/${pageNumber}`
  // );

  return http.get(
    `process/GetApplicationDetails/user/${username}/${paramcode}/${status}/${pageNumber}`
  );
};

const getApplicationByLandlordEpicNumber = async (id) => {
  http.defaults.headers.common["Authorization"] = sessionStorage.getItem(
    "accessToken"
  );
  // http.defaults.headers.common["Authorization"] = await getDecryptedData(
  //   "accessToken"
  // );
  return http.get(`process/user/ApplicationList/${id}`);
};

const getCompleteApplicationByLandlordEpicNumber = (username, id, pageNo) => {
  return http.get(
    `process/GetApplicationDetails/user/complete/${username}/${id}/${pageNo}`
  );
};
const getIncompleteApplicationByLandlordEpicNumber = (username, id, pageNo) => {
  return http.get(
    `process/GetApplicationDetails/user/incomplete/${username}/${id}/${pageNo}`
  );
};

const updateApplication = (data) => {
  return http.post("process/updateApplication", data);
};

const deleteApplication = (id) => {
  return http.put(`process/updateApplication/${id}`);
};

const deleteApplicationAnonymous = (id, lndId) => {
  return httpApiApp.put(`process/anonymous/updateApplication/${id}/${lndId}`);
};

const addFamilyAndHelperMembers = (data) => {
  return httpApiApp.post("/process/application/DomesticHelperAndFamily", data);
};

const addFamilyAndHelperMembersAsUser = (data) => {
  return http.post("/process/application/user/DomesticHelperAndFamily", data);
};

const getApplicationPdf = (data) => {
  return httpApiApp.get(`/process/GetCompletedApplicationForm/${data}`);
};

const getApplicationPdfAsUser = (data) => {
  return http.get(`/process/user/GetCompletedApplicationForm/${data}`);
};

const getReportApplications = async (
  username,
  date1,
  date2,
  pageno,
  paramcode,
  payload
) => {
  let usercode = sessionStorage.getItem("usercode");
  // let usercode = await getDecryptedData("usercode");

  return sessionStorage.getItem("role") === "VERIFIER"
    ? http.post(
      `/process/ReportGetApplication/${username}/${usercode}/${date1}/${date2}/${pageno}`,
      { statuscode: payload.statuscode }
    )
    : http.post(
      `/process/ReportGetApplication/${username}/${paramcode}/${date1}/${date2}/${pageno}`,
      payload
    );
};

const getApplicationFlow = (id) => {
  return http.get(`process/applicationFlow/${id}`);
};

const getApplicationFlowAsAnonymousUser = async (id) => {
  return await httpApiApp.get(`process/applicationFlow/anonymous/${id}`);
};

const getAllApplicationListByLandlord = (id) => {
  return httpApiApp.get(`process/AllApplicationByLandlord/${id}`);
};

const searchPeople = (payload) => {
  return http.post("process/search", payload);
};

// const getApplicationPdf = async documentId => {
//   alert(documentId)
//   axios({
//     method: "GET",
//     url: `http://10.179.2.219:8080/mrss/v-1/GetCompletedApplicationForm/${documentId}`,
//     headers: {
//       // "Content-type": "application/json",
//       "Authorization": sessionStorage.getItem('accessToken'),
//     }
//   })
//   .then(res => {
//     //const persons = res.data;
//     //this.setState({ persons });
//     console.log("res : ",res);
//   })
//   .catch((error) => {
//     console.log("error : ",error);
//   });
//   //return http.get("GetCompletedApplicationForm/"+documentId);

// };

export default {
  getApplication,
  updateApplication,
  getApplicationByLandlordEpicNumber,
  getApplicationByStatus,
  addFamilyAndHelperMembers,
  getCompleteApplicationByLandlordEpicNumber,
  getIncompleteApplicationByLandlordEpicNumber,
  getApplicationPdf,
  getApplicationForPagination,
  addFamilyAndHelperMembersAsUser,
  getApplicationPdfAsUser,
  getApplicationAnonymouseUser,
  getReportApplications,
  getApplicationListByKey,
  getApplicationFlow,
  getApplicationFlowAsAnonymousUser,
  getAllApplicationListByLandlord,
  searchPeople,
  deleteApplication,
  deleteApplicationAnonymous,
};
