import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import UserService from "../../api/UserService";
import PageHeader from "../../components/Header/PageHeader";
// import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import {
  Grid,
  Table,
  TableColumnResizing,
  TableFixedColumns,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";
import { IntegratedSorting, SortingState } from "@devexpress/dx-react-grid";
import { Paper, Zoom } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import ScrollToTop from "react-scroll-to-top";

const LogRecords = () => {
  const [rows, setRows] = useState([]);

  // const columns = [
  //   {
  //     field: "id",
  //     headerName: "ID",
  //     width: 70,
  //     filterable: false,
  //     sortable: false,
  //   },
  //   // {
  //   //   field: "code",
  //   //   headerName: "Code",
  //   //   width: 70,
  //   //   filterable: false,
  //   //   sortable: false,
  //   // },

  //   {
  //     field: "ipAddress",
  //     headerName: "IP Address",
  //     width: 140,
  //     sortable: false,
  //     // valueGetter: (params) => {
  //     //   return params.getValue(params).ipAddress;
  //     // },
  //   },
  //   {
  //     field: "remark",
  //     headerName: "Remark",
  //     width: 100,
  //     sortable: false,
  //   },
  //   {
  //     field: "url",
  //     headerName: "URL",
  //     width: 200,
  //     sortable: false,
  //   },
  //   {
  //     field: "logdt",
  //     headerName: "Date",
  //     width: 300,
  //     sortable: false,
  //   },
  // ];

  const columns = [
    { name: "id", title: "ID" },
    { name: "ipAddress", title: "IP Address" },
    { name: "remark", title: "Remark" },
    { name: "url", title: "URL" },
    { name: "logdt", title: "Login Date & Time" },
  ];

  const [sortingStateColumnExtensions] = useState([
    { columnName: "remark", sortingEnabled: false },
    { columnName: "ipAddress", sortingEnabled: false },
    { columnName: "url", sortingEnabled: false },
  ]);
  const [defaultColumnWidths] = useState([
    { columnName: "id", width: 80 },
    { columnName: "ipAddress", width: 240 },
    { columnName: "remark", width: 240 },
    { columnName: "url", width: 340 },
    { columnName: "logdt", width: 340 },
  ]);
  const [leftColumns] = useState(["id"]);

  useEffect(() => {
    UserService.getLogRecords().then((response) => {
      console.log("Data logs:: ", response.data);
      let data = [];
      data = response.data;
      for (var i = 0; i < data.length; i++) {
        data[i].id = i + 1;
      }
      setRows(response.data);
    });
  }, []);

  // function ToolB() {       //Trying to customize GridToolbar
  //   return (
  //     <GridToolbarContainer>
  //       <GridColumnsToolbarButton />
  //     <GridFilterToolbarButton />
  //     <GridDensitySelector />
  //       <GridToolbarExport />
  //     </GridToolbarContainer>
  //   );
  // }

  return (
    <>
      <PageHeader
        title="Log Records"
        subTitle="Actions of users are displayed here."
        icon={<ReceiptLongIcon fontSize="large" />}
      />
      <ScrollToTop smooth color="#29335C" />
      {rows === null ? (
        <h2>No Records Found</h2>
      ) : (
        // <DataGrid
        //   autoHeight
        //   rows={rows}
        //   columns={columns}
        //   //   {...data}
        //   components={{
        //     Toolbar: GridToolbar,
        //   }}
        //   />

        <Paper>
          <Grid rows={rows} columns={columns}>
            <SortingState
              defaultSorting={[{ columnName: "id", direction: "asc" }]}
              columnExtensions={sortingStateColumnExtensions}
            />
            <IntegratedSorting />
            <Table />
            <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
            <TableHeaderRow showSortingControls />
            <TableFixedColumns
              leftColumns={leftColumns}
              // rightColumns={rightColumns}
            />
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default LogRecords;
