import React, { useState } from "react";
import { Card, Grid } from "@material-ui/core";

// styles
import useStyles from "../styles";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";

import NewTenantApplication from "./NewTenantApplication";
import TenantApplicationsDataTable from "./TenantApplicationsDataTable";

export default function TenantApplications(props) {
  var classes = useStyles();
  const [refreshDataTable, setRefreshDataTable] = useState(false);

  return (
    <Card style={{ width: "100%" }}>
      <PageTitle title="Tenant Applications" />
      <NewTenantApplication
        finish={props.finish}
        refreshDataTable={refreshDataTable}
        setRefreshDataTable={setRefreshDataTable}
      />
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <p style={{ color: "blue" }}>LIST OF COMPLETED APPLICATIONS </p>
          <TenantApplicationsDataTable refreshDataTable={refreshDataTable} />
        </Grid>
      </Grid>
    </Card>
  );
}
