import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const columns = [
  { name: "houseNo", title: "House Number" },
  { name: "floorNo", title: "Floor Number" },
  { name: "address1", title: "Address" },
];

export default function RentalUnitDetails(props) {
  const classes = useStyles();
  const [defaultColumnWidths] = useState([
    { columnName: "houseNo", width: 200 },
    { columnName: "floorNo", width: 200 },
    { columnName: "address1", width: 240 },
  ]);

  let rows = [];
  const { rentalunit } = props;
  rows.push(rentalunit);

  return (
    <Paper>
      <Grid rows={rows} columns={columns}>
        <Table />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow />
      </Grid>
    </Paper>
  );
}
