import React from "react";
import { Grid } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import ApplicationDataTable from "../offlineapplication/DataTable";
import * as Constants from "../../Constants";
import NewApplication from "../offlineapplication/NewApplication";
import PageHeader from "../../components/Header/PageHeader";
import BlockIcon from "@material-ui/icons/Block";

export default function Rejected() {
  // const role = sessionStorage.getItem("role");
  return (
    <>
      {/* <PageTitle title="Rejected Applications" /> */}
      <PageHeader
        title="Rejected Applications"
        subTitle="Applications that are rejected by the headman goes here."
        icon={<BlockIcon fontSize="large" />}
      />
      {/* {role === Constants.role.USER || role === Constants.role.MODERATOR ? (
        <NewApplication />
      ) : (
        <></>
      )} */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <ApplicationDataTable status="RJT" />
        </Grid>
      </Grid>
    </>
  );
}
