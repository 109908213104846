import { Button, Dialog } from "@material-ui/core";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

export const IdleTimeOutModal = ({
  showModal,
  handleClose,
  handleLogout,
  //   remainingTime,
}) => {
  return (
    <Dialog show={showModal} onHide={handleClose}>
      <DialogTitle>You Have Been Idle!</DialogTitle>

      <DialogContent>You Will Get Timed Out. You want to stay?</DialogContent>
      <DialogActions>
        <Button variant="danger" onClick={handleLogout}>
          Logout
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Stay
        </Button>
      </DialogActions>
    </Dialog>
  );
};
