import React, { useEffect } from "react";
import { makeStyles, responsiveFontSizes } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@mui/lab/Autocomplete";
// styles
import useStyles from "../offlineapplication/styles";
import Button from "@material-ui/core/Button";
import {
  Typography,
  FormControlLabel,
  TextField,
  Snackbar,
  Card,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useState } from "react";
import MasterData from "../../api/MasterData";
import { Alert, LoadingButton } from "@mui/lab";
import TenantService from "../../api/TenantService";
import { GlobalContext } from "../../context/GlobalState";
import { ApplicationContext } from "../../context/ApplicationState";

import { useContext } from "react";
// import { useUserDispatch, loginUser } from "../../context/UserContext";
import httpApi from "../../api/http-common-apikey";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import VerifyOTPDialog from "../../components/VerifyOTPDialog";
import InfoText from "../../components/InfoText";
import { getDecryptedData, storeEncryptedData } from "../../api/GlobalService";

const AddressType = { PERMANENT: 0, PRESENT: 1 };

const useStyle = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  disabledButton: {
    backgroundColor: theme.palette.primary || "red",
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "20px",
    zIndex: "20px",
    color: "#4A4A4A9F",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

// backdrop: {
//   zIndex: theme.zIndex.drawer + 1,
//     color: "#fff",
//   },

export default function TenantForm(props) {
  var classTheme = useStyles();
  const [openVerifyOTP, setOpenVerifyOTP] = useState(false);
  const { tenantApplication, currentApplication, openSnackBar } = useContext(GlobalContext);
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const { setApplicationData } = useContext(ApplicationContext);

  const { selectedRentalUnit } = props;
  const { setBackDrop } = props;
  const { setTenantId } = props;
  // const {toNextTab} = props;
  const [isSameAspermanentAddress, setSameAspermanentAddress] = React.useState(
    false
  );
  const [savedFlag, setSavedFlag] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedOccupation, setSelectedOccupation] = useState(
    props.profile.exist === true ? props.profile.occupation : null
  );
  const [occupation, setOccupation] = useState([]);
  const [isParentEpicCard, setIsParentEpicCard] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const { setDisableOtherTabs } = props;
  const [presentDistricts, setPresentDistricts] = useState([]);
  const [states, setStates] = useState([]);
  const [presentSelectedDistrict, setPresentSelectedDistrict] = useState();
  const [presentSelectedState, setPresentSelectedState] = useState(
    selectedRentalUnit === false
      ? props.profile.permanentAddress.district.statename
      : null
  );
  const [permanentSelectedDistrict, setPermanentSelectedDistrict] = useState();
  const [permanentSelectedState, setPermanentSelectedState] = useState(
    selectedRentalUnit === false
      ? props.profile.permanentAddress.district.statename
      : null
  );
  const [selectedNationality, setSelectedNationality] = useState("");
  const [epicNo, setEpicNo] = useState();
  const [tenancyTypes, setTenancyTypes] = useState([]);

  const [selectedTenancyType, setSelectedTenancyType] = useState(
    selectedRentalUnit === false ? props.profile.type : ""
  );

  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });
  const [profile, setProfile] = React.useState(
    savedFlag ? tenantApplication : props.profile
  );
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [otpValue, setOtpValue] = useState("")


  const fontColor = {
    style: { color: "#3598bd", fontWeight: 900 },
  };
  const errorInitialState = {
    error: false,
    epicNo: {
      exist: false,
      helperText: "ID required",
    },
    firstName: {
      exist: false,
      helperText: "firstName required",
    },
    // lastName: {
    //   exist: false,
    //   helperText: "lastName required",
    // },
    // email: {
    //   exist: false,
    //   helperText: "email required",
    // },

    parentOrspouseName: {
      exist: false,
      helperText: "Parent or Spouse name required",
    },
    occupation: {
      exist: false,
      helperText: "Occupation required",
    },
    mobile: {
      exist: false,
      helperText: "Mobile Number required",
    },
    permanentAddress: {
      address1: {
        exist: false,
        helperText: "Address Line 1 required!",
      },
      state: {
        exist: false,
        helperText: "State required",
      },
      district: {
        exist: false,
        helperText: "District required",
      },
      pincode: {
        exist: false,
        helperText: "Pincode required",
      },
    },
    presentAddress: {
      address1: {
        exist: false,
        helperText: "Address Line 1 required!",
      },
      state: {
        exist: false,
        helperText: "State required",
      },
      district: {
        exist: false,
        helperText: "District required",
      },
      pincode: {
        exist: false,
        helperText: "Pincode required",
      },
    },
    type: {
      exist: false,
      helperText: "Type required",
    },
  };
  const [errors, setErrors] = useState(errorInitialState);
  const getDistricts = async (stateId) => {
    const response = await MasterData.getDistrictsByStateId(stateId);
    setDistricts(response.data);
  };
  const getPresentDistricts = async (stateId) => {
    const response = await MasterData.getDistrictsByStateId(stateId);
    setPresentDistricts(response.data);
  };
  const getStates = async () => {
    const response = await MasterData.getStates();
    setStates(response.data);
  };
  const getDetails = () => {
    // This re-render.
    if (profile.exist === true) {
      setDisableOtherTabs(false);
      setPermanentSelectedState(profile.permanentAddress.district.statename);
      setPermanentSelectedDistrict(profile.permanentAddress.district);
      setPresentSelectedState(profile.presentAddress.district.statename);
      setPresentSelectedDistrict(profile.presentAddress.district);
      setSelectedOccupation(profile.occupation);

      var appData = {
        tenantid: profile.id,
        // rentcode: selectedRentalUnit.rentcode,
        rentcode:
          // getDecryptedData("role") === null
          sessionStorage.getItem("role") === null
            ? ""
            : selectedRentalUnit.rentcode,
        landlordid: currentApplication.id,
        applStatus: {
          applstatus: "LAVP",
        },
        landlordcode: currentApplication.id,
        applType: "APPLPS",
        // logby: profile.id,
        // logby: getDecryptedData("username"),
        logby: sessionStorage.getItem("username"),
      };
      // sessionStorage.setItem("tenantId", profile.id);
      setApplicationData(appData);
      // return TenantService.createApplication(appData);
    }
  };

  const getTypes = () => {
    httpApi
      .get("master/tenancytype")
      .then((response) => {
        setTenancyTypes(response.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDetails();
    getStates();
    getoccupations();
    getTypes();
    if (selectedRentalUnit === false) {
      getDistricts(permanentSelectedState.stateCode);
      getPresentDistricts(presentSelectedState.stateCode);
    }
  }, []);

  const classes = useStyle();

  const getoccupations = async () => {
    const response = await MasterData.getOccupation(); // get Occupation
    setOccupation(response.data);
  };

  const handlefirstNameChange = (event) => {
    profile.firstName = event.target.value;
    setProfile({ ...profile, firstName: event.target.value });
  };
  const handlemiddleNameChange = (event) => {
    setProfile({ ...profile, middleName: event.target.value });
  };
  const handlelastNameChange = (event) => {
    setProfile({ ...profile, lastName: event.target.value });
  };
  const handleEmailChange = (event) => {
    setProfile({ ...profile, email: event.target.value });
  };

  const handleOccupationChange = (event) => {
    setProfile({ ...profile, occupation: event.target.value });
  };
  const handleParentOrspouseNameChange = (event) => {
    setProfile({ ...profile, parentOrspouseName: event.target.value });
  };
  const handleNationalityChange = (event, value) => {
    if (value !== null) {
      // alert(`value is ${value}`);
      setSelectedNationality(value);
      setProfile({ ...profile, nationality: value });
    }
  };
  const handleAgeChange = (event) => {
    // let date = event.target.value.replace("-", "/");
    // date = date.replace("-", "/");
    setProfile({ ...profile, age: event.target.value });
  };
  const handleoccupationChange = (value) => {
    const s = { ...profile };
    s.occupation = value.value;
    setSelectedOccupation(value);
    setProfile(s);
  };
  const handleAddressLine1Change = (event, addressType) => {
    const s = { ...profile };
    switch (addressType) {
      case AddressType.PERMANENT:
        s.permanentAddress.address1 = event.target.value;
        break;
      case AddressType.PRESENT:
        s.presentAddress.address1 = event.target.value;
    }
    setProfile(s);
  };

  const handleAddressLine2Change = (event, addressType) => {
    const s = { ...profile };
    switch (addressType) {
      case AddressType.PERMANENT:
        s.permanentAddress.address2 = event.target.value;
        break;
      case AddressType.PRESENT:
        s.presentAddress.address2 = event.target.value;
    }
    setProfile(s);
  };
  const handleDistrictChange = (value, addressType) => {
    const s = { ...profile };
    switch (addressType) {
      case AddressType.PERMANENT:
        s.permanentAddress.district.districtCode = value.districtCode;
        s.permanentAddress.district.districtName = value.districtName;
        setPermanentSelectedDistrict(value);
        break;
      case AddressType.PRESENT:
        s.presentAddress.district.districtCode = value.districtCode;
        s.presentAddress.district.districtName = value.districtName;
        setPresentSelectedDistrict(value);
    }
    setProfile(s);
  };

  const handlePincodeChange = (event, addressType) => {
    const s = { ...profile };
    switch (addressType) {
      case AddressType.PERMANENT:
        s.permanentAddress.pinCode = event.target.value;
        break;
      case AddressType.PRESENT:
        s.presentAddress.pinCode = event.target.value;
        break;
    }
    setProfile(s);
  };

  const handleStateChange = (value, addressType) => {
    const s = { ...profile };
    switch (addressType) {
      case AddressType.PERMANENT:
        // s.permanentAddress.statename.stateCode = value.stateCode;
        s.permanentAddress.district.statename.stateCode = value.stateCode;
        getDistricts(value.stateCode);
        setPermanentSelectedState(value);

        break;
      case AddressType.PRESENT:
        // s.presentAddress.statename.stateCode = value.stateCode;
        s.presentAddress.district.statename.stateCode = value.stateCode;
        getPresentDistricts(value.stateCode);
        setPresentSelectedState(value);
    }
    setProfile(s);
  };

  const handleTTypeChange = (value) => {
    const s = { ...profile };
    setSelectedTenancyType(value);
    s.type = value.tenancyCode;
    setProfile(s);
  };

  const handleMobileNumberChange = (event) => {
    event.target.value.length < 11 &&
      setProfile({ ...profile, mobile: event.target.value });
    event.target.value.length > 0 && /^\d+$/.test(event.target.value)
      ? setErrors({ ...errors, mobile: { exist: false } })
      : event.target.value.length === 0
        ? setErrors({
          ...errors,
          mobile: { exist: true, helperText: "Mobile number required" },
        })
        : event.target.value.length < 11 &&
        setErrors({
          ...errors,
          mobile: { exist: true, helperText: "Only numbers are allowed" },
        });
  };

  const handleIDChange = (event) => {
    setEpicNo(event.target.value);
  };
  const copyAddress = () => {
    const s = { ...profile };
    Object.assign(s.presentAddress, s.permanentAddress);
    setPresentSelectedDistrict(permanentSelectedDistrict);
    setPresentSelectedState(permanentSelectedState);
    setProfile(s);
  };

  const validateTenantData = () => {
    const e = errorInitialState;
    if (profile.firstName === "") {
      e.error = true;
      e.firstName.exist = true;
    }
    if (profile.epicNo === "") {
      e.error = true;
      e.epicNo.exist = true;
    }
    // if (profile.lastName === "") {
    //   e.error = true;
    //   e.lastName.exist = true;
    // }
    // if (profile.email === "") {
    //   e.error = true;
    //   e.email.exist = true;
    // }
    if (profile.occupation === "") {
      e.error = true;
      e.occupation.exist = true;
    }
    if (profile.mobile === "") {
      e.error = true;
      e.mobile.exist = true;
    }
    if (profile.parentOrspouseName === "") {
      e.error = true;
      e.parentOrspouseName.exist = true;
    }
    if (profile.permanentAddress.address1 === "") {
      e.error = true;
      e.permanentAddress.address1.exist = true;
    }
    if (profile.permanentAddress.district.statename.stateCode === "") {
      e.error = true;
      e.permanentAddress.state.exist = true;
    }
    if (profile.permanentAddress.district.districtCode === "") {
      e.error = true;
      e.permanentAddress.district.exist = true;
    }
    if (profile.permanentAddress.pinCode === "") {
      e.error = true;
      e.permanentAddress.pincode.exist = true;
    }
    if (profile.presentAddress.address1 === "") {
      e.error = true;
      e.presentAddress.address1.exist = true;
    }
    // if (profile.presentAddress.district.statename.stateCode === "") {
    //   e.error = true;
    //   e.presentAddress.statename.exist = true;
    // }
    if (profile.presentAddress.district.districtCode === "") {
      e.error = true;
      e.presentAddress.district.exist = true;
    }
    if (profile.presentAddress.pinCode === "") {
      e.error = true;
      e.presentAddress.pincode.exist = true;
    }
    if (profile.type === "") {
      e.error = true;
      e.type.exist = true;
    }
    return e;
  };
  const save = async () => {
    setBackDropOpen(true);
    if (profile.middleName === "") delete profile.middleName;
    if (profile.email === "") delete profile.email;

    delete profile.exist;
    delete profile.id;
    profile.individualEpic = true;
    profile.type = "T1";
    var appData = {
      tenantid: "",
      rentcode:
        // getDecryptedData("role") === null
        sessionStorage.getItem("role") === null
          ? ""
          : selectedRentalUnit.rentcode,
      landlordid: currentApplication.id,
      applStatus: {
        applstatus: "LAVP",
      },
      landlordcode: currentApplication.id,
      applType: "APPLPS",
      // logby: getDecryptedData("username"),
      logby: sessionStorage.getItem("username"),
    };
    TenantService.create(profile)
      .then((response) => {
        // setSaving(true);
        setBackDropOpen(false);

        appData.tenantid = response.data.id;
        // alert("Successfull saved Tenant Details!");

        sessionStorage.setItem("tenantId", response.data.id); // This is a temporary fixed. We need to move this to some state
        // storeEncryptedData("tenantId", response.data.id); // This is a temporary fixed. We need to move this to some state

        //setTenantId(response.data.id); I am not able to set this, hence doing some small un-cleaned code 👆
        // editTenantApplication(response.data);
        // getDecryptedData("role") === null &&
        sessionStorage.getItem("role") === null &&
          httpApi.get(`apikey/getOTP/${response.data.id}`).then(res => setOtpValue(res.data));
        // getDecryptedData("role") === null && setOpenVerifyOTP(true);
        // (getDecryptedData("role") === "MODERATOR" ||
        //   getDecryptedData("role") === "SUPERMODERATOR") &&
        // sessionStorage.getItem("role") === null && setOpenVerifyOTP(true);
        (sessionStorage.getItem("role") === "MODERATOR" ||
          sessionStorage.getItem("role") === "SUPERMODERATOR") &&
          setDisableOtherTabs(false);
        // sessionStorage.getItem("userlevel") !== null &&
        setApplicationData(appData);
        // return TenantService.createApplication(appData);
        if (props.setSaved !== null) props.setSaved(true);
        openSnackBar({
          message:
            `Successfully Saved Tenant. Please login to proceed with further data entries.`,
          open: true,
          severity: "success",
        })
        props.setActiveStep((prevActiveStep) => prevActiveStep - 1)

        // setOpenRegConfirmation(true);
        setSavedFlag(true);
        setSaving(false);
      })
      .catch((error) => {
        setBackDropOpen(false);

        if (error.response !== null && error.status === 400) {
          // Request made and server responded
          setSnackBar({
            ...snackBar,
            message: error.response.data.message,
            open: true,
            severity: "error",
          });
        } else if (error.request) {
          // The request was made but no response was received
          setSnackBar({
            ...snackBar,
            message: error.response.data.message
              ? error.response.data.message
              : error.response.data, // error message is a string!
            open: true,
            severity: "error",
          });
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });

    /* if(error!=null || error.response!=undefined){
               
         // Loop through all the error array 
         var errorStr= '';
         if(error.response.data!=undefined){
   
           for(var i=0;i<error.response.data.length;i++){
             errorStr+= error.response.data[i] + '\n';
           }
   
         */
    // var msg = "Error in Server";
    // if (error.response != null) msg = error.response.data.message;

    // setSnackBar({
    //   ...snackBar,
    //   message: msg,
    //   open: true,
    //   severity: "error",
    // });

    // throw error;
    // .then((response) => {
    //   setSnackBar({
    //     ...snackBar,
    //     message: "Successfully Saved Tenant Application",
    //     open: true,
    //     severity: "success",
    //   });
    //   setSavedFlag(true);

    //   setDisableOtherTabs(false);
    // });
    // Save Landlord Here
    return true; // Successfully Saved
  };
  const update = async () => {
    if (profile.middleName === "") delete profile.middleName;
    if (profile.email === "") delete profile.email;

    delete profile.exist;
    profile.type = selectedTenancyType.tenancyCode;

    profile.individualEpic = true;

    TenantService.update(profile)
      .then((response) => {
        setSnackBar({
          ...snackBar,
          message: (
            <Typography>Successfully Updated Tenant Application.</Typography>
          ),
          open: true,
          severity: "success",
        });
        // setSavedFlag(true);
        // setSaving(false);
      })
      .catch((error) => {
        if (error.response !== null && error.status === 400) {
          // Request made and server responded
          setSnackBar({
            ...snackBar,
            message: error.response.data.message,
            open: true,
            severity: "error",
          });
        } else if (error.request) {
          // The request was made but no response was received
          setSnackBar({
            ...snackBar,
            message: error.response.data.message
              ? error.response.data.message
              : error.response.data, // error message is a string!
            open: true,
            severity: "error",
          });
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });

    /* if(error!=null || error.response!=undefined){
               
         // Loop through all the error array 
         var errorStr= '';
         if(error.response.data!=undefined){
   
           for(var i=0;i<error.response.data.length;i++){
             errorStr+= error.response.data[i] + '\n';
           }
   
         */
    // var msg = "Error in Server";
    // if (error.response != null) msg = error.response.data.message;

    // setSnackBar({
    //   ...snackBar,
    //   message: msg,
    //   open: true,
    //   severity: "error",
    // });

    // throw error;
    // .then((response) => {
    //   setSnackBar({
    //     ...snackBar,
    //     message: "Successfully Saved Tenant Application",
    //     open: true,
    //     severity: "success",
    //   });
    //   setSavedFlag(true);

    //   setDisableOtherTabs(false);
    // });
    // Save Landlord Here
    return true; // Successfully Saved
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
      >
        <Alert
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          severity={snackBar.severity}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      {/* <Widget disableWidgetMenu={false}> */}
      {selectedRentalUnit != null && selectedRentalUnit != false ? (
        <Typography variant="h6" component="h6" style={{ marginTop: 0 }}>
          {"Floor Number: " +
            selectedRentalUnit.floorNo +
            " / House Number: " +
            selectedRentalUnit.houseNo}
        </Typography>
      ) : (
        ""
      )}
      <Box
        sx={{
          p: 2,
          bgcolor: "background.default",
          display: "grid",
          gridTemplateColumns: { md: "1fr 1fr 1fr" },
          gap: 2,
        }}
      >
        <Paper
          variant="elevation"
          sx={{
            padding: { xs: "5%", sm: "7%", lg: "2%" },
          }}
          elevation="4"
        >
          {/* <div className={classTheme.dashedBorder} style={{ marginTop: 40 }}> */}
          <Card style={{ marginBottom: "2%" }}>
            <Typography
              align="center"
              variant="h6"
              component="h6"
              style={{ marginTop: 0 }}
              className={classes.heading}
            >
              Basic Details
            </Typography>
          </Card>
          <Stack direction="column" spacing={2}>
            <FormControl fullWidth>
              <TextField
                id="epic-number"
                value={profile.epicNo}
                label={
                  <span>
                    Epic Card Number <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                error={errors.epicNo.exist === true ? true : false}
                helperText={errors.epicNo.exist ? errors.epicNo.helperText : ""}
                disabled={profile.exist ? true : false}
                inputProps={profile.exist && fontColor}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="firstName"
                onChange={handlefirstNameChange}
                value={profile.firstName}
                label={
                  <span>
                    First Name <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                error={errors.firstName.exist === true ? true : false}
                helperText={
                  errors.firstName.exist ? errors.firstName.helperText : ""
                }
                disabled={profile.exist ? true : false}
                inputProps={profile.exist && fontColor}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="middleName"
                onChange={handlemiddleNameChange}
                value={profile.middleName}
                label="Middle Name"
                disabled={profile.exist ? true : false}
                inputProps={profile.exist && fontColor}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="lastName"
                onChange={handlelastNameChange}
                value={profile.lastName}
                label={
                  <span>
                    Last Name
                  </span>
                }
                // error={errors.lastName.exist}
                // helperText={
                //   errors.lastName.exist ? errors.lastName.helperText : ""
                // }
                disabled={profile.exist ? true : false}
                inputProps={profile.exist && fontColor}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="email"
                onChange={handleEmailChange}
                value={profile.email}
                label="Email"
                // error={errors.email.exist}
                // helperText={errors.email.exist ? errors.email.helperText : ""}
                disabled={
                  profile.exist
                    ? selectedRentalUnit === false
                      ? false
                      : true
                    : false
                }
                inputProps={profile.exist && fontColor}
              />
            </FormControl>

            <FormControl fullWidth>
              <Autocomplete
                onChange={(event, value) => {
                  handleoccupationChange(value);
                }}
                id="occupation"
                options={occupation}
                value={
                  props.profile.exist === true
                    ? { value: props.profile.occupation } //must be an object. This object is then fetched by getOptionLabel
                    : selectedOccupation
                }
                getOptionLabel={(option) => option.value}
                getOptionSelected={() => selectedOccupation}
                // style={{ width: 200 }}
                disabled={
                  profile.exist
                    ? selectedRentalUnit === false
                      ? false
                      : true
                    : false
                }





                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={!profile.exist && <span>
                      Occupation <span style={{ color: 'red' }}>*</span>
                    </span>}
                    variant="outlined"
                    error={errors.occupation.exist}
                    helperText={
                      errors.occupation.exist
                        ? errors.occupation.helperText
                        : ""
                    }
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="parent-spouse"
                onChange={handleParentOrspouseNameChange}
                value={profile.parentOrspouseName}
                label="Parent or Spouse Name "
                disabled={profile.exist ? true : false}
                inputProps={profile.exist && fontColor}
                error={errors.parentOrspouseName.exist}
                helperText={
                  errors.parentOrspouseName.exist
                    ? errors.parentOrspouseName.helperText
                    : ""
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <Autocomplete
                disabled={profile.exist ? true : false}
                onChange={handleNationalityChange}
                options={["Indian", "Others"]}
                value={
                  props.profile !== null
                    ? props.profile.nationality
                    : selectedNationality
                }
                getOptionLabel={(option) => option}
                // style={{ width: 200 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        Nationality <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    variant="outlined"
                  // error={errors.permanentAddress.state.exist}
                  // helperText={
                  //   errors.permanentAddress.state.exist
                  //     ? errors.permanentAddress.state.helperText
                  //     : ""
                  />
                )}
              />
            </FormControl>

            {/* <FormControl>
              <TextField
                id="age"
                onChange={handleAgeChange}
                value={profile.age}
                label="Age "
                disabled={profile.exist ? true : false}
                inputProps={profile.exist && fontColor}
              />
            </FormControl> */}
            {/* 
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                openTo="day"
                label="Age"
                value={value}
                okText
                onChange={(newValue) => {
                  // if (newValue !== null) {
                  setValue(newValue);
                  // let text = newValue.toString().substring(4, 15);
                  // setAge({
                  //   ...age,
                  //   month: getMonthNumber(text.substring(0, 3)),
                  //   day: text.substring(4, 6),
                  //   year: text.substring(7, 11),
                  // });
                  // }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // helperText={params?.inputProps?.placeholder}
                  />
                )}
              />
            </LocalizationProvider> */}
            <TextField
              disabled={profile.exist ? true : false}
              id="date"
              label="Date of Birth"
              value={profile.age}
              type="date"
              defaultValue="YYYY-MM-DD"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleAgeChange}
            />
            <FormControl>
              <TextField
                id="mobilenumber"
                disabled={
                  profile.exist
                    ? selectedRentalUnit === false
                      ? false
                      : true
                    : false
                }
                onChange={handleMobileNumberChange}
                value={profile.mobile}
                label={
                  <span>
                    Mobile Number <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                error={errors.mobile.exist}
                helperText={
                  errors.mobile.exist ? (
                    errors.mobile.helperText
                  ) : (
                    <InfoText text="Enter numbers from 0 - 9" />
                  )
                }
                inputProps={profile.exist && fontColor}
              />
            </FormControl>
            <FormControl fullWidth>
              <Autocomplete
                onChange={(event, value) => {
                  handleTTypeChange(value);
                }}
                id="tenancy-type"
                options={tenancyTypes}
                getOptionSelected={() => selectedTenancyType}
                getOptionLabel={(option) => option.type}
                // style={{ width: 200, margin: "auto" }}
                value={
                  props.profile !== null
                    ? selectedRentalUnit === false
                      ? selectedTenancyType
                      : props.profile.type
                    : selectedTenancyType
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        Type <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    variant="outlined"
                    error={errors.type.exist}
                    helperText={errors.type.exist ? errors.type.helperText : ""}
                  />
                )}
                disabled={
                  profile.exist
                    ? selectedRentalUnit === false
                      ? false
                      : true
                    : false
                }
              />
            </FormControl>
          </Stack>
          {/* </div> */}
        </Paper>

        {/* </div> */}

        <Paper
          variant="elevation"
          sx={{ padding: { xs: "5%", sm: "7%", lg: "2%" } }}
          elevation="4"
        >
          <Card style={{ marginBottom: "2%" }}>
            <Typography
              align="center"
              variant="h6"
              component="h6"
              style={{ marginTop: 0 }}
              className={classes.heading}
            >
              Permanent Address
            </Typography>
          </Card>
          <Stack direction="column" spacing={2}>
            <FormControl fullWidth>
              <TextField
                id="permanent-address-1"
                disabled={
                  profile.exist
                    ? selectedRentalUnit === false
                      ? false
                      : true
                    : false
                }
                inputProps={profile.exist && fontColor}
                onChange={(event) => {
                  handleAddressLine1Change(event, AddressType.PERMANENT);
                }}
                value={profile.permanentAddress.address1}
                label={
                  <span>
                    Address Line-1 <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                error={errors.permanentAddress.address1.exist}
                helperText={
                  errors.permanentAddress.address1.exist
                    ? errors.permanentAddress.address1.helperText
                    : ""
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                disabled={
                  profile.exist
                    ? selectedRentalUnit === false
                      ? false
                      : true
                    : false
                }
                id="permanent-address-2"
                onChange={(event) => {
                  handleAddressLine2Change(event, AddressType.PERMANENT);
                }}
                value={profile.permanentAddress.address2}
                label="Address Line-2"
                inputProps={profile.exist && fontColor}
              />
            </FormControl>

            <FormControl fullWidth>
              <Autocomplete
                disabled={
                  profile.exist
                    ? selectedRentalUnit === false
                      ? false
                      : true
                    : false
                }
                onChange={(event, value) => {
                  handleStateChange(value, AddressType.PERMANENT);
                }}
                id="permanent-state"
                options={states}
                value={
                  props.profile.exist === true
                    ? selectedRentalUnit === false
                      ? permanentSelectedState
                      : {
                        stateName:
                          props.profile.permanentAddress.district.statename
                            .stateName,
                      }
                    : permanentSelectedState
                }
                getOptionLabel={(option) => option.stateName}
                getOptionSelected={() => permanentSelectedState}
                // style={{ width: 200 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        State <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    variant="outlined"
                    error={errors.permanentAddress.state.exist}
                    helperText={
                      errors.permanentAddress.state.exist
                        ? errors.permanentAddress.state.helperText
                        : ""
                    }
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <Autocomplete
                disabled={
                  profile.exist
                    ? selectedRentalUnit === false
                      ? false
                      : true
                    : false
                }
                onChange={(event, value) => {
                  value !== null
                    ? handleDistrictChange(value, AddressType.PERMANENT)
                    : setPermanentSelectedDistrict(null);
                }}
                id="permanent-district"
                options={districts}
                value={
                  props.profile !== null
                    ? props.profile.permanentAddress.district
                    : permanentSelectedDistrict
                }
                getOptionLabel={(option) => option.districtName}
                // style={{ width: 200 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        District <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    variant="outlined"
                    error={errors.permanentAddress.state.exist}
                    helperText={
                      errors.permanentAddress.district.exist
                        ? errors.permanentAddress.district.helperText
                        : ""
                    }
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="permanent-pincode"
                disabled={
                  profile.exist
                    ? selectedRentalUnit === false
                      ? false
                      : true
                    : false
                }
                onChange={(event) => {
                  handlePincodeChange(event, AddressType.PERMANENT);
                }}
                value={profile.permanentAddress.pinCode}
                label={
                  <span>
                    Pincode <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                error={errors.permanentAddress.state.exist}
                helperText={
                  errors.permanentAddress.pincode.exist
                    ? errors.permanentAddress.pincode.helperText
                    : ""
                }
                inputProps={profile.exist && fontColor}
              />
            </FormControl>
          </Stack>
        </Paper>

        <Paper
          variant="elevation"
          sx={{ padding: { xs: "5%", sm: "7%", lg: "2%" } }}
          elevation="4"
        >
          <Card style={{ marginBottom: "2%" }}>
            <Typography
              align="center"
              variant="h6"
              component="h6"
              style={{ marginTop: 0 }}
              className={classes.heading}
            >
              Present Address
            </Typography>
          </Card>
          <FormControlLabel
            disabled={
              profile.exist
                ? selectedRentalUnit === false
                  ? false
                  : true
                : false
            }
            control={
              <Checkbox
                checked={isSameAspermanentAddress}
                name="sameAddress"
                onChange={() => {
                  if (!isSameAspermanentAddress) copyAddress();

                  setSameAspermanentAddress(!isSameAspermanentAddress);
                }}
              />
            }
            label="Same as Permanent Address"
          />
          <Stack direction="column" spacing={2}>
            <FormControl fullWidth>
              <TextField
                id="present-address-1"
                label={
                  <span>
                    Address Line-1 <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                disabled={
                  profile.exist
                    ? selectedRentalUnit === false
                      ? false
                      : true
                    : false
                }
                onChange={(event) => {
                  handleAddressLine1Change(event, AddressType.PRESENT);
                }}
                value={profile.presentAddress.address1}
                error={errors.presentAddress.address1.exist}
                helperText={
                  errors.presentAddress.address1.exist
                    ? errors.presentAddress.address1.helperText
                    : ""
                }
                inputProps={profile.exist && fontColor}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                disabled={
                  profile.exist
                    ? selectedRentalUnit === false
                      ? false
                      : true
                    : false
                }
                id="present-address-2"
                label="Address Line-2"
                onChange={(event) => {
                  handleAddressLine2Change(event, AddressType.PRESENT);
                }}
                value={profile.presentAddress.address2}
                inputProps={profile.exist && fontColor}
              />
            </FormControl>

            <FormControl fullWidth>
              <Autocomplete
                disabled={
                  profile.exist
                    ? selectedRentalUnit === false
                      ? false
                      : true
                    : false
                }
                onChange={(event, value) => {
                  handleStateChange(value, AddressType.PRESENT);
                }}
                id="present-state"
                options={states}
                value={
                  props.profile.exist === true
                    ? selectedRentalUnit === false
                      ? presentSelectedState
                      : {
                        stateName:
                          props.profile.presentAddress.district.statename
                            .stateName,
                      }
                    : presentSelectedState
                }
                getOptionSelected={() => selectedState}
                getOptionLabel={(option) => option.stateName}
                // style={{ width: 200 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        State <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    variant="outlined"
                    error={errors.presentAddress.state.exist}
                    helperText={
                      errors.presentAddress.state.exist
                        ? errors.presentAddress.state.helperText
                        : ""
                    }
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <Autocomplete
                disabled={
                  profile.exist
                    ? selectedRentalUnit === false
                      ? false
                      : true
                    : false
                }
                onChange={(event, value) => {
                  value !== null &&
                    handleDistrictChange(value, AddressType.PRESENT);
                }}
                id="present-district"
                options={presentDistricts}
                value={
                  props.profile !== null
                    ? props.profile.presentAddress.district
                    : presentSelectedDistrict
                }
                getOptionSelected={() => selectedDistrict}
                getOptionLabel={(option) => option.districtName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        District <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    variant="outlined"
                    error={errors.presentAddress.district.exist}
                    helperText={
                      errors.presentAddress.district.exist
                        ? errors.presentAddress.district.helperText
                        : ""
                    }
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                disabled={
                  profile.exist
                    ? selectedRentalUnit === false
                      ? false
                      : true
                    : false
                }
                id="present-pincode"
                label={
                  <span>
                    Pincode <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                onChange={(event) => {
                  handlePincodeChange(event, AddressType.PRESENT);
                }}
                value={profile.presentAddress.pinCode}
                error={errors.presentAddress.pincode.exist}
                helperText={
                  errors.presentAddress.pincode.exist
                    ? errors.presentAddress.pincode.helperText
                    : ""
                }
                inputProps={profile.exist && fontColor}
              />
            </FormControl>
          </Stack>
        </Paper>
      </Box>

      {profile.exist !== true ? (
        <div>
          {savedFlag && (
            <Button
              variant="contained"
              color="primary"
              onClick={props.toNextTab}
            >
              Next
            </Button>
          )}

          <LoadingButton
            loading={saving}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            style={{ float: "right", marginTop: 5 }}
            variant="contained"
            color="primary"
            onClick={async () => {
              const errs = validateTenantData();
              if (errs.error === true) {
                setErrors(errs);
                setSnackBar({
                  message: "Some fields are empty!",
                  severity: "error",
                  open: true,
                });
                return;
              }

              const status = await save();
            }}
            disabled={savedFlag}
          >
            Save
          </LoadingButton>
        </div>
      ) : (
        <>
          {selectedRentalUnit === false && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  const errs = validateTenantData();
                  if (errs.error === true) {
                    setErrors(errs);
                    setSnackBar({
                      message: "Some fields are empty!",
                      severity: "error",
                      open: true,
                    });
                    return;
                  }

                  const status = await update();
                }}
              >
                Update
              </Button>
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={props.toNextTab}
            >
              Next
            </Button>
          </Box>
        </>
      )}
      {/* </Widget> */}
      <VerifyOTPDialog
        openVerifyOTP={openVerifyOTP}
        setOpenVerifyOTP={setOpenVerifyOTP}
        // id={getDecryptedData("tenantId")}
        id={sessionStorage.getItem("tenantId")}
        setDisableOtherTabs={setDisableOtherTabs}
        snackBar={snackBar}
        setSnackBar={setSnackBar}
        otpValueForTenant={otpValue}
      />
    </>
  );
}
