import React from "react";
import { Paper, Button, Box } from "@mui/material";
import MUIDataTable from "mui-datatables";
import jsPDF from "jspdf";
import "jspdf-autotable";

const options = {
  filter: true,
  selectableRows: "multiple",
  selectableRowsOnClick: false,
  selectableRowsHideCheckboxes: true,
  filterType: "dropdown",
  responsive: "vertical",
  enableNestedDataAccess: ".", // allows nested data separated by "."
  fixedHeader: true,
  tableBodyHeight: "500px",
  setRowProps: (row, dataIndex, rowIndex) => {
    return (
      rowIndex % 2 === 0 && {
        style: { backgroundColor: "#E8EAFC" },
      }
    );
  },
};

const CustomDataTable = ({ rows, columns, title }) => {
  const handleExportPDF = () => {
    const doc = new jsPDF();


    // Define a mapping for applStatus to text
    const getStatusLabel = (status) => {
      switch (status) {
        case "PLVP":
          return "APPROVED";
        case "LAVP":
          return "Pending at Locality/Cantonment level";
        case "RJT":
          return "REJECTED";
        default:
          return "Pending";
      }
    };



    const tableColumn = columns.filter((col) => col.name !== "logby" && col.name !== "actions") // Exclude specific columns
      .map((col) => col.label || col.name); // Extract column names
    const tableRows = rows.map((row) => {
      return columns
        .filter((col) => col.name !== "logby" && col.name !== "actions") // Exclude specific columns
        .map((col) => {
          if (col.name === "applStatus") {
            return getStatusLabel(row[col.name]); // Convert applStatus code to label
          }
          return row[col.name];
        });
    }
    );

    doc.setFontSize(14);
    doc.text(title, 14, 15); // Title
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 8, cellPadding: 1 }, // Compact styling
    });

    doc.save(`${title}.pdf`);
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleExportPDF}
        style={{ margin: "10px" }}
      >
        Export to PDF
      </Button>
      <Paper>
        <MUIDataTable
          title={<b>{title}</b>}
          data={rows}
          columns={columns}
          options={options}
        />
      </Paper>
    </Box>
  );
};

export default CustomDataTable;
