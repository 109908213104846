import React, { useState } from "react";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";
import { Box, Paper } from "@material-ui/core";
import tinycolor from "tinycolor2";
import DashboardCard from "./DashboardCard";
import { getDecryptedData } from "../../api/GlobalService";
import AssignmentIcon from '@mui/icons-material/Assignment';
import HailIcon from '@mui/icons-material/Hail';
import { Grid } from "@mui/material";


// import { UserStateContext } from "./../../context/UserContext";
// import { UserStateContext } from "../../context/GlobalState";

export default function PageTitle(props) {
  var classes = useStyles();

  // const {} = useContext(UserStateContext);

  return (
    <div className={classes.pageTitleContainer}>
      {props.title === "Dashboard" ? (
        <Grid container wrap="wrap" spacing={3} justifyContent="center">
          {/*Landlord in the state */}
          {
            sessionStorage.getItem("role") === "VERIFIER"
            // getDecryptedData("role") === "VERIFIER"
            && (
              <>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  {/* <Paper
                className={classes.paper}
                style={{ backgroundColor: "#caf3fc" }}
              > */}
                  {/* <PoliceStations /> */}
                  <DashboardCard
                    icon={<AssignmentIcon
                      sx={{ fontSize: 50, color: "#fff" }}

                    />}
                    bgColor="#FE621D
"
                    cardTitle="Sub-Localities/Wards"
                    textColor="#fff"
                    link="/app/local-authorities"
                  />
                  {/* </Paper> */}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  {/* <Paper
                className={classes.paper}
                style={{ backgroundColor: "#caf3fc" }}
              > */}
                  {/* <PoliceStations /> */}
                  <DashboardCard
                    icon={<AssignmentIcon
                      sx={{ fontSize: 50, color: "#fff" }}

                    />}
                    bgColor="#FE621D
"
                    cardTitle="Localities"
                    textColor="#fff"
                    link="/app/localities"
                  />
                  {/* </Paper> */}
                </Grid>
              </>
            )
          }
          {
            sessionStorage.getItem("role") === "ADMIN" &&
            // getDecryptedData("role") === "ADMIN" &&
            (
              <>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  {/* <Paper
                className={classes.paper}
                style={{ backgroundColor: "#caf3fc" }}
              > */}
                  <DashboardCard
                    icon={<AssignmentIcon
                      sx={{ fontSize: 50, color: "#fff" }}

                    />}
                    bgColor="#AF3B6E
"
                    cardTitle="Registered Police Stations"
                    textColor="#fff"
                    link="/app/policestations"
                  />

                  {/* </Paper> */}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <DashboardCard
                    icon={<AssignmentIcon
                      sx={{ fontSize: 50, color: "#fff" }}

                    />}
                    bgColor="#FE621D
"
                    cardTitle="Registered Sub-Localities/Wards"
                    textColor="#fff"
                    link="/app/registered-local-authorities"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <DashboardCard
                    icon={<AssignmentIcon
                      sx={{ fontSize: 50, color: "#fff" }}

                    />}
                    bgColor="#FE621D
"
                    cardTitle="Registered Localities"
                    textColor="#fff"
                    link="/app/registered-localities"
                  />
                </Grid>
              </>
            )}

          {
            sessionStorage.getItem("role") === "SUPERVISOR" && (
              // getDecryptedData("role") === "SUPERVISOR" && (
              <>
                <Grid item xs={12} sm={6} lg={3}>
                  {/* <Paper
                className={classes.paper}
                style={{ backgroundColor: "#caf3fc" }}
              > */}
                  <DashboardCard
                    icon={<AssignmentIcon
                      sx={{ fontSize: 50, color: "#fff" }}

                    />}
                    bgColor="#FE621D
"
                    cardTitle="Registered Police Stations"
                    textColor="#fff"
                    link="/app/policestations"
                  />
                  {/* </Paper> */}
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <DashboardCard
                    icon={<AssignmentIcon
                      sx={{ fontSize: 50, color: "#fff" }}

                    />}
                    bgColor="#FE621D
"
                    cardTitle="Registered Sub-Localities/Wards"
                    textColor="#fff"
                    link="/app/registered-local-authorities"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <DashboardCard
                    icon={<AssignmentIcon
                      sx={{ fontSize: 50, color: "#fff" }}

                    />}
                    bgColor="#FE621D
"
                    cardTitle="Registered Localities"
                    textColor="#fff"
                    link="/app/registered-localities"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3} >
                  <Box marginBottom={"1rem"}>
                    <DashboardCard
                      icon={<HailIcon />}
                      bgColor="#FE621D
"
                      cardTitle="Total Tenants Registered"
                      textColor="#fff"
                      link="/app/registered-localities"
                      noAction={true}
                    />
                  </Box>
                  <DashboardCard
                    icon={<AssignmentIcon
                      sx={{ fontSize: 50, color: "#fff" }}

                    />}
                    bgColor="#FE621D
"
                    cardTitle="Total Landlords Registered"
                    textColor="#fff"
                    link="/app/registered-localities"
                    noAction={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={12}>

                  {/* <DashboardChart />  */}
                  {/* <PieChart /> */}
                  {/* <BarChart /> */}
                </Grid>
              </>
            )}
          {
            sessionStorage.getItem("role") !== "ADMIN" && (
              // getDecryptedData("role") !== "ADMIN" && (
              <>
                {sessionStorage.getItem("role") !== "SUPERVISOR" && (
                  // {getDecryptedData("role") !== "SUPERVISOR" && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {/* <Paper
                className={classes.paper}
                style={{ backgroundColor: "#fce6ca" }}
              > */}
                    <DashboardCard
                      icon={<AssignmentIcon
                        sx={{ fontSize: 50, color: "#fff" }}

                      />}
                      bgColor="#4B8F8C
"
                      cardTitle="Approved Applications"
                      textColor="#fff"
                      link="/app/application/approved"
                    />
                    {/* </Paper> */}
                  </Grid>
                )}
                {sessionStorage.getItem("role") === "MODERATOR" && (
                  // {getDecryptedData("role") === "MODERATOR" && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {/* <Paper
                      className={classes.paper}
                      style={{ backgroundColor: "#c4fff3" }}
                    > */}
                    <DashboardCard
                      icon={<AssignmentIcon
                        sx={{ fontSize: 50, color: "#fff" }}

                      />}
                      bgColor="#F4AC45

"
                      cardTitle="Forwarded Applications"
                      textColor="#fff"
                      link="/app/application/forwarded"
                    />
                    {/* </Paper> */}
                  </Grid>
                )}

                {/* {(getDecryptedData("role") === "MODERATOR" ||
                  getDecryptedData("role") === "SUPERMODERATOR") && ( */}
                {(sessionStorage.getItem("role") === "MODERATOR" ||
                  sessionStorage.getItem("role") === "SUPERMODERATOR") && (
                    <>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {/* <Paper
                      className={classes.paper}
                      style={{ backgroundColor: "#c4fff3" }}
                    > */}
                        {/* <PendingApplications /> */}
                        <DashboardCard
                          icon={<AssignmentIcon
                            sx={{ fontSize: 50, color: "#fff" }}

                          />}
                          bgColor="#FE621D
"
                          cardTitle="Pending Applications"
                          textColor="#fff"
                          link="/app/application/pending"
                        />
                        {/* </Paper> */}
                      </Grid>
                    </>
                  )}
                {/* {getDecryptedData("role") !== "SUPERVISOR" && ( */}
                {/* {sessionStorage.getItem("role") !== "SUPERVISOR" && ( */}
                  {/* <Grid item xs={12} sm={6} md={4} lg={3}> */}
                    {/* <Paper
                  className={classes.paper}
                  style={{ backgroundColor: "#c4ddff" }}
                > */}
                    {/* <DashboardCard
                      icon={<AssignmentIcon
                        sx={{ fontSize: 50, color: "#fff" }}

                      />}
                      bgColor="#4E5340
"
                      cardTitle="Rejected Applications"
                      textColor="#fff"
                      link="/app/application/rejected"
                    /> */}
                    {/* </Paper> */}
                  {/* </Grid> */}
                {/* )} */}

                {/* {(getDecryptedData("role") === "MODERATOR" ||
                  getDecryptedData("role") === "SUPERMODERATOR") && ( */}
                {(sessionStorage.getItem("role") === "MODERATOR" ||
                  sessionStorage.getItem("role") === "SUPERMODERATOR") && (
                    <>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {/* <Paper
                      className={classes.paper}
                      style={{ backgroundColor: "#c4fff3" }}
                    > */}
                        {/* <PendingApplications /> */}
                        <DashboardCard
                          icon={<HailIcon
                            sx={{
                              fontSize: 50,
                              color: "#fff"
                            }}
                          />}
                          bgColor="#8AA29E
                        
"
                          cardTitle="Tenants"
                          textColor="#fff"
                          link="/app/approved-tenants"
                        />
                        {/* </Paper> */}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {/* <Paper
                      className={classes.paper}
                      style={{ backgroundColor: "#c4fff3" }}
                    > */}
                        {/* <PendingApplications /> */}
                        <DashboardCard
                          icon={<HailIcon
                            sx={{
                              fontSize: 50,
                              color: "#fff"
                            }}
                          />}
                          bgColor="#8AA29E
                        
"
                          cardTitle="Shifted Tenants"
                          textColor="#fff"
                          link="/app/shifted-tenants"
                        />
                        {/* </Paper> */}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {/* <Paper
                      className={classes.paper}
                      style={{ backgroundColor: "#c4fff3" }}
                    > */}
                        {/* <PendingApplications /> */}
                        <DashboardCard
                          icon={<HailIcon
                            sx={{
                              fontSize: 50,
                              color: "#fff"
                            }}
                          />}
                          bgColor="#8AA29E
                        
"
                          cardTitle="Landlords"
                          textColor="#fff"
                          link="/app/landlords"
                        />
                        {/* </Paper> */}
                      </Grid>
                    </>
                  )}
              </>
            )}
        </Grid>
      ) : (
        <Typography className={classes.typo} variant="h1" size="sm">
          {props.title}
        </Typography>
      )}

      {props.button && props.button}
    </div>
  );
}
