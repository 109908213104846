import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  heading: {
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: 600,
    color: "#767C93",
    fontSize: "20px",
    zIndex: "20px",
    fontFamily: "raleway"
  },
}));
