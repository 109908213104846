import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import "./family.css";

const FamilyMemberFormUsingFormik = () => {
  return (
    <div>
      <Formik
        initialValues={{
          //   tenantId: sessionStorage.getItem("tenantId"),
          email: "",
          memberName: "",
          age: "",
          occupation: "",
          relationship: "",
        }}
        onSubmit={async (values) => {
          await new Promise((resolve) => setTimeout(resolve, 500));
          alert(JSON.stringify(values, null, 2));
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email("invalid email").required("Required"),

          memberName: Yup.string().required("Required"),
          age: Yup.number()
            .test(
              "Is positive?",
              "ERROR: The number must be greater than 0!",
              (value) => value > 0
            )
            .required("Required"),
          occupation: Yup.string().required("Required"),
          relationship: Yup.string().required("Required"),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              <label htmlFor="email" style={{ display: "block" }}>
                Email
              </label>
              <input
                id="email"
                placeholder="Enter your email"
                type="text"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.email && touched.email
                    ? "text-input error"
                    : "text-input"
                }
              />
              {errors.email && touched.email && (
                <div className="input-feedback">{errors.email}</div>
              )}
              <label htmlFor="memberName" style={{ display: "block" }}>
                Member Name
              </label>
              <input
                id="memberName"
                placeholder="Enter your memberName"
                type="text"
                value={values.memberName}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.memberName && touched.memberName
                    ? "text-input error"
                    : "text-input"
                }
              />
              {errors.memberName && touched.memberName && (
                <div className="input-feedback">{errors.memberName}</div>
              )}

              <label htmlFor="age" style={{ display: "block" }}>
                Age
              </label>
              <input
                id="age"
                placeholder="Enter your age"
                type="text"
                value={values.age}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.age && touched.age ? "text-input error" : "text-input"
                }
              />
              {errors.age && touched.age && (
                <div className="input-feedback">{errors.age}</div>
              )}
              <label htmlFor="occupation" style={{ display: "block" }}>
                Occupation
              </label>
              <input
                id="occupation"
                placeholder="Enter your occupation"
                type="text"
                value={values.occupation}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.occupation && touched.occupation
                    ? "text-input error"
                    : "text-input"
                }
              />
              {errors.occupation && touched.occupation && (
                <div className="input-feedback">{errors.occupation}</div>
              )}
              <label htmlFor="relationship" style={{ display: "block" }}>
                Relationship
              </label>
              <input
                id="relationship"
                placeholder="Enter your relationship"
                type="text"
                value={values.relationship}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.relationship && touched.relationship
                    ? "text-input error"
                    : "text-input"
                }
              />
              {errors.relationship && touched.relationship && (
                <div className="input-feedback">{errors.relationship}</div>
              )}

              <button
                type="button"
                className="outline"
                onClick={handleReset}
                disabled={!dirty || isSubmitting}
              >
                Reset
              </button>
              <button type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FamilyMemberFormUsingFormik;
