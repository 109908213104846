import React from "react";
import ApplicationService from "../api/ApplicationService";
import UserService from "../api/UserService";
import JSEncrypt from "jsencrypt";
import http from "../api/http-common";
import { getDecryptedData, storeEncryptedData, storeEncryptedToken } from "../api/GlobalService";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  console.log(state);
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, backgroundFlag: true };
    case "SERVER_ERROR":
      return { ...state, isAuthenticated: false, error: true };
    case "LOGIN_FAILURE": {
      return { ...state, isAuthenticated: false, error: true };
    }
    case "BG_LOGIN_FALSE": {
      return { ...state, backgroundFlag: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
function isAuthenticated() {
  var status = false; //kept false

  // Proxy for checking if we can request the API, best is to have  an API for checking if the session is still valid or not, for now we can use this,
  // It would be better to have one more api /islogin that reply yes (if token is still valid) else no (if expired)

  ApplicationService.getApplicationByStatus(
    "LAVP",
    // getDecryptedData("username")
    sessionStorage.getItem("username")
  )
    .then(() => {
      status = true;
    })
    .catch((e) => {
      status = false; // kept false
    });

  return status;
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: isAuthenticated(),
    backgroundFlag: true,
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  signOut,
  redirectLogout,
};

// ###########################################################

async function loginUser(
  publicKey,
  dispatch,
  login,
  password,
  captcha,
  history,
  setIsLoading,
  setError,
  setActiveTab,
  backgroundFlag,
  setErrorMessage,
  setCaptchaValue
) {
  let crypt = new JSEncrypt();
  crypt.setPublicKey(publicKey);
  let enc = crypt.encrypt(password);
  setError(false);
  setIsLoading(true);

  // Do a API Call Here for authentication , login (username), password

  if (backgroundFlag === false) {
    UserService.signIn(login, enc, captcha)
      .then((response) => {
        http.defaults.headers.common["Authorization"] = // injection of accessToken into headers of http instance
          "Bearer " + response.data.accessToken;
        sessionStorage.setItem("userlevel", response.data.userlevel);

        sessionStorage.setItem(
          "accessToken",
          "Bearer " + response.data.accessToken
        );
        // storeEncryptedData("accessToken", "Bearer " + response.data.accessToken)

        sessionStorage.setItem("role", response.data.roles[0]);
        // storeEncryptedData("role", response.data.roles[0])

        sessionStorage.setItem("username", response.data.username);
        // storeEncryptedData("username", response.data.username)


        sessionStorage.setItem("usercode", response.data.usercode);
        // storeEncryptedData("usercode", response.data.usercode)


        setError(null);
        setIsLoading(false);

        dispatch({ type: "LOGIN_SUCCESS" });
        history.push("/app/dashboard");
      })
      .catch((e) => {
        setError(true);
        setCaptchaValue("");
        if (e.response.data) {

          if (e.response.data.message) {
            setErrorMessage(e.response.data.message);
          } else {
            setErrorMessage(e.response.data);
          }
        }

        setIsLoading(false);
        //dispatch({ type: "LOGIN_FAILURE" }); // No need this, if enable it will reset the tabIndex State
      });
  }
}

function signOut(dispatch, history) {
  sessionStorage.clear();
  // sessionStorage.removeItem("userlevel");

  UserService.signOut()
    .then(() => {
      // useEffect(() => {
      //   Cookies.remove("session");
      //   history.push("/login");
      // }, []);

      delete http.defaults.headers.common["Authorization"];
      dispatch({ type: "SIGN_OUT_SUCCESS" });
      history.push("/");
    })
    .catch((e) => console.log("ERR in signOut: ", e));
}

function redirectLogout(dispatch) {
  sessionStorage.clear();

  dispatch({ type: "SIGN_OUT_SUCCESS" });
}
