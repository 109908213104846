import React, { useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { GlobalContext } from "../context/GlobalState";
import { useUserDispatch, signOut } from "../context/UserContext";
import { useHistory } from "react-router";
import { togglePrompt, useLayoutDispatch } from "../context/LayoutContext";
import { getDecryptedData } from "../api/GlobalService";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#B12B39",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
    // fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  viewAppButton: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.4rem",
    },
  },
  actionButton: {
    justifyContent: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const Prompt = ({
  setLoginValue,
  setActiveStep,
  setLndOrTntLogin,
  setPrompt,
  prompt,
  text,
}) => {
  const { newTenantApplication, newLandlordApplication } = useContext(
    GlobalContext
  );
  var userDispatch = useUserDispatch();
  var layoutDispatch = useLayoutDispatch();

  let history = useHistory();
  const classes = useStyles();

  let role = sessionStorage.getItem("role");
  // let role = getDecryptedData("role");

  return (
    <Dialog
      open={prompt}
      onClose={() =>
        role !== null ? togglePrompt(layoutDispatch) : setPrompt(false)
      }
      fullWidth
    >
      <DialogContent>
        <DialogContentText>
          <Typography align="center">{text}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actionButton}>
        <IconButton
          style={{ backgroundColor: "#92d459", color: "#fff" }}
          onClick={async () => {
            if (role === null) {
              await newTenantApplication();
              await newLandlordApplication();
              setLoginValue("");
              setActiveStep(0);
              setLndOrTntLogin(false);

              setPrompt(false);
            } else {
              signOut(userDispatch, history);
              togglePrompt(layoutDispatch);
            }
          }}
        >
          <CheckCircleOutlineIcon />
        </IconButton>
        <IconButton
          onClick={() =>
            role !== null ? togglePrompt(layoutDispatch) : setPrompt(false)
          }
          style={{ backgroundColor: "#eb5809", color: "#fff" }}
        >
          <CancelOutlinedIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default Prompt;
