import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import StepperForm from './StepperForm';

export default function NewApplication() {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button style={{float:'right'}}
        variant="contained"
        color="secondary"
      
        startIcon={<AddIcon />}

        onClick={handleClickOpen}
      >
        New Application
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"  fullWidth={true} maxWidth="xl">
        <DialogTitle id="form-dialog-title">New Application</DialogTitle>
          <DialogContent>  
              <StepperForm/>    
          </DialogContent>    
      </Dialog>
    </>
  );
}
