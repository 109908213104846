import { Button, Chip, Tooltip, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import "react-data-grid/dist/react-data-grid.css";
import TenantService from "../../api/TenantService";
import CustomDataTable from "../../components/data-table/CustomDataTable";
import ImageDialog from "../../components/ImageDialog";
import { Box } from "@mui/material";
import { formatDateToIndianFormat } from "../../api/GlobalService";
import ConfirmDialog from "../../components/confirm-dialog/ConfirmDialog";


const useStyle = makeStyles({
  rowColor: {
    backgroundColor: "#E8EAFC",
    color: "black",
  },
});

export default function FamilyMemberDataTable({ famMemberFlag, reload, setReload }) {
  const [familyMembers, setFamilyMembers] = useState([]);
  const [openImage, setOpenImage] = useState(false);
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [familyMemberId, setFamilyMemberId] = useState("")

  const handleRemove = async () => {
    // getDecryptedData("role") === "MODERATOR" ||
    //   getDecryptedData("role") === "SUPERMODERATOR" ||
    //   getDecryptedData("role") === "VERIFIER"
    sessionStorage.getItem("role") === "MODERATOR" ||
      sessionStorage.getItem("role") === "SUPERMODERATOR" ||
      sessionStorage.getItem("role") === "VERIFIER"
      ? await TenantService.removeFamilyMember(familyMemberId).then(res => {
        console.log("responseDeleted: ", res.data)
        alert("Family Member successfully removed!")
        setReload(reload => !reload);
      })
      : await TenantService.removeFamilyMemberAnonymous(
        familyMemberId
      ).then(res => {
        console.log("responseDeleted: ", res.data)
        alert("Family Member successfully removed!")
        setReload(reload => !reload);
      })
  }

  const columns = [
    {
      name: "memberCode",
      label: "Member Code",
      options: {
        display: false,
        filter: true,
      },
    },
    {
      name: "memberName",
      label: "Member Name",
      options: {
        filter: true,
      },
    },
    {
      name: "age",
      label: "Date of Birth",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta)
          return formatDateToIndianFormat(tableMeta.rowData[2])
        }
      },
    },
    {
      name: "relationship",
      label: "Relationship",
      options: {
        filter: true,
      },
    },
    {
      name: "occupation",
      label: "Occupation",
      options: {
        filter: true,
      },
    },
    {
      name: "memberCode",
      label: "Family Member ID",
      options: {
        display: false,
        filter: true,
      },
    },
    {
      name: "leftOn",
      label: "Left On",
      options: {
        display: false,
        filter: true,
      },
    },
    {
      name: "active",
      label: "Status",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[7] ? (
            <Chip
              label="Active"
              style={{ padding: 0, color: "#fff", backgroundColor: "#92d459" }}
            />
          ) : (
            <Chip
              label={`Left on: ${formatDateToIndianFormat(tableMeta.rowData[6])}`}
              style={{ padding: 0, color: "#fff", backgroundColor: "red" }}
            />
          );
        },
      },
    },
    {
      name: "documents",
      label: "Actions",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log("errr", tableMeta)
          return (<Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
            <Tooltip
              title={`View photo of ${tableMeta.rowData[1]}`}
              placement="top-start"
              arrow
            >
              <Button
                variant="outlined"
                size="small" style={{
                  backgroundColor: "#F3A712",
                  color: "#fff",
                  borderRadius: "4px",
                }}
                onClick={async () => {
                  // setSelectedRow(props.row.docid);
                  // setIsLoading(true);
                  const fileBase64 =
                    // getDecryptedData("role") === "MODERATOR" ||
                    //   getDecryptedData("role") === "SUPERMODERATOR" ||
                    //   getDecryptedData("role") === "VERIFIER"
                    sessionStorage.getItem("role") === "MODERATOR" ||
                      sessionStorage.getItem("role") === "SUPERMODERATOR" ||
                      sessionStorage.getItem("role") === "VERIFIER"
                      ? await TenantService.getFamilyMemberDocuments(
                        tableMeta.rowData[5]
                      ).then(resp => TenantService.downloadFamilyMemberDocument(resp.data[0].docid)).catch(err => {
                        alert("No Photo Uploaded For This Member!")
                      })
                      : await TenantService.downloadFamilyMemberDocumentAnonymous(
                        tableMeta.rowData[8][0].docid
                      ).catch(err => alert("Please re-upload photograph!"))
                  if (!fileBase64) return;
                  const contenttype = fileBase64.data.contenttype;
                  var type = null;
                  if (
                    contenttype == "JPEG" ||
                    contenttype == "JPG" ||
                    contenttype == "jpeg" ||
                    contenttype == "jpg"
                  ) {
                    type = "image/jpg";
                  } else {
                    type = "application/pdf";
                  }
                  handleDownload(fileBase64.data.base64img, type);
                }}
              >
                View Photo
              </Button>

            </Tooltip>
            {tableMeta.rowData[7] && <Tooltip title={`Remove ${tableMeta.rowData[1]}`}
              sx={{ flexGrow: 1 }}
              placement="top-start"
              arrow>
              <Button
                size="small"

                variant="outlined"
                // startIcon={<AddIcon />}
                style={{
                  backgroundColor: "#F3A712",
                  color: "#fff",
                  borderRadius: "4px",
                }}
                onClick={async () => {
                  setFamilyMemberId(tableMeta.rowData[0])
                  setOpenConfirmDialog(true);
                }}
              >
                Remove
              </Button>
            </Tooltip>}
          </Box>

          );
        },
      },
    },
  ];

  const handleDownload = async (base64img, type) => {
    var blob = base64toBlob(base64img, type);
    let blobUrl = URL.createObjectURL(blob);
    setIsLoading(false);
    setImage(blobUrl);
    setOpenImage(true);
  };

  function base64toBlob(base64Data, type) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: type });
  }

  // const getFamilyMembers = async () => {
  //   const response =
  //     getDecryptedData("role") === "MODERATOR" ||
  //       getDecryptedData("role") === "SUPERMODERATOR"
  //       ? await TenantService.getTenantFamilyMembersByEpicNumber(
  //         getDecryptedData("tenantId")
  //       )
  //       : await TenantService.getTenantFamilyMembersByEpicNumberAnonymous(
  //         getDecryptedData("tenantId")
  //       );
  //   setFamilyMembers(response.data);
  // };
  const getFamilyMembers = async () => {
    const response =
      sessionStorage.getItem("role") === "MODERATOR" ||
        sessionStorage.getItem("role") === "SUPERMODERATOR"
        ? await TenantService.getTenantFamilyMembersByEpicNumber(
          sessionStorage.getItem("tenantId")
        )
        : await TenantService.getTenantFamilyMembersByEpicNumberAnonymous(
          sessionStorage.getItem("tenantId")
        );
    setFamilyMembers(response.data);
  };

  useEffect(() => {
    getFamilyMembers();
  }, [reload, famMemberFlag]);

  return (
    <div style={{ width: "100%" }}>
      <CustomDataTable
        rows={familyMembers} columns={columns}
        title="Family Members List"
      />
      <ImageDialog
        openImage={openImage}
        setOpenImage={setOpenImage}
        image={image}
      />
      <ConfirmDialog
        text="Are you sure you want to delete this family member?"
        openConfirmDialog={openConfirmDialog}
        setOpenConfirmDialog={setOpenConfirmDialog}
        handleDelete={handleRemove}
      />
    </div>
  );
}
