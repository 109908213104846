import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {
  Backdrop,
  CircularProgress,
  Container,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import { useState } from "react";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import { useUserDispatch } from "../../context/UserContext";
// import LandlordTab from "./LandlordTab";
import ApplicationService from "../../api/ApplicationService";
import TenantTab from "../offlineapplication/TenantTab";
import SaveIcon from "@material-ui/icons/Save";
import TenantService from "../../api/TenantService";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Alert, Paper, Stack } from "@mui/material";
import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import httpApi from "../../api/http-common-apikey";
import JSEncrypt from "jsencrypt";
import http from "../../api/http-common";
import httpApiApp from "../../api/http-common-apikey-application";
import Timer from "../../components/Timer";
import { getDecryptedData, storeEncryptedData, validateTenantId } from "../../api/GlobalService";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  downloadApp: {
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  resize: {
    fontSize: 20,
  },
  stepper: {
    "& .MuiStepIcon-active": { color: "#F3A712" },
    "& .MuiStepIcon-completed": { color: "#29335C" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "none" },
  },
}));

// function getSteps() {
//   return ;
// }

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TenantStepperForm({
  setLndOrTntLogin,
  activeStep,
  setActiveStep,
  loginValue,
  setLoginValue,
  lndOrTntLogin,
}) {
  const [download, setDownload] = useState(false);
  const [value, setValue] = React.useState("id");
  const [notFound, setNotFound] = useState(false);

  var userDispatch = useUserDispatch();
  /*
      We can useReducer for this form Here

    */
  const {
    newTenantApplication,
    runningApplication,
    tenantApplication,
    openSnackBar,
    editTenantApplication,
  } = useContext(GlobalContext);

  const classes = useStyles();
  const steps = ["Tenant ID", "Tenant Details"];
  const [profile, setProfile] = React.useState(tenantApplication);
  const [tabValue, setTabValue] = React.useState(0);
  const [registerFlag, setRegisterFlag] = useState(false);
  const [tenantExists, setTenantExists] = useState(false);
  const [documentsExist, setDocumentsExist] = useState(false);
  const [saved, setSaved] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [counter, setCounter] = useState(0);

  const [tenLogin, setTenLogin] = useState(false);

  const [backDropOpen, setBackDropOpen] = useState(false);
  const [publicKey, setPublicKey] = useState("");
  const [otp, setOtp] = useState("");
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const setBackDrop = (val) => {
    setBackDropOpen(val);
  };

  const getPublicKey = () => {
    http
      .get("users/getPublicKey")
      .then(function (response) {
        setPublicKey(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (activeStep === 0) {
      newTenantApplication();
      getPublicKey();
      handleReset();
    }
    if (activeStep === steps.length) {
      profile.document.map(async (doc) => {
        if (
          doc.doctype === "PHOTO" ||
          doc.doctype === "IDENTITYPROOF" ||
          doc.doctype === "SIGNATURE"
        )
          await setCounter((counter) => counter + 1);
        return counter;
      });
    } else {
      setCounter(0);
    }
  }, [activeStep]);

  useEffect(() => {
    if (lndOrTntLogin === false) {
      setProfile(tenantApplication);
    }
  }, [lndOrTntLogin]);

  function isEmptyString(str) {
    return !str || 0 === str.length;
  }

  const isValidEpicCard = (val) => {
    return !isEmptyString(val);
  };

  const handleLoginValueChange = (event) => {
    setLoginValue(event.target.value.toUpperCase());
    // setEpicNo(event.target.value);
  };

  const handleLoginChange = (e) => {
    setLoginValue("");
    registerFlag === false && setNotFound(false);
    setTenLogin(!tenLogin);
    setValue(e.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setLoginValue("");
    setTabValue(newValue);
    setNotFound(false);
    setRegisterFlag(!registerFlag);
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Box sx={{ width: "100%" }}>
            <Snackbar
              open={snackBar.open}
              // autoHideDuration={3000}
              // anchorOrigin={{ vertical: "top", horizontal: "right" }}
              sx={{ height: "100%" }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={(event, reason) => {
                if (reason === "clickaway") {
                  return; // Ignore the click outside behavior
                }
                setSnackBar({ ...snackBar, open: false });
              }}
            >
              <Alert
                onClose={() => {
                  setSnackBar({ ...snackBar, open: false }); // Correctly close the Snackbar when 'x' is clicked
                }}
                severity={snackBar.severity}
              >
                {snackBar.message}
              </Alert>
            </Snackbar>
            <Box>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                centered
              >
                <Tab label="Login" {...a11yProps(0)} />
                <Tab label="Register" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <Paper // variant="outlined"
                sx={{
                  padding: "6%",
                  width: { md: "40%", lg: "40%", sm: "100%", xs: "100%" },
                  margin: "auto",
                }}
                elevation={3}
              >
                <FormControl
                  style={{
                    width: "100%",
                    marginBottom: 0,
                    paddingBottom: 0,
                    // paddingLeft: "20px",
                  }}
                >
                  <Stack spacing={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignSelf: "center",
                      }}
                    >
                      <InfoIcon color="primary" sx={{ marginRight: "5px" }} />
                      <Typography
                        variant="subtitle1"
                        align="center"
                        color="primary"
                      >
                        Login with either: 1. Tenant ID or 2. Tenant Reg no.
                      </Typography>
                    </Box>
                    <RadioGroup
                      aria-label="quiz"
                      name="quiz"
                      value={value}
                      onChange={handleLoginChange}
                    >
                      <Stack spacing={1}>
                        <Stack direction="row">
                          <FormControlLabel
                            value="id"
                            control={<Radio />}
                            label="1"
                          />
                          <TextField
                            autoFocus
                            disabled={tenLogin}
                            id="epic-number"
                            onChange={(e) => {
                              setLoginValue(e.target.value);
                            }}
                            value={value === "id" ? loginValue : ""}
                            label="ID (driving licence, PAN or epic number)"
                            fullWidth={true}
                            style={{
                              marginBottom: "20px",
                            }}
                            error={value === "id" && notFound === true}
                            helperText={
                              value === "id" &&
                              notFound === true &&
                              "Tenant does not exist. Please Register!"
                            }
                            InputLabelProps={{ shrink: true }} //this is used for textfield with long Labels

                          // InputProps={{ style: { fontSize: 30 } }}
                          // InputLabelProps={{ style: { fontSize: 20 } }}
                          // InputProps={{                // FOR CONTROLLING FONT-SIZES.
                          //   classes: {
                          //     input: classes.resize,
                          //   },
                          // }}
                          />
                        </Stack>
                        <Stack direction="row">
                          <FormControlLabel
                            value="reg"
                            control={<Radio />}
                            label="2"
                          />
                          <TextField
                            disabled={!tenLogin}
                            // variant="outlined"
                            id="epic-number"
                            onChange={handleLoginValueChange}
                            value={value === "reg" ? loginValue : ""}
                            label="Registration number"
                            fullWidth={true}
                            style={{
                              marginBottom: "20px",
                            }}
                            error={value === "reg" && notFound === true}
                            helperText={
                              value === "reg" &&
                              notFound === true &&
                              "Tenant does not exist. Please Register!"
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}

                          // InputProps={{                      // FOR CONTROLLING FONT-SIZES.
                          //   classes: {
                          //     input: classes.resize,
                          //   },
                          // }}
                          />
                        </Stack>
                      </Stack>
                    </RadioGroup>
                    <Button
                      disabled={otpSent || loginValue === ""}
                      color="primary"
                      variant="contained"
                      style={{ width: "50%", margin: "auto" }}
                      onClick={() =>
                        httpApi
                          .get(`apikey/getOTP/${loginValue}`)
                          .then((res) => {
                            setOtpSent(true);
                            setSnackBar({
                              message:
                                // "An OTP has been sent to your registered mobile number or email",
                                "Please enter OTP received in your registered mobile number or email.",
                              severity: "success",
                              open: true,
                            });
                          })
                          .catch((err) => {
                            openSnackBar({
                              message: "Server Error: Please try again!",
                              open: true,
                              severity: "error",
                            });
                          })
                      }
                    >
                      Get OTP
                    </Button>
                    <TextField
                      disabled={!otpSent}
                      autoFocus
                      fullWidth
                      style={{
                        marginBottom: "20px",
                      }}
                      id="id"
                      onChange={async (e) => {
                        let otp = e.target.value;
                        if (otp.length >= 6) {
                          try {
                            let crypt = new JSEncrypt();
                            crypt.setPublicKey(publicKey);
                            let enc = crypt.encrypt(otp);
                            httpApi.defaults.headers["Content-type"] =
                              "application/json";
                            setOtp(enc);
                          } catch (error) {
                            console.log(error.response);
                          }
                        }
                      }}
                      label="Enter OTP"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {otpSent && (
                              <Timer initialMinute={5} initialSeconds={0} />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>

                  {/* <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            sx={{ mt: 1 }}
            onClick={async () => {
              sessionStorage.setItem("tenantEpicNo", loginValue);
              try {
                const response =
                  value === "id"
                    ? await TenantService.getByEpicNumber(loginValue)
                    : await TenantService.getTenantById(loginValue);
                // If exist and isParentNotCheck, Error Saying already exist, but what if he shifted to another House
                // Design flaw here
                console.log(response);
                sessionStorage.setItem("tenantId", response.data.id);

                setProfile({ ...response.data, exist: true });
                // setDisableOtherTabs(false);
              } catch (error) {
                console.log(error);
                // setDisableOtherTabs(true);
                setProfile({ ...profile, epicNo: loginValue, exist: false });
              }
              await handleNext();
            }}
          >
            Login
          </Button> */}
                </FormControl>
              </Paper>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <Paper // variant="outlined"
                sx={{
                  padding: "3%",
                  width: { md: "30%", lg: "30%", sm: "100%", xs: "100%" },
                  margin: "auto",
                }}
                elevation={3}
              >
                <FormControl
                  style={{
                    width: "100%",
                    marginBottom: 0,
                    paddingBottom: 0,
                    // paddingLeft: "20px",
                  }}
                >
                  <Stack spacing={2}>
                    <Box sx={{ display: "flex", alignSelf: "center" }}>
                      <InfoIcon color="primary" />
                      <Typography
                        variant="subtitle1"
                        align="center"
                        color="primary"
                      >
                        Register with Tenant ID
                      </Typography>
                    </Box>

                    <TextField
                      autoFocus
                      // disabled={tenLogin}
                      id="epic-number"
                      onChange={(e) => {
                        // validateTenantId(e.target.value) &&
                        setLoginValue(e.target.value);
                      }}
                      value={loginValue}
                      label="ID (driving licence, PAN or epic number)"
                      fullWidth={true}
                      style={{
                        marginBottom: "20px",
                      }}
                      error={tenantExists}
                      helperText={
                        tenantExists && "Tenant already exists. Please login!"
                      }
                      InputLabelProps={{
                        //this is used for textfield with long Labels
                        shrink: true,
                      }}
                    // InputProps={{                // FOR CONTROLLING FONT-SIZES.
                    //   classes: {
                    //     input: classes.resize,
                    //   },
                    // }}
                    />
                  </Stack>

                  {/* <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            sx={{ mt: 1 }}
            onClick={async () => {
              sessionStorage.setItem("tenantEpicNo", loginValue);
              try {
                const response =
                  value === "id"
                    ? await TenantService.getByEpicNumber(loginValue)
                    : await TenantService.getTenantById(loginValue);
                // If exist and isParentNotCheck, Error Saying already exist, but what if he shifted to another House
                // Design flaw here
                console.log(response);
                sessionStorage.setItem("tenantId", response.data.id);

                setProfile({ ...response.data, exist: true });
                // setDisableOtherTabs(false);
              } catch (error) {
                console.log(error);
                // setDisableOtherTabs(true);
                setProfile({ ...profile, epicNo: loginValue, exist: false });
              }
              await handleNext();
            }}
          >
            Login
          </Button> */}
                </FormControl>
              </Paper>
            </TabPanel>
          </Box>
        );
      case 1:
        return (
          <TenantTab
            profile={profile}
            setDocumentsExist={setDocumentsExist}
            setSaved={setSaved}
            saved={saved}
            registerFlag={registerFlag}
            setActiveStep={setActiveStep}
          />
        );
    }
  }
  const handleNext = async () => {
    switch (activeStep) {
      case 0: {

        sessionStorage.setItem("tenantEpicNo", loginValue);
        // storeEncryptedData("tenantEpicNo", loginValue)

        try {
          registerFlag
            ? await TenantService.checkTenant(loginValue)
            : await httpApi
              .post(`apikey/validateOTP/${loginValue}`, otp)
              .then(async (res) => {
                httpApiApp.defaults.headers["API-key"] = res.data;
                const response =
                  value === "id"
                    ? await TenantService.getByEpicNumberAnonymous(loginValue)
                    : await TenantService.getTenantByIdAnonymous(loginValue);
                // If exist and isParentNotCheck, Error Saying already exist, but what if he shifted to another House
                // Design flaw here
                editTenantApplication(response.data);

                sessionStorage.setItem("tenantId", response.data.id);
                // storeEncryptedData("tenantId", response.data.id)
                setSnackBar({ ...snackBar, open: false });

                response.data.document.length === 3 &&
                  setDocumentsExist(true);
                setProfile({ ...response.data, exist: true });
                setLndOrTntLogin(true);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              })

              .catch((err) => {
                return openSnackBar({
                  message: err.response.data,
                  open: true,
                  severity: "error",
                });
              });
          if (registerFlag) {
            setTenantExists(true);
            return;
          }
        } catch (error) {
          if (error.response.status === 404) {
            setProfile({ ...profile, epicNo: loginValue });
            openSnackBar({
              open: true,
              message:
                "Tenant with ID " +
                loginValue +
                " not exist; add as new!",
              severity: "success",
            });
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
        break;
      }
      case 1: {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    activeStep === 1 && setLoginValue("");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setOtpSent(false);
    setNotFound(false);
    setLoginValue("");
    setOtp("");
    setLndOrTntLogin(false);
  };

  const handleDownload = async () => {
    setBackDropOpen(true);
    setDownload(true);
    console.log(runningApplication);
    const base64Pdf = await TenantService.getTenantRegistrationFormAnonymous(
      // getDecryptedData("tenantId")
      sessionStorage.getItem("tenantId")
    );
    var blob = base64toBlob(base64Pdf.data.base64img);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
    setDownload(false);
    setBackDropOpen(false);
  };
  function base64toBlob(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
  }

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.stepper}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length ? (
        <div>
          <Typography className={classes.instructions}>
            {profile.document !== null
              ? counter === 3
                ? "All steps completed. Download registration form below."
                : "Cannot download registration form. Please upload all the documents required."
              : "Cannot download registration form. Please upload all the documents required."}
          </Typography>
          <Button
            onClick={handleDownload}
            color="primary"
            disabled={counter === 3 ? false : true}
            // disabled={counter !== 3 ? true : false}
            // disabled={!download || !documentsExist}
            className={classes.downloadApp}
          >
            Download Completed Form
          </Button>
          <Button onClick={handleBack} className={classes.backButton}>
            Go Back
          </Button>
        </div>
      ) : (
        <div>
          <Typography className={classes.instructions}>
            {getStepContent(activeStep)}
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.backButton}
              sx={{ display: activeStep === 1 && "none" }}
            >
              Back
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                setBackDropOpen(true);
                await handleNext();
                setBackDropOpen(false);
              }}
              // disabled={
              //   (activeStep === steps.length - 1 && finish === false) ||
              //   activeStep === 0
              // }
              disabled={
                (activeStep === 1 &&
                  saved === false &&
                  // getDecryptedData("tenantId") === null) ||
                  sessionStorage.getItem("tenantId") === null) ||
                (activeStep === 0 && otp === "" && !registerFlag) ||
                (activeStep === 0 && registerFlag && loginValue === "")
              }
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
            <Button onClick={handleReset} className={classes.backButton}>
              Reset
            </Button>
          </Box>
        </div>
      )}
    </div>
  );
}
