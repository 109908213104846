import React from "react";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle";


export default function Dashboard() {


  return (
    <>
      <PageTitle title="Dashboard" />
     
    </>
  );
}