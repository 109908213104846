import { Button, CircularProgress } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { useContext } from "react";
import "react-data-grid/dist/react-data-grid.css";
import TenantService from "../../../api/TenantService";
import { GlobalContext } from "../../../context/GlobalState";
import { makeStyles } from "@material-ui/core/styles";
import ImageDialog from "../../../components/ImageDialog";
import CustomDataTable from "../../../components/data-table/CustomDataTable";
import { getDecryptedData } from "../../../api/GlobalService";

const useStyle = makeStyles((theme) => ({
  closeButton: {
    "&:hover": {
      transform: "scale(1.5)",
    },
  },
}));

export default function TenantDocumentsDataTable(props) {
  const classes = useStyle();
  const [image, setImage] = useState();
  const [openImage, setOpenImage] = useState(false);
  const { tenantApplication, currentApplication } = useContext(GlobalContext);
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");

  const columns = [
    {
      name: "docid",
      label: "Document ID",
      options: {
        display: false,
      },
    },

    { name: "doctype", label: "Document Type" },
    {
      name: "file",
      label: "File",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>
            {isLoading === true && selectedRow === tableMeta.rowData[0] ? (
              <CircularProgress size={22} />
            ) :
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  setSelectedRow(tableMeta.rowData[0]);
                  setIsLoading(true);
                  const fileBase64 =
                    // getDecryptedData("role") === "MODERATOR" ||
                    //   getDecryptedData("role") === "SUPERMODERATOR" ||
                    //   getDecryptedData("role") === "VERIFIER"
                    sessionStorage.getItem("role") === "MODERATOR" ||
                      sessionStorage.getItem("role") === "SUPERMODERATOR" ||
                      sessionStorage.getItem("role") === "VERIFIER"
                      ? await TenantService.getDocumentsByDocumentId(
                        tableMeta.rowData[0]
                      )
                      : await TenantService.getDocumentsByDocumentIdAnonymous(
                        tableMeta.rowData[0]
                      );

                  const contenttype = fileBase64.data.contenttype;
                  var type = null;
                  if (
                    contenttype == "JPEG" ||
                    contenttype == "JPG" ||
                    contenttype == "jpeg" ||
                    contenttype == "jpg"
                  ) {
                    type = "image/jpg";
                  } else {
                    type = "application/pdf";
                  }
                  handleDownload(fileBase64.data.base64img, type);
                }}
              >
                View
              </Button>}
          </>
        }
      }


    },
  ];

  const getDocuments = async () => {
    // const response =
    //   getDecryptedData("role") === "MODERATOR" ||
    //     getDecryptedData("role") === "SUPERMODERATOR" ||
    //     getDecryptedData("role") === "VERIFIER"
    //     ? await TenantService.getTenantDocumentsByTenantId(
    //       getDecryptedData("tenantId")
    //     )
    //     : await TenantService.getTenantDocumentsByTenantIdAnonymous(
    //       getDecryptedData("tenantId")
    //     );
    const response =
      sessionStorage.getItem("role") === "MODERATOR" ||
        sessionStorage.getItem("role") === "SUPERMODERATOR" ||
        sessionStorage.getItem("role") === "VERIFIER"
        ? await TenantService.getTenantDocumentsByTenantId(
          sessionStorage.getItem("tenantId")
        )
        : await TenantService.getTenantDocumentsByTenantIdAnonymous(
          sessionStorage.getItem("tenantId")
        );

    setDocuments(response.data);
    if (props.setDocumentsExist) {
      if (response.data.length === 3) props.setDocumentsExist(true);
    }
  };

  const handleDownload = async (base64img, type) => {
    //   const base64Pdf = await TenantService.getDocumentsByDocumentId(props.row.docid);
    var blob = base64toBlob(base64img, type);
    let blobUrl = URL.createObjectURL(blob);
    setIsLoading(false);
    setImage(blobUrl);
    setOpenImage(true);

    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveOrOpenBlob(blob);
    // } else {
    //   const blobUrl = URL.createObjectURL(blob);
    //   window.open(blobUrl);
    // }
  };
  function base64toBlob(base64Data, type) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: type });
  }

  useEffect(() => {
    getDocuments();
  }, [props.refresh]);

  return (
    <div style={{ height: 550, width: "100%", position: "relative" }}>
      <CustomDataTable rows={documents} columns={columns} title="Tenant's Documents" />
      <div style={{ position: "absolute", marginLeft: "48%" }}>
        {/* {getDecryptedData("role") === "LOCAL AUTHORITY" && ( */}
        {sessionStorage.getItem("role") === "LOCAL AUTHORITY" && (
          <Button
            className={classes.closeButton}
            variant="contained"
            color="primary"
            onClick={props.closeTenantModal}
          >
            Close
          </Button>
        )}
      </div>
      <ImageDialog
        openImage={openImage}
        setOpenImage={setOpenImage}
        image={image}
      />
      ;
    </div>
  );
}
