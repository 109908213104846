import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {
  Backdrop,
  Card,
  CircularProgress,
  FormControl,
} from "@material-ui/core";
import { useState } from "react";
import LandlordService from "../../api/LandlordService";
import Tenants from "../tenants/Tenants";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import { useUserDispatch, redirectLogout } from "../../context/UserContext";
import LandlordTab from "./LandlordTab";
import TenantApplications from "../tenants/applications/TenantApplications";
import ApplicationService from "../../api/ApplicationService";
import { Box } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  downloadApp: {
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  responsiveLabel: {

    [theme.breakpoints.down('lg')]: {
      fontSize: '1.1rem', // Adjust the font size for smaller screens
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem', // Adjust the font size for smaller screens
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem', // Adjust the font size for smaller screens
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem', // Adjust the font size for smaller screens
    },
    // Add more styles as needed for different breakpoints
  },
}));

// function getSteps() {
//   return ;
// }

export default function StepperForm(props) {
  const [finish, setFinish] = useState(false);
  const [download, setDownload] = useState(true);

  var userDispatch = useUserDispatch();
  /*
      We can useReducer for this form Here 
    
    */
  const {
    newApplication,
    existingApplication,
    currentApplication,
    openSnackBar,
    initialState,
    runningApplication,
  } = useContext(GlobalContext);

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ["Landlord Election ID", "Landlord", "Tenants", "Applications"];
  const [epicNumber, setEpicNumber] = useState("");

  const [backDropOpen, setBackDropOpen] = useState(false);

  const setBackDrop = (val) => {
    setBackDropOpen(val);
  };

  function isEmptyString(str) {
    return !str || 0 === str.length;
  }

  const isValidEpicCard = (val) => {
    return !isEmptyString(val);
  };

  const handleKeyPress = (event) => {
    // Check if the pressed key is Enter (key code 13)
    if (event.key === 'Enter') {
      // Trigger the button click
      handleClick();
    }
  };

  const handleClick = async () => {
    setBackDropOpen(true);
    await handleNext();
    setBackDropOpen(false);
  };


  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <FormControl
            style={{
              width: "100%",
              margin: "1rem 1rem"
            }}
            margin="auto"
          >
            <TextField
              size="small"
              align="center"
              variant="outlined"
              style={{
                width: "60%",
                margin: "auto"
              }}
              value={epicNumber}
              id="epicnumber"
              onChange={(event) => {
                setEpicNumber(event.target.value.toUpperCase());
              }}
              // style={{ width: "100%" }}
              label="Landlord's Epic Card Number"
              onKeyDown={handleKeyPress}
              InputLabelProps={{
                className: classes.responsiveLabel,
              }}
            />
          </FormControl>
        );
      case 1:
        return (
          <>
            <LandlordTab />
          </>
        );
      case 2:
        return (
          <>
            <Tenants activeStep={activeStep} setActiveStep={setActiveStep} />
          </>
        );
      case 3:
        return <TenantApplications finish={setFinish} />;
    }
  }
  const handleNext = async () => {
    switch (activeStep) {
      case 0: {
        if (isValidEpicCard(epicNumber) === false) {
          openSnackBar({
            open: true,
            severity: "error",
            message: "Landlord Epic Number required!",
          });
          newApplication({ epicNumber: epicNumber, exist: false });
          return;
        }
        try {
          const response = await LandlordService.getByEpicNumber(epicNumber);
          response.data.exist = true;
          response.data.id = response.data.landlord_id;
          //setLandLordDetails(response.data);
          existingApplication(response.data);
        } catch (error) {
          if (error.response != undefined && error.response.status === 404) {
            // EPIC NUMBER Not exist, promt to add a new one
            openSnackBar({
              open: true,
              message:
                "Landlord with EPIC Number " +
                epicNumber +
                " not exist; add as new!",
              severity: "success",
            });
            //console.log(currentApplication);
            let newApp = initialState;
            newApp.id = epicNumber;
            // Reinitialized the currentApplication
            newApplication(newApp);
            // setLandLordDetails({...landlordDetails,exist:false,id:epicNumber});
          } else {
            if (error.response.status === 401) {
              openSnackBar({
                open: true,
                message: "Authentication Failed",
                severity: "error",
              });
              redirectLogout(userDispatch);
            } else {
              openSnackBar({
                open: true,
                message:
                  "Network Connection Error!, Cannot connect to backend API",
                severity: "error",
              });
            }
            // Connection Problem

            // Do no progress
            return;
          }
        }

        break;

        // Dispatch Loading
        // Get the landlord details
        // If exist populate else promt a new one.
      }
      case 1: {
        // Validate Entry
        //
        if (currentApplication.exist === false) {
          // initializeApplication();
          openSnackBar({
            open: true,
            message:
              "Landlord details not saved, please save and then continue...",
            severity: "error",
          });

          return;
        }

        break;
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    newApplication(initialState);
    setEpicNumber("");
  };

  const handleDownload = async () => {
    setBackDropOpen(true);
    setDownload((download) => !download);
    console.log(runningApplication);
    const base64Pdf = await ApplicationService.getApplicationPdfAsUser(
      runningApplication
    );
    setDownload((download) => !download);
    var blob = base64toBlob(base64Pdf.data.base64img);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
      setBackDropOpen(false);
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
      setBackDropOpen(false);
    }
  };
  function base64toBlob(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
  }

  //  useEffect(()=>{
  //   handleDownload();
  //  },[]);

  // const handleDownload = () => {
  //   getApplicationPdf("APPL020210001")
  //   const base64Pdf="";
  //   var blob = base64toBlob(base64Pdf);
  //   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //     window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
  //   } else {
  //     const blobUrl = URL.createObjectURL(blob);
  //     window.open(blobUrl);
  //   }
  // };
  // function base64toBlob(base64Data) {
  //   const sliceSize = 1024;
  //   const byteCharacters = atob(base64Data);
  //   const bytesLength = byteCharacters.length;
  //   const slicesCount = Math.ceil(bytesLength / sliceSize);
  //   const byteArrays = new Array(slicesCount);

  //   for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
  //     const begin = sliceIndex * sliceSize;
  //     const end = Math.min(begin + sliceSize, bytesLength);

  //     const bytes = new Array(end - begin);
  //     for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
  //       bytes[i] = byteCharacters[offset].charCodeAt(0);
  //     }
  //     byteArrays[sliceIndex] = new Uint8Array(bytes);
  //   }
  //   return new Blob(byteArrays, { type: "application/pdf" });
  //}

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed
            </Typography>
            <Button
              onClick={handleDownload}
              color="primary"
              disabled={!download}
              className={classes.downloadApp}
            >
              Download the Complete Application
            </Button>
          </div>
        ) : (
          <div>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                // className={classes.backButton}
                variant="outlined"
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClick}
                disabled={
                  (activeStep === steps.length - 1 && finish === false) ||
                  epicNumber === "" ||
                  activeStep === 2
                }
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
              <Button
                onClick={handleReset}
                // className={classes.backButton}
                variant="outlined"
              >
                Reset
              </Button>
            </Box>
            <Box className={classes.instructions}>
              {getStepContent(activeStep)}
            </Box>
          </div>
        )}
      </div>
    </div>
  );
}
