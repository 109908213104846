import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert, Autocomplete } from "@mui/lab";
import MasterData from "../../api/MasterData";
import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import RentalUnitService from "../../api/RentalUnitService";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import { Box, FormControl, Stack } from "@mui/material";
import { getDecryptedData } from "../../api/GlobalService";

const useStyle = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const errorInitialState =
  // getDecryptedData("role") === null
  sessionStorage.getItem("role") === null
    ? {
      error: false,
      localAuthority: {
        exist: false,
        helperText: "Sub-Locality/Wards required",
      },
      policeStation: {
        exist: false,
        helperText: "Police Station required",
      },
      // houseNo: {
      //   exist: false,
      //   helperText: "House number required",
      // },
      // floorNo: {
      //   exist: false,
      //   helperText: "Floor number  required",
      // },
      address1: {
        exist: false,
        helperText: "Address1 required",
      },
      // address2: {
      //   exist: false,
      //   helperText: "Address2 required",
      // },
      district: {
        exist: false,
        helperText: "District required",
      },
      pinCode: {
        exist: false,
        helperText: "Pincode required",
      },
    }
    : {
      error: false,
      localAuthority: {
        exist: false,
        helperText: "Sub-Locality/Wards required",
      },

      // houseNo: {
      //   exist: false,
      //   helperText: "House number required",
      // },
      // floorNo: {
      //   exist: false,
      //   helperText: "Floor number  required",
      // },
      address1: {
        exist: false,
        helperText: "Address1 required",
      },
      // address2: {
      //   exist: false,
      //   helperText: "Address2 required",
      // },

      pinCode: {
        exist: false,
        helperText: "Pincode required",
      },
    };

const RentalUnitFormDialog = ({
  open,
  setOpen,
  rentalUnitProp,
  setRentalUnitProp,
}) => {
  const classes = useStyle();
  const [backDropOpen, setBackDropOpen] = useState(false);

  const {
    currentApplication,
    addRentalUnit,
    openSnackBar,
    editRentalUnit,
  } = useContext(GlobalContext);

  const [rentalUnit, setRentalUnit] = useState({
    houseNo: "",
    floorNo: "",
    address: {
      address1: "",
      address2: "",
      pinCode: "",
      district: "",
    },
    laCode: "",
    landlordId: "",
  });

  const [errors, setErrors] = useState(errorInitialState);
  const [districts, setDistricts] = useState([]);

  const [policeStations, setPoliceStations] = useState([]);
  const [dsCode, setDsCode] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedPoliceStation, setSelectedPoliceStation] = useState(null);
  const [selectedLocalAuthority, setSelectedLocalAuthority] = useState(null);
  const [psCode, setPsCode] = useState("");
  const [localAutorities, setLocalAutorities] = useState([]);

  useEffect(() => {
    if (districts.length < 1) {
      getDistricts();
    }
    if (psCode !== "") {
      getLocalAutoritiesByPSCode();
      // } else if (getDecryptedData("role") === null && dsCode !== "") {
    } else if (sessionStorage.getItem("role") === null && dsCode !== "") {
      getPoliceStations();
    } else {
      getLocalAutorities();
    }
  }, [dsCode, psCode]);

  // useEffect(() => {
  //   setRentalUnit(rentalUnitProp);
  // }, [rentalUnitProp]);

  const validateUserData = () => {
    const e = errorInitialState;
    if (rentalUnit.laCode === "") {
      e.error = true;
      e.localAuthority.exist = true;
    } else {
      e.error = false;
      e.localAuthority.exist = false;
    }
    if (selectedPoliceStation === null) {
      e.error = true;
      e.policeStation.exist = true;
    } else {
      e.error = false;
      e.policeStation.exist = false;
    }
    if (rentalUnit.address.address1 === "") {
      e.error = true;
      e.address1.exist = true;
    } else {
      e.error = false;
      e.address1.exist = false;
    }
    // if (rentalUnit.address.address2 === "") {
    //   e.error = true;
    //   e.address2.exist = true;
    // } else {
    //   e.error = false;
    //   e.address2.exist = false;
    // }
    if (rentalUnit.address.pinCode === "") {
      e.error = true;
      e.pinCode.exist = true;
    } else {
      e.error = false;
      e.pinCode.exist = false;
    }
    if (rentalUnit.address.district === "") {
      e.error = true;
      e.district.exist = true;
    } else {
      e.error = false;
      e.district.exist = false;
    }

    return e;
  };

  const validateUserDataProp = () => {
    const e = errorInitialState;
    if (rentalUnitProp.laCode === "") {
      e.error = true;
      e.localAuthority.exist = true;
    } else {
      e.error = false;
      e.localAuthority.exist = false;
    }
    // if (selectedPoliceStation === null) {
    //   e.error = true;
    //   e.policeStation.exist = true;
    // } else {
    //   e.error = false;
    //   e.policeStation.exist = false;
    // }
    if (rentalUnitProp.address.address1 === "") {
      e.error = true;
      e.address1.exist = true;
    } else {
      e.error = false;
      e.address1.exist = false;
    }
    // if (rentalUnitProp.address.address2 === "") {
    //   e.error = true;
    //   e.address2.exist = true;
    // } else {
    //   e.error = false;
    //   e.address2.exist = false;
    // }
    if (rentalUnitProp.address.pinCode === "") {
      e.error = true;
      e.pinCode.exist = true;
    } else {
      e.error = false;
      e.pinCode.exist = false;
    }
    if (rentalUnitProp.address.district === "") {
      e.error = true;
      e.district.exist = true;
    } else {
      e.error = false;
      e.district.exist = false;
    }

    return e;
  };

  const validateUserData2 = () => {
    let e = errorInitialState;
    if (rentalUnit.laCode === "") {
      e.error = true;
      e.localAuthority.exist = true;
    } else {
      e.localAuthority.exist = false;
    }
    if (rentalUnit.address.address1 === "") {
      e.error = true;
      e.address1.exist = true;
    } else {
      e.address1.exist = false;
    }
    // if (rentalUnit.address.address2 === "") {
    //   e.error = true;
    //   e.address2.exist = true;
    // } else {
    //   e.address2.exist = false;
    // }
    if (rentalUnit.address.pinCode === "") {
      e.error = true;
      e.pinCode.exist = true;
    } else {
      e.pinCode.exist = false;
    }

    if (
      e.localAuthority.exist === false &&
      e.address1.exist === false &&
      e.address2.exist === false &&
      e.pinCode.exist === false
    )
      e.error = false;
    return e;
  };

  const validateUserData2Prop = () => {
    const e = errorInitialState;
    if (rentalUnitProp.laCode === "") {
      e.error = true;
      e.localAuthority.exist = true;
    } else {
      e.error = false;
      e.localAuthority.exist = false;
    }
    if (rentalUnitProp.address.address1 === "") {
      e.error = true;
      e.address1.exist = true;
    } else {
      e.error = false;
      e.address1.exist = false;
    }
    // if (rentalUnitProp.address.address2 === "") {
    //   e.error = true;
    //   e.address2.exist = true;
    // } else {
    //   e.error = false;
    //   e.address2.exist = false;
    // }
    if (rentalUnitProp.address.pinCode === "") {
      e.error = true;
      e.pinCode.exist = true;
    } else {
      e.error = false;
      e.pinCode.exist = false;
    }
    return e;
  };

  const handleDistrictChange = (value) => {
    const s = { ...rentalUnit };
    if (value !== null) {
      setDsCode(value.districtCode);
      setSelectedDistrict(value);
      s.address.district = value.districtCode;
      setRentalUnit(s);
    } else {
      s.address.district = "";
      setRentalUnit(s);
    }
  };

  const handlePSChange = (value) => {
    setPsCode(value.policeStationCode);
    setSelectedPoliceStation(value);
    // const s = { ...rentalUnit };
    // if (value !== null) {
    //   s.address.district = value.districtCode;
    //   setRentalUnit(s);
    // } else {
    //   s.address.district = "";
    // }
  };

  const handleLocalAutorityChange = (value) => {
    const s = { ...rentalUnit };
    if (value != null) {
      s.address.district = value.ps.dist.districtCode;
      s.laCode = value.localAuthorityCode;
      setSelectedLocalAuthority(value);
    } else {
      s.laCode = "";
    }
    setRentalUnit(s);
  };

  const getDistricts = async () => {
    // const response = await MasterData.getDistrictsByStateId('MEG');
    const response = await MasterData.getDistrictsByStateId("17");
    setDistricts(response.data);
  };

  // const getLocalAutorities = async () => {
  //   let response;
  //   if (getDecryptedData("role") === "MODERATOR") {
  //     response = await RentalUnitService.getLocalAutoritiesByLACode(
  //       getDecryptedData("usercode")
  //     );
  //     setLocalAutorities((prevState) => [...prevState, response.data]);
  //   } else if (getDecryptedData("role") === "SUPERMODERATOR") {
  //     response = await RentalUnitService.getLocalAutoritiesByLocalityCode(
  //       getDecryptedData("usercode")
  //     );
  //     setLocalAutorities(response.data);
  //   } else {
  //     response = await RentalUnitService.getLocalAutoritiesByPoliceStationId(
  //       getDecryptedData("usercode")
  //     );
  //     setLocalAutorities(response.data);
  //   }
  // };
  const getLocalAutorities = async () => {
    let response;
    if (sessionStorage.getItem("role") === "MODERATOR") {
      response = await RentalUnitService.getLocalAutoritiesByLACode(
        sessionStorage.getItem("usercode")
      );
      setLocalAutorities((prevState) => [...prevState, response.data]);
    } else if (sessionStorage.getItem("role") === "SUPERMODERATOR") {
      response = await RentalUnitService.getLocalAutoritiesByLocalityCode(
        sessionStorage.getItem("usercode")
      );
      setLocalAutorities(response.data);
    } else {
      response = await RentalUnitService.getLocalAutoritiesByPoliceStationId(
        sessionStorage.getItem("usercode")
      );
      setLocalAutorities(response.data);
    }
  };

  const getPoliceStations = async () => {
    const response = await MasterData.getPoliceStationsByDistrictCode(dsCode);
    setPoliceStations(response.data);
  };

  const getLocalAutoritiesByPSCode = async () => {
    const response = await RentalUnitService.getLocalAutoritiesByPoliceStationId(
      psCode
    );
    setLocalAutorities(response.data);
  };

  const handleHouseNumberChange = (value) => {
    rentalUnitProp
      ? setRentalUnitProp({ ...rentalUnitProp, houseNo: value.target.value })
      : setRentalUnit({ ...rentalUnit, houseNo: value.target.value });
  };
  const handleFloorNumberChange = (value) => {
    rentalUnitProp
      ? setRentalUnitProp({ ...rentalUnitProp, floorNo: value.target.value })
      : setRentalUnit({ ...rentalUnit, floorNo: value.target.value });
  };
  const handleAddress1Change = (value) => {
    rentalUnitProp
      ? setRentalUnitProp({
        ...rentalUnitProp,
        address: { ...rentalUnitProp.address, address1: value.target.value },
      })
      : setRentalUnit({
        ...rentalUnit,
        address: { ...rentalUnit.address, address1: value.target.value },
      });
  };
  const handleAddress2Change = (value) => {
    rentalUnitProp
      ? setRentalUnitProp({
        ...rentalUnitProp,
        address: { ...rentalUnitProp.address, address2: value.target.value },
      })
      : setRentalUnit({
        ...rentalUnit,
        address: { ...rentalUnit.address, address2: value.target.value },
      });
  };
  const handlePincodeChange = (value) => {
    rentalUnitProp
      ? setRentalUnitProp({
        ...rentalUnitProp,
        address: { ...rentalUnitProp.address, pinCode: value.target.value },
      })
      : setRentalUnit({
        ...rentalUnit,
        address: { ...rentalUnit.address, pinCode: value.target.value },
      });
  };

  const saveRentalUnit = async () => {
    setBackDropOpen(true);
    const s = rentalUnitProp ? { ...rentalUnitProp } : { ...rentalUnit };
    s.landlordId = currentApplication.id;
    try {
      const response =
        // getDecryptedData("role") === "MODERATOR" ||
        //   getDecryptedData("role") === "SUPERMODERATOR" ||
        //   getDecryptedData("role") === "VERIFIER"
        sessionStorage.getItem("role") === "MODERATOR" ||
          sessionStorage.getItem("role") === "SUPERMODERATOR" ||
          sessionStorage.getItem("role") === "VERIFIER"
          ? rentalUnitProp
            ? await RentalUnitService.editOrDeleteRentalUnit(s, 0)
            : await RentalUnitService.create(s)
          : rentalUnitProp
            ? await RentalUnitService.editOrDeleteRentalUnitAnonymous(s, 0)
            : await RentalUnitService.createAnonymous(s);

      if (response != null || response != undefined) {
        setBackDropOpen(false);
        // We successfully saved it and refresh the data
        rentalUnitProp
          ? editRentalUnit(response.data)
          : addRentalUnit({ ...response.data, policestation: response.data.la.ps.policeStationName, localAuthority: response.data.la.localAuthorityName });

        openSnackBar({
          open: true,
          message: rentalUnitProp
            ? "Successfully Edited Rental Unit"
            : "Successfully Saved Rental Unit",
          severity: "success",
        });

        setOpen(false);

        setSelectedPoliceStation(null);
        setSelectedDistrict(null);
        setSelectedLocalAuthority(null);
        setRentalUnit({
          houseNo: "",
          floorNo: "",
          address: {
            address1: "",
            address2: "",
            pinCode: "",
            district: "",
          },
          laCode: "",
          landlordId: "",
        });
        setErrors(errorInitialState);
      }
    } catch (error) {
      setBackDropOpen(false);
      openSnackBar({
        open: true,
        message: error.response.data.message,
        severity: "error",
      });
      // setOpen(false);
    }
  };

  const handleClose = () => {
    setSelectedPoliceStation(null);
    setSelectedDistrict(null);
    setSelectedLocalAuthority(null);
    setRentalUnit({
      houseNo: "",
      floorNo: "",
      address: {
        address1: "",
        address2: "",
        pinCode: "",
        district: "",
      },
      laCode: "",
      landlordId: "",
    });
    setErrors(errorInitialState);
    setOpen(false);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      // key={open}
      >
        <Backdrop className={classes.backdrop} open={backDropOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle id="form-dialog-title">
          <Typography variant="h3" align="center">
            {rentalUnitProp ? "Edit Rental Unit" : "New Rental Unit"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form className={classes.root} noValidate autoComplete="off">
            {/* {getDecryptedData("role") === null ? ( */}
            {sessionStorage.getItem("role") === null ? (
              <Stack direction="column" spacing={2}>
                <FormControl>
                  <Autocomplete
                    size={"small"}
                    // value={
                    //   selectedDistrict === null
                    //     ? { districtName: "" }
                    //     : selectedDistrict //must be an object. This object is then fetched by getOptionLabel
                    // }
                    onChange={(event, value) => {
                      handleDistrictChange(value);
                    }}
                    id="standard-basic"
                    options={districts}
                    getOptionLabel={(option) => option.districtName}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <span>
                            District <span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        variant="outlined"
                      // error={errors.district.exist === true ? true : false}
                      // helperText={
                      //   errors.district.exist
                      //     ? errors.district.helperText
                      //     : ""
                      // }
                      />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Autocomplete
                    size={"small"}
                    onChange={(event, value) => {
                      handlePSChange(value);
                    }}
                    // value={
                    //   selectedPoliceStation === null
                    //     ? { policeStationName: "" }
                    //     : selectedPoliceStation //must be an object. This object is then fetched by getOptionLabel
                    // }
                    id="standard-basic"
                    options={policeStations}
                    getOptionLabel={(option) => option.policeStationName}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <span>
                            Police Station <span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        variant="outlined"
                        error={
                          errors.policeStation.exist === true ? true : false
                        }
                        helperText={
                          errors.policeStation.exist
                            ? errors.policeStation.helperText
                            : ""
                        }
                      />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Autocomplete
                    size={"small"}
                    onChange={(event, value) => {
                      handleLocalAutorityChange(value);
                    }}
                    // value={
                    //   selectedLocalAuthority === null
                    //     ? { localAuthorityName: "" }
                    //     : selectedLocalAuthority //must be an object. This object is then fetched by getOptionLabel
                    // }
                    id="standard-basic"
                    options={localAutorities}
                    getOptionLabel={(option) => option.localAuthorityName}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <span>
                            Locality <span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        variant="outlined"
                        error={
                          errors.localAuthority.exist === true ? true : false
                        }
                        helperText={
                          errors.localAuthority.exist
                            ? errors.localAuthority.helperText
                            : ""
                        }
                      />
                    )}
                  />
                </FormControl>
              </Stack>
            ) : rentalUnitProp ? (
              ""
            ) : (
              <FormControl
                sx={{
                  width: { lg: "70%", md: "70%", sm: "50%", xs: "100%" },
                }}
              >
                <Autocomplete
                  // size="small"
                  onChange={(event, value) => {
                    handleLocalAutorityChange(value);
                  }}
                  id="standard-basic"
                  options={localAutorities}
                  getOptionLabel={(option) =>
                    option.localAuthorityName +
                    " /Headman:" +
                    option.headManName
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span>
                          Sub-locality/Wards <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      variant="outlined"
                      error={
                        errors.localAuthority.exist === true ? true : false
                      }
                      helperText={
                        errors.localAuthority.exist
                          ? errors.localAuthority.helperText
                          : ""
                      }
                    />
                  )}
                />
              </FormControl>
            )}

            <br />
            <Box
              sx={{
                display: "flex",
                flexDirection: { lg: "row", md: "row", sm: "row", xs: "row" },
                gap: 2,
                // width: { lg: "70%", md: "70%", sm: "50%", xs: "100%" },
              }}
            >
              <FormControl>
                <TextField
                  autoFocus
                  margin="dense"
                  id="housenumber"
                  label="House Number"
                  type="text"
                  fullWidth
                  // value={rentalUnit.houseNo}

                  value={
                    rentalUnitProp ? rentalUnitProp.houseNo : rentalUnit.houseNo
                  }
                  onChange={handleHouseNumberChange}
                // error={errors.houseNo.exist === true ? true : false}
                // helperText={
                //   errors.houseNo.exist ? errors.houseNo.helperText : ""
                // }
                />
              </FormControl>
              <FormControl>
                <TextField
                  autoFocus
                  margin="dense"
                  id="floornumber"
                  label="Floor Number/Flat No."
                  type="text"
                  fullWidth
                  value={
                    rentalUnitProp ? rentalUnitProp.floorNo : rentalUnit.floorNo
                  }
                  onChange={handleFloorNumberChange}
                // error={errors.floorNo.exist === true ? true : false}
                // helperText={
                //   errors.floorNo.exist ? errors.floorNo.helperText : ""
                // }
                />
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  lg: "row",
                  md: "row",
                  sm: "column",
                  xs: "column",
                },
                gap: 2,
                // width: { lg: "70%", md: "70%", sm: "50%", xs: "100%" },
              }}
            >
              <FormControl>
                <TextField
                  autoFocus
                  margin="dense"
                  id="address"
                  label={
                    <span>
                      Address-1 <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  type="text"
                  fullWidth
                  value={
                    rentalUnitProp
                      ? rentalUnitProp.address.address1
                      : rentalUnit.address.address1
                  }
                  onChange={handleAddress1Change}
                  error={errors.address1.exist === true ? true : false}
                  helperText={
                    errors.address1.exist ? errors.address1.helperText : ""
                  }
                />
              </FormControl>
              <FormControl>
                <TextField
                  autoFocus
                  margin="dense"
                  id="address"
                  label={
                    <span>
                      Address-2
                    </span>
                  }
                  type="text"
                  fullWidth
                  value={
                    rentalUnitProp
                      ? rentalUnitProp.address.address2
                      : rentalUnit.address.address2
                  }
                  onChange={handleAddress2Change}
                  // error={errors.address2.exist === true ? true : false}
                  // helperText={
                  //   errors.address2.exist ? errors.address2.helperText : ""
                  // }
                />
              </FormControl>
            </Box>
            <FormControl>
              <TextField
                autoFocus
                margin="dense"
                id="pincode"
                label={
                  <span>
                    Pincode <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                type="text"
                fullWidth
                value={
                  rentalUnitProp
                    ? rentalUnitProp.address.pinCode
                    : rentalUnit.address.pinCode
                }
                onChange={handlePincodeChange}
                error={errors.pinCode.exist === true ? true : false}
                helperText={
                  errors.pinCode.exist ? errors.pinCode.helperText : ""
                }
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={async (e) => {
              e.preventDefault();
              const errs =
                // getDecryptedData("role") === null
                sessionStorage.getItem("role") === null
                  ? rentalUnitProp
                    ? validateUserDataProp()
                    : validateUserData()
                  : rentalUnitProp
                    ? validateUserData2Prop()
                    : validateUserData2();

              if (errs.error === true) {
                setErrors(errs);
              } else {
                await saveRentalUnit();
              }
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RentalUnitFormDialog;
