import http from "./http-common";
import httpApiApp from "./http-common-apikey-application";
const create = async (data, type) => {
  console.log(type);
  if (type === "TENANT")
    return http.post("/register/tenant/domesticHelper", data).then(
      // return httpApi.post("/registration/tenant/domesticHelper", data).then(
      function (response) {
        var data = response.data;
        console.log(data);
        return data;
        // not relevant
      },
      function (error) {
        var data = error.data;
        return data;
        // not relevant
      }
    );
  else {
    const response = http.post("/landlord/domesticHelper", data).then(
      function (response) {
        var data = response.data;
        console.log(data);
        return data;
        // not relevant
      },
      function (error) {
        var data = error.data;
        return data;
        // not relevant
      }
    );
    return response;
  }
};
const getHelpersByTenantId = async (epicNo) => {
  return http.get(`/register/tenant/domesticHelper/${epicNo}`);
  // return httpApi.get(`/registration/tenant/domesticHelper/${epicNo}`);
};
const remove = async (id) => {
  return http.delete(`/user/${id}`);
};
const removeAll = async () => {
  return http.delete(`/user`);
};

// APIs for Anonymous users
const getHelpersByTenantIdAnonymous = async (epicNo) => {
  return httpApiApp.get(`/process/tenant/domesticHelper/${epicNo}`);
  // return httpApi.get(`/registration/tenant/domesticHelper/${epicNo}`);
};

const createAnonymous = async (data, type) => {
  console.log(type);
  if (type === "TENANT")
    return httpApiApp.post("/process/tenant/domesticHelper", data).then(
      // return httpApi.post("/registration/tenant/domesticHelper", data).then(
      function (response) {
        var data = response.data;
        console.log(data);
        return data;
        // not relevant
      },
      function (error) {
        var data = error.data;
        return data;
        // not relevant
      }
    );
  else {
    const response = httpApiApp.post("/landlord/domesticHelper", data).then(
      function (response) {
        var data = response.data;
        console.log(data);
        return data;
        // not relevant
      },
      function (error) {
        var data = error.data;
        return data;
        // not relevant
      }
    );
    return response;
  }
};

export default {
  create,
  getHelpersByTenantId,
  remove,
  removeAll,
  // APIs for Anonymous users
  getHelpersByTenantIdAnonymous,
  createAnonymous,
};
