import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LandlordForm from "./LandlordForm";
import RentalUnit from "../rentalunits/RentalUnit";
import { GlobalContext } from "../../context/GlobalState";
import ApplicationsList from "./ApplicationsList";
import { getDecryptedData } from "../../api/GlobalService";
import Application from "./Application";
import ClearanceForm from "./ClearanceForm";
import CreateApplication from "../login/CreateApplication";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "#E8E9ED"
  },
}));

export default function LandlordTab({ registerFlag, setActiveStep }) {
  const { currentApplication } = useContext(GlobalContext);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [disabledOtherTabs, setDisableOtherTabs] = useState(
    !currentApplication.exist
  );

  return (
    <div
      className={classes.root}
    // style={{ overflowY: "scroll" }}
    >
      {/* <AppBar position="static"> */}
      {registerFlag ?
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          centered
        >
          <Tab label="Basic Details" {...a11yProps(0)} />
        </Tabs>

        : <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          centered
        >
          <Tab label="Basic Details" {...a11yProps(0)} />
          <Tab
            label="Rental Units"
            {...a11yProps(1)}
            disabled={disabledOtherTabs}
          />
          {/* {getDecryptedData("role") === null && ( */}
          {sessionStorage.getItem("role") === null && (
            <Tab
              label="Applications"
              {...a11yProps(2)}
              disabled={disabledOtherTabs}
            />
          )}
          {sessionStorage.getItem("role") === null && (
            <Tab
              label="Clearance"
              {...a11yProps(3)}
              disabled={disabledOtherTabs}
            />
          )}

          {/* <Tab
            label="Domestic Helpers"
            {...a11yProps(2)}
            disabled={disabledOtherTabs}
          /> */}
        </Tabs>}
      {/* </AppBar> */}
      <TabPanel value={value} index={0}>
        <LandlordForm setDisableOtherTabs={setDisableOtherTabs} setActiveStep={setActiveStep} />
        {/* <Button 
        variant = "contained"
        color = "secondary"
        onClick={() => {
          setValue(value+1);
          setDisableOtherTabs();
        }}>Go to Rental Units</Button> */}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RentalUnit />
        {/* <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setValue(value + 1);
            setDisableOtherTabs();
          }}
        >
          Go to Domestic Helpers
        </Button> */}
      </TabPanel>
      {/* {getDecryptedData("role") === null && ( */}
      {sessionStorage.getItem("role") === null && (
        <>

          <TabPanel value={value} index={2}>
            <ApplicationsList />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ClearanceForm />
          </TabPanel>
        </>
      )}
      {/* <TabPanel value={value} index={2}>
        <Helpers type="LANDLORD" profile={currentApplication} />
      </TabPanel> */}
    </div>
  );
}
