import { FormControl, TextField } from "@material-ui/core";
import Button from "@mui/material/Button";

import { Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import MasterData from "../../api/MasterData";
import UserService from "../../api/UserService";

const PoliceStationForm = (props) => {
  const [districts, setDistricts] = useState([]);

  const errorInitialState = {
    error: false,
    psName: {
      exist: false,
      helperText: "Police station name required",
    },
    district: {
      exist: false,
      helperText: "District required",
    },
  };

  const validateUserData = () => {
    const e = errorInitialState;
    if (ps.policeStationName === "") {
      e.error = true;
      e.psName.exist = true;
    }

    if (ps.dist === "") {
      e.error = true;
      e.district.exist = true;
    }

    return e;
  };

  const [errors, setErrors] = useState(errorInitialState);

  const [ps, setPs] = useState({
    // policeStationCode: "1",
    policeStationName: "",
    dist: "",
  });

  const handleClick = async (e) => {
    e.preventDefault();
    const errs = validateUserData();

    if (errs.error === true) {
      setErrors(errs);

      // setSnackBar({
      //   message: "Validation Errors!",
      //   severity: "error",
      //   open: true,
      // });

      return;
    }

    UserService.createPoliceStation(ps)
      .then((response) => {
        console.log("Response for PS Entry is : ", response);
        alert(
          `Successfully added police station "${response.data.policeStationName}"`
        );
        // history.push("/app/dashboard");
        //   setError(null)
        //   setIsLoading(false);

        // history.push("/app/dashboard");
        //   dispatch({ type: "LOGIN_SUCCESS" });
      })
      .then(() => {
        props.toggleRefresh();
        props.setOpen(false);
      })
      .catch((e) => {
        console.error(e);
        //   setError(true);
        //   setIsLoading(false);
        //dispatch({ type: "LOGIN_FAILURE" }); // No need this, if enable it will reset the tabIndex State
      });
  };

  const handleDistrictChange = (event, code) => {
    if (code !== null) {
      setPs({ ...ps, dist: code.districtCode });
    } else {
      setPs({ ...ps, dist: "" });
    }
  };

  const getDistricts = async () => {
    const response = await MasterData.getDistrictsByStateId("17");
    setDistricts(response.data);
  };
  useEffect(() => {
    getDistricts();
  }, []);

  const [refresh, setRefresh] = useState(false);

  return (
    <form
      noValidate
      autoComplete="off"
      style={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
      }}
    >
      <FormControl style={{ width: "100%" }}>
        <TextField
          onChange={(e) => setPs({ ...ps, policeStationName: e.target.value })}
          error={errors.psName.exist === true ? true : false}
          helperText={errors.psName.exist ? errors.psName.helperText : ""}
          value={ps.policeStationName}
          autoFocus
          margin="dense"
          label="Police Station Name"
          type="text"
          variant="outlined"
          fullWidth
        />
      </FormControl>

      <Autocomplete
        onChange={(event, value) => {
          handleDistrictChange(event, value);
        }}
        id="permanent-district"
        options={districts}
        getOptionLabel={(option) => option.districtName}
        renderInput={(params) => (
          <TextField
            margin="dense"
            name="dist"
            {...params}
            label="District *"
            variant="outlined"
            error={errors.district.exist === true ? true : false}
            helperText={errors.district.exist ? errors.district.helperText : ""}
          />
        )}
      />
      <Button
        sx={{
          marginTop: "8%",
          backgroundColor: "#29335C",
          "&:hover": {
            background: "#015090",
          },
        }}
        variant="contained"
        onClick={handleClick}
      >
        Create
      </Button>
    </form>
  );
};

export default PoliceStationForm;
