import { Box, Container, Typography } from "@mui/material";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <Container>
      <img
        src={process.env.PUBLIC_URL + "/static/banner.jpg"}
        alt="Banner"
        style={{ width: "100%", height: "auto" }}
      />
      <Box sx={{ padding: "3%" }}>
        <Typography variant="h5" align="center" color={"#806e14"}>
          Privacy Policy
        </Typography>
        <Box sx={{ minHeight: 10 }} />
        <Typography variant="subtitle1">
          This privacy policy governs your use of the software applications
          (“Applications”) for mobile devices as well as Web application.The
          Applications mainly provide eGovernance Services delivery and intend
          to provide better citizen services by the government.
        </Typography>
        <Box sx={{ minHeight: 10 }} />

        <Typography variant="caption1" color={"#806e14"}>
          User Provided Information:
        </Typography>
        <Box sx={{ minHeight: 3 }} />

        <Typography variant="subtitle1">
          The Applications may obtain the information you provide when you
          download and register the Application. Registration is optional.
          However, please keep in mind that you may not be able to use some of
          the features offered by an Application unless you register.
          <br /> When you register and use the Application, you generally
          provide (a) your name, email address, age, user name, password and
          other registration information; (b) download or use applications from
          us; and; (c) information you enter into our system when using the
          Application, such as contact information and other details.
          <br />
          The information you provided may be used to contact you from time to
          time to provide you with important information and required notices.
        </Typography>
        <Box sx={{ minHeight: 10 }} />

        <Typography variant="caption1" color={"#806e14"}>
          Automatically Collected Information :
        </Typography>
        <Box sx={{ minHeight: 3 }} />

        <Typography variant="subtitle1">
          In addition, mobile apps “ Drugs Free Meghalaya”, may collect certain
          information automatically, including your mobile device's unique
          device ID, the IP address of your device, and information about the
          way you use the Application.
          <br /> When you visit the mobile application, it may use GPS
          technology (or other similar technology) to determine your current
          location in order to determine the city you are located within and
          display a location map. The location information may be sent to
          authorities for taking necessary actions and making policy decisions.
          <br />
          If you do not want the app to use your location for the purposes set
          forth above, you should turn off the location services for the mobile
          application located in your account settings or in your mobile phone
          settings and/or within the mobile application. However, if the service
          provided by the Application requires the location services using GPS
          technology, such services offered by the application will not be
          available for you.
        </Typography>
        <Box sx={{ minHeight: 10 }} />

        <Typography variant="caption1" color={"#806e14"}>
          Data Retention Policy, Managing Your Information
        </Typography>
        <Box sx={{ minHeight: 3 }} />

        <Typography variant="subtitle1">
          We will retain User provided data for as long as you use the
          Application and for a reasonable time thereafter. We will retain
          Automatically Collected information also for a reasonable period of
          time depending on the nature of application and thereafter may store
          it in aggregate. Please note that some or all of the User Provided
          Data may be required in order for the Application to function properly
        </Typography>
        <Box sx={{ minHeight: 10 }} />

        <Typography variant="caption1" color={"#806e14"}>
          Collection of Personal Information:
        </Typography>
        <Box sx={{ minHeight: 3 }} />

        <Typography variant="subtitle1">
          If you are asked for any other Personal Information you will be
          informed how it will be used if you choose to give it. If at any time
          you believe the principles referred to in this privacy statement have
          not been followed, or have any other comments on these principles,
          please notify the webmaster through the contact us page.
        </Typography>
        <Box sx={{ minHeight: 10 }} />

        <Typography variant="caption1" color={"#806e14"}>
          Security:
        </Typography>
        <Box sx={{ minHeight: 3 }} />

        <Typography variant="subtitle1">
          We are concerned about safeguarding the confidentiality of your
          information. We limit access to this information to authorized
          employees and contractors who need to know that information in order
          to operate, develop or improve our Application. Please be aware that,
          although we endeavor to provide reasonable security for information we
          process and maintain, no security system can prevent all potential
          security breaches.
        </Typography>

        <Typography variant="caption1" color={"#806e14"}>
          Your Consent :
        </Typography>
        <Box sx={{ minHeight: 3 }} />

        <Typography variant="subtitle1">
          By using the Application, you are consenting to our processing of your
          information as set forth in this Privacy Policy now and as amended by
          us.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
