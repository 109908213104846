export default (state, action) => {
  switch (action.type) {
    case "REMOVE_APPPLICATION":
      return {
        ...state,
        applications: state.applications.filter(
          (application) => application.id !== action.payload
        ),
      };

    case "GET_APPPLICATIONS":
      return {
        ...state,
        applications: action.payload,
      };

    case "OPEN_SNACKBAR":
      return {
        ...state,
        snackbar: action.payload,
      };

    case "GET_RUNNINGAPPLICATIONS":
      return {
        ...state,
        runningApplication: action.payload,
      };
    case "GET_GET_USERNAME":
      return {
        ...state,
        username: action.payload,
      };
    case "GET_GET_USERCODE":
      return {
        ...state,
        usercode: action.payload,
      };

    case "PROCESS_APPLICATION":
      return {
        ...state,
        processApplication: action.payload,
      };
    case "UPDATE_APPLICATION":
      return {
        ...state,
        currentApplication: action.payload,
      };
    case "ADD_APPLICATION":
      return {
        ...state,
        applications: [...state.applications, action.payload],
      };
    case "ADD_RENTALUNIT":
      //     if(state.currentApplication.rentalUnit===null)
      //          state.currentApplication.rentalUnit=[]; // initialization due to some null bug, no idea where it originated. cheers :) temporary fixing this one

      state.currentApplication.rentalUnit.push(action.payload);
      return {
        ...state,
        currentApplication: state.currentApplication,
      };
    case "EDIT_RENTALUNIT":
      const updatedRentalUnit = action.payload;

      const updatedRentalUnits = state.currentApplication.rentalUnit.map(
        (unit) => {
          if (unit.rentcode === updatedRentalUnit.rentcode) {
            return updatedRentalUnit;
          }
          return unit;
        }
      );
      return {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          rentalUnit: updatedRentalUnits,
        },
      };
    case "DELETE_RENTALUNIT":
      state.currentApplication.rentalUnit.filter(
        (unit) => unit.rentcode !== action.payload
      );
      return {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          rentalUnit: state.currentApplication.rentalUnit.filter(
            (unit) => unit.rentcode !== action.payload
          ),
        },
      };
    case "ADD_LANDLORDHELPER":
      state.currentApplication.helper.push(action.payload);
      return {
        ...state,
        currentApplication: state.currentApplication.helper,
      };

    case "ADD_TENANT":
      state.currentApplication.tenants.push(action.payload);

      return {
        ...state,
        currentApplication: state.currentApplication,
      };

    case "NEW_APPLICATION":
      return {
        ...state,
        currentApplication: action.payload,
      };

    case "EXISTING_APPLICATION":
      return {
        ...state,
        currentApplication: action.payload,
      };

    case "EDIT_TENANT_APPLICATION":
      return {
        ...state,
        tenantApplication: action.payload,
      };

    case "NEW_TENANT_APPLICATION":
      return {
        ...state,
        tenantApplication: action.payload,
      };

    case "NEW_LANDLORD_APPLICATION":
      return {
        ...state,
        currentApplication: action.payload,
      };

    case "EDIT_APPLICATION":
      const updatedApplication = action.payload;
      const updatedApplications = state.applications.map((application) => {
        if (application.id === updatedApplication.id) {
          return updatedApplication;
        }
        return application;
      });

      return {
        ...state,
        applications: updatedApplication,
      };

    case "ADD_TENANT_DOCUMENT":
      state.tenantApplication.document.push(action.payload);
      return {
        ...state,
        tenantApplication: state.tenantApplication,
      };

    default:
      return state;
  }
};
