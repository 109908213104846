import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import {
  CssBaseline,
  responsiveFontSizes,
  Typography,
} from "@material-ui/core";

import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { Link } from "react-router-dom";

let theme2 = responsiveFontSizes(Themes.default);

const UnsecuredPage = () => (
  <div>
    <Typography>
      If you see this page, Webb App link you have clicked on is under
      Clickjacking security attack.
    </Typography>
    <h2>
      Please inform team with the reference of the application from where you
      clicked this link.
    </h2>
    <h2>
      Click{" "}
      <Link
        href={window.self.location.href}
        title="Web Application"
        target="blank"
      >
        here
      </Link>{" "}
      to access WebApp safely.
    </h2>
  </div>
);

window.onbeforeunload = function (e) {
  sessionStorage.clear();
};

if (window.self === window.top) {
  ReactDOM.render(
    <LayoutProvider>
      <UserProvider>
        <ThemeProvider theme={theme2}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </UserProvider>
    </LayoutProvider>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <ThemeProvider theme={theme2}>
      <UnsecuredPage />
    </ThemeProvider>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister();
