import axios from "axios";

 //export const apiUrl = "http://10.179.2.80:8080/mrss/v-1/";
//export const apiUrl = "http://localhost:8080/mrss/v-1/";

export const apiUrl =
  window.location.protocol +
  "//" +
  window.location.hostname +
  "/mrssapi/mrss/v-1/";

// export const apiUrl = "http://megrssa.nic.in:8080/mrssapi/mrss/v-1/";
// export const apiUrl = "http://megrssa.nic.in/mrssapi/mrss/v-1/";
// export const apiUrl = process.env.REACT_APP_ENDPOINT;

// const getToken = (count) => {
//   let token = sessionStorage.getItem("accessToken");

//   //  if(!token){
//   //   if(count>1000)
//   //   {
//   //      return null;
//   //   }
//   //    return getToken(count+1);
//   //  }
//   return token;
// };

export default axios.create({
  withCredentials: false,
  timeout: 10000,
  baseURL: apiUrl,
});
